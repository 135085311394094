import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase'; // Your Firebase configuration
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';
import { Bar } from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import ReactECharts from 'echarts-for-react';
import SuccessAnim from '../components/SuccessAnim';

// Utility function to calculate the number of days in the current month
const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

// Function to filter orders for the current month
const filterOrdersForCurrentMonth = (orders) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  let totalSales = 0;
  let totalOrders = 0;
  const salesByDay = {};
  const ordersByDay = {}; // Track orders by day

  orders.forEach(order => {
    const orderDate = order.date.toDate();
    const orderMonth = orderDate.getMonth();
    const orderYear = orderDate.getFullYear();

    if (orderMonth === currentMonth && orderYear === currentYear) {
      const day = orderDate.getDate();
      const orderAmount = order.amount || 0;

      // Track sales
      salesByDay[day] = (salesByDay[day] || 0) + orderAmount;

      // Track orders
      ordersByDay[day] = (ordersByDay[day] || 0) + 1;

      totalSales += orderAmount;
      totalOrders += 1;
    }
  });

  return {
    salesByDay,
    ordersByDay, // Return orders by day
    totalSales,
    totalOrders
  };
};

const TestScreen = () => {
  const [salesChartData, setSalesChartData] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [mySoldProductsLoading, setMySoldProductsLoading] = useState(true);
  const myId = 90; // Replace with actual user ID

  useEffect(() => {
    const fetchOrders = () => {
      setMySoldProductsLoading(true);
      const ordersQuery = query(
        collection(db, 'Orders'),
        where('sellerId', '==', myId),
        where('payment_status', '==', "success"),
        where('type', '==', "Purchase"),
        orderBy('date', "desc")
      );

      const unsubscribe = onSnapshot(ordersQuery, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const ordersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          const result = filterOrdersForCurrentMonth(ordersData);

          // Get number of days in the current month
          const currentDate = new Date();
          const daysInMonth = getDaysInMonth(currentDate.getMonth() + 1, currentDate.getFullYear());

          // Prepare chart data for both sales and orders
          const processedChartData = Array.from({ length: daysInMonth }, (_, i) => {
            const day = i + 1; // Day of the month (1-based)
            return {
              day: day, // Day of the month
              sales: result.salesByDay[day] || 0, // Sales for the day
              orders: result.ordersByDay[day] || 0 // Orders for the day
            };
          });

          // Update state with chart data and totals
          setSalesChartData(processedChartData);
          setTotalSales(result.totalSales);
          setTotalOrders(result.totalOrders);
        } else {
          // Reset if no orders are found
          setSalesChartData([]);
          setTotalSales(0);
          setTotalOrders(0);
        }

        setMySoldProductsLoading(false);
      });

      return () => unsubscribe();
    };

    if (myId) {
      fetchOrders();
    }
  }, [myId, db]);

  // Chart.js Data
  const chartJSData = {
    labels: salesChartData.map(item => `Day ${item.day}`),
    datasets: [
      {
        label: 'Sales',
        data: salesChartData.map(item => item.sales),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  // ApexCharts Data
  const apexChartOptions = {
    chart: { type: 'bar' },
    xaxis: { categories: salesChartData.map(item => `Day ${item.day}`) },
  };
  const apexChartSeries = [{ name: 'Sales', data: salesChartData.map(item => item.sales) }];

  // ECharts Data
  const eChartOptions = {
    xAxis: { type: 'category', data: salesChartData.map(item => `Day ${item.day}`) },
    yAxis: { type: 'value' },
    series: [{ data: salesChartData.map(item => item.sales), type: 'bar' }],
  };

  return (
    <SuccessAnim />
  );
};

export default TestScreen;
