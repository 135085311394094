import React, {useState, useEffect} from 'react'
import CustomNavbar from '../components/CustomNavbar';
import { GoHomeFill, GoReport } from "react-icons/go";


function UserAgreement () {
    // window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: 'instant'
    //   });


    const [isVisible, setIsVisible] = useState(false); // Visibility state

    useEffect(() => {
      // Set the div to visible after a slight delay to ensure the transition happens
        setTimeout(() => setIsVisible(true), 100);
    }, []);


        return (
                <div
      style={{
        opacity: isVisible ? 1 : 0, // Fade in effect based on isVisible
        transform: isVisible ? "translateY(0)" : "translateY(20px)", // Move up effect based on isVisible
        transition: "opacity 0.3s ease, transform 0.2s ease", // Transition effects
      }}
    >

      <div style={{
          width: window.innerWidth > window.innerHeight ? "80%" : "100%", 
          height:"fit-content", 
          marginLeft: "auto", 
          marginRight:"auto", 
          direction:"rtl", 
          paddingBottom:"32px",
          padding: window.innerWidth > window.innerHeight ?  "auto" : "0px 16px", 
          minHeight:"100vh",
          paddingTop:"2rem"
      }}>



      <h5>
      اتفاقية المستخدم
      </h5>
      <h6 style={{fontWeight:500, marginTop:"2rem"}}>المادة الأولى: المقدمة:</h6>
      <p>

1.	إن اتفاقية المستخدم هذه، وجميع الشروط والبنود والسياسات المنشورة على منصة بيع ("المنصة") وضعت لحماية وحفظ حقوق كل من المنصة و [شركة حلول البيع لتقنية المعلومات] – يشار للمنصة والشركة مجتمعين لاحقًا باسم ("المنصة") – وكل من يستعرض ويستخدم الموقع سواء بتسجيل او من دون تسجيل ("المستخدم").
<br/>
<br/>

2.	لكونك تستعرض وتستخدم المنصة بأي شكل كان فإنك تعد بذلك مستخدمًا لها وتوافق على الالتزام بكل ما يرد في اتفاقية المستخدم هذه وجميع السياسات والشروط الأخرى.
<br/>
<br/>

3.	 يحق للمنصة التعديل على هذه الاتفاقية والتعديل على السياسات والشروط الأخرى في أي وقت وتعتبر التعديلات ملزمة لجميع الأطراف بمجرد نشرها في المنصة. 
<br/>
<br/>


4.	يجب على المستخدم قراءة الشروط والأحكام والسياسات في كل مرة يقوم فيها باستخدام المنصة لضمان العلم بالأحكام السارية وقت الاستخدام. 
<br/>
<br/>
<br/>
<br/>

<span style={{fontWeight:500, marginTop:"2rem"}}>
المادة الثانية: مسؤولية المنصة والمستخدم:

</span>
<br/>

1.	تمكن المنصة المستخدم من عرض السلع وبيعها وشراءها والمزايدة عليها وتسهل ذلك دون أن تكون المنصة مسؤولة عن أي إخلال يحدث في أي من مراحل العرض، والبيع، والمزايدة، والشراء.
<br/>
<br/>

2.	 لا تقدم المنصة أي ضمان حالة عدم التزام المستخدم (سواء كان بائعًا أو مشتر) بأحكام اتفاقية المستخدم والسياسات والشروط الأخرى، وتخلي المنصة مسؤوليتها من أي مخاطرة، أو أضرار، أو تبعات، أو خسائر تقع بسبب ذلك على البائع أو المشتري أو أي طرف آخر.
<br/>
<br/>

3.	المستخدم يقر بتحمل كامل المسؤولية في التأكد من اتباع جميع القوانين، واللوائح، والأنظمة ذات الصلة عند استخدام المنصة، ويتحمل مسؤولية إدارة جميع عمليات الشراء وعمليات التسليم وحل المشكلات والنزاعات الناشئة عن محتوى الإعلان مسؤولية كاملة.
<br/>
<br/>

4.	يلتزم المستخدم بضوابط وشروط النشر والإعلان الموضحة من قبل المنصة ويتحمل وحده تبعات وأضرار ذلك ويخلي مسؤولية المنصة من أي مطالبات ناتجة أو تابعة لـ تعاملاته أو مترتبة عليها.
<br/>
<br/>

5.	تلتزم المنصة باتفاقية المستخدم، ولكنها ليست طرفًا في أي خلاف أو قضايا تنشأ بين المستخدمين، ومع ذلك فالمنصة تسعى للحد والقضاء على التعديات التي يقوم بها بعض المستخدمين على المنصة وذلك تحقيقا لنزاهة البيع والشراء ومحاربة النصب والاحتيال والغش تطبيقاً للقوانين والتشريعات والتنظيمات المتبعة في المملكة العربية السعودية وبذلك فإنه يحق للمنصة اتخاذ الإجراء اللازم تجاه أي فرد أو جهة اعتبارية مخالفة، علما أن الإجراء قد يصل إلى الملاحقة القانونية والقضائية أمام الجهة ذات العلاقة.
<br/>
<br/>
<br/>
<br/>


<span style={{fontWeight:500, marginTop:"2rem"}}>
المادة الثالثة: أطراف التعاقد:
</span>
<br/>
<br/>

1.	المنصة تقدم خدمة ربط المستخدمين بصفتهم بائعين أو مشترين عبر عرض السلع والمزايدة عليها وشرائها، وعليه فجميع الصفقات التي تتم في المنصة تنشئ عقودًا بين المستخدمين أنفسهم حصرًا، ولا تعد المنصة بأي حال من الأحوال طرفًا في هذه التعاقدات، ولا تتحمل مسؤولية إخلال أي طرف منهم بالتزاماته.
<br/>
<br/>
2.	تمكن المنصة المستخدمين من التواصل بين بعضهم البعض عبر الرسائل أو بالطريقة التي يختارونها قبل إتمام الصفقات وتتيح لهم التحقق من موثوقية البائعين أو المشترين بكافة الطرق الممكنة، وتتيح لهم الاجتماع ومعاينة السلعة ومبادلتها بالثمن يدًا بيد، وبذلك يكون المستخدم وحده هو المسؤول ويتحمل تبعة حدوث أي إخلال من الطرف الآخر باتفاقية المستخدم أو بالشروط والأحكام الأخرى أو عند تعرضه لعملية احتيال في السلعة أو مواصفاتها أو قيمتها.
<br/>
<br/>
3.	عند تعرض المستخدم لعملية نصب واحتيال من قبل طرف آخر فيجب عليه إبلاغ الجهات الأمنية المختصة، ولن تكون المنصة طرفًا في هذه القضايا والشكاوى ولن تتحمل أي مسؤولية في ذلك. كما يجب على المستخدم إبلاغ المنصة بتعرضه لذلك وستقوم إدارة المنصة بمراجعة الشكوى والتحقق منها ليتم اتخاذ الإجراء اللازم في حق الطرف الآخر، ويمكن للمنصة أن تقدم ما لديها من بيانات للجهات المختصة حال مناسبة ذلك. 
<br/>
<br/>
<br/>
<br/>

<span style={{fontWeight:500, marginTop:"2rem"}}>
المادة الثالثة: عرض السلع والإعلان عنها:

</span>
<br/>


1.	يمكن للمستخدم عرض أي سلعة للبيع وفقًا لاتفاقية الاستخدام والشروط والأحكام الأخرى وباتباع الأنظمة واللوائح السارية في المملكة. 
<br/>
<br/>

2.	يتقيد المستخدم بتصنيفات المنصة عند عرضه للسلع.
<br/>
<br/>

3.	يمكن أن تكون حالة السلعة المعلن عنها جديدة أو مستعملة وفقًا للتفصيل التالي:
<br/>
<br/>

‌أ.	جديدة:
<br/>
<br/>

1)	جديدة بحالة المصنع 100٪.
<br/>
<br/>

2)	جديدة (غير مستعملة نهائيًا) ولكن تغيرت بشكل يسير عن حالة المصنع.
<br/>
<br/>

‌ب.	مستعملة:
<br/>
<br/>

1)	ممتازة: المنتج يعمل بشكل سليم و لا يحتوي على خدوش.
<br/>
<br/>

2)	جيدة جدا: يحتوي المنتج على بعض الخدوش و لكنه نظيف و قابل للاستخدام بشكل سليم.
<br/>
<br/>

3)	جيدة: يحتوي المنتج على خدوش و بعض الكسور التي لا تعيق استخدامه.
<br/>
<br/>

4)	مقبولة: يحتوي المنتج على خدوش و كسور التي قد تعيق استخدام المنتج.
<br/>
<br/>

4.	عند إضافة المستخدم لأي إعلان في المنصة فيلتزم بتعبئة كافة البيانات والمعلومات وتوضيح حالة السلعة والموعد الأقصى لتسليمها جميع ذلك بشكل صحيح ودقيق، ويلتزم بأن تكون الصور ومقاطع الفيديو للسلعة حديثة ومطابقة لحالتها عند الإعلان.
<br/>
<br/>

5.	عند تغير حالة السلعة أو بيعها أو حدوث أي أمر يستوجب ذلك فيلتزم المستخدم مباشرة بتحديث الإعلان أو حذفه – بحسب الأحوال –.
<br/>
<br/>

6.	يتحمل المستخدم الذي يعرض سلعته مسؤولية إخلاله في أي جانب من جوانب الإعلان سواء بما يتعلق بالسلعة ذاتها، أو مواصفاتها، أو توصيلها، أو قيمتها، أو تلفها.
<br/>
<br/>

7.	عند عرض السلعة يحدد المستخدم آلية شرائها من شراء مباشر أو مزاد وفقًا للأحكام الخاصة بذلك والمبينة في هذه الاتفاقية.
<br/>
<br/>

8.	يعد عرض السلعة في المنصة إيجابًا من المستخدم ببيعها، ويجب عليه الالتزام بتسليم السلعة لمن يشتريها بالسعر المحدد أو من يرسو عليه المزاد وفقًا للأحكام المحددة في هذه الاتفاقية، ويتحمل البائع في هذه الحالة مسؤولية تخلفه عن الالتزام بذلك، ويلتزم بتعويض المشتري والمنصة عن أي أضرار أو تبعات ناشئة عن ذلك.
<br/>
<br/>
<br/>
<br/>

<span style={{fontWeight:500, marginTop:"2rem"}}>
المادة الرابعة: طرق البيع:
</span>
<br/>

1.	 الشراء المباشر:
<br/>
<br/>

‌أ.	تتيح المنصة للمستخدم عرض السلعة وتحديد سعر بيعها، ويعتبر هذا السعر ملزمًا للطرف البائع الذي عرض السلعة.
<br/>
<br/>
‌
ب.	المستخدم الذي يختار شراء السلعة المعروضة وفقًا لسعر محدد يكون ملتزمًا بدفع المبلغ للمشتري وفقًا لأحكام هذه الاتفاقية.
<br/>
<br/>
‌
ج.	للمستخدم المعلن حذف الإعلان في أي وقت، دون إخلال بأي تعاقدات أو صفقات تمت قبل وقت الحذف.
<br/>
<br/>
‌
د.	يمكن للمستخدم المعلن للسلعة عبر خيار الشراء المباشر إتاحة خيار (تقديم عرض) والذي يمكن بقية المستخدمين من تقديم عروضهم لشراء السلعة بمبلغ يقل عن سعر الشراء المباشر، ويكون للمعلن صلاحية قبول ورفض هذه العروض.
<br/>
<br/>

2.	المزاد:
<br/>
<br/>

‌أ.	تتيح المنصة للمستخدم عرض السلعة وبيعها وفقًا لمزاد تعقد بين مستخدمي المنصة، ويجب في هذه الحالة على الطرف البائع تحديد المبلغ الذي يبدأ منه المزاد، ومقدار مبلغ المزايدة، وموعد بداية وانتهاء المزاد (الوقت والتاريخ).
<br/>
<br/>
‌
ب.	في حالة عدم سداد المستخدم الذي رسى عليه المزاد للمبلغ المتبقي من ثمن السلعة خلال (24) ساعة من وقت انتهائه دون سبب تقبله المنصة؛ فيمكن للمنصة عندها حظر حساب هذا المستخدم واتخاذ ما يلزم حياله.
<br/>
<br/>
‌
ج.	يكون الحد الأدنى لوقت المزاد (4) ساعات، والحد الأعلى (5) أيام.
<br/>
<br/>
‌
د.	يمكن للبائع إلغاء إعلان المزاد قبل (6) ساعات من نهاية مدته كحد أقصى.
<br/>
<br/>

3.	يمكن للمستخدم عرض السلعة في المزاد مع تحديد سعر لشرائها مباشرة.
<br/>
<br/>
<br/>
<br/>


<span style={{fontWeight:500, marginTop:"2rem"}}>

المادة الخامسة: الشحن والتوصيل:
</span>
<br/>

1.	يلتزم البائع بأن تكون السلعة مغلفة ومعدة للشحن بشكل متناسب مع نوعها وقيمتها.
<br/>
<br/>

2.	يحدد البائع في إعلانه الموعد الأقصى لتسليم السلعة لشركة الشحن على ألا تتجاوز (5) أيام عمل من تاريخ الشراء.
<br/>
<br/>

3.	عند اكتمال صفقة البيع فتشارك المنصة وثيقة التوصيل مع البائع ويجب عليه طباعتها وتزويد شركة الشحن بها عند استلامهم البضاعة خلال المدة المحددة لتسليم السلعة.
<br/>
<br/>

4.	عند إخلال البائع بالتزامه وفقًا لهذه المادة فيتحمل مسؤولية كامل الأضرار الناتجة عن ذلك وللمنصة اتخاذ الإجراءات اللازمة حياله.
<br/>
<br/>

5.	يكون تسليم السلعة للمشتري حسب موقعه وبعده من مكان البائع على ألا تتجاوز المدة في جميع الأحوال (15) يوم عمل ما لم تحدث ظروف قاهرة – خارجة عن يد البائع والمنصة وشركات الشحن – تتسبب بتأخير تسليم السلعة.
<br/>
<br/>

6.	خدمة التوصيل حاليًا محددة بالمناطق داخل المملكة.
<br/>
<br/>

7.	خدمة التوصيل تتم بشكل كامل عبر شركات الشحن، ولا علاقة للمنصة بذلك سوى تسهيلها للعملية عبر المنصة، وعليه فلا تتحمل المنصة أي مسؤولية عند إخلال هذه الشركات بمسؤولياتها وواجباتها.
<br/>
<br/>

8.	يمكن للبائع إتاحة خيار تسليم السلعة يدًا بيد للمشتري، ويكون الخيار للمشتري في تحديد طريقة الاستلام عند شرائه للسلعة أو المزايدة عليها، ويجب في هذه الحالة تأكيد المشتري استلام السلعة خلال (3) أيام من تاريخ شرائها عبر المنصة وإلا فإن العملية تعتبر ملغاة ويتحمل المشتري تكاليف الإلغاء.
<br/>
<br/>
<br/>
<br/>

<span style={{fontWeight:500, marginTop:"2rem"}}>
المادة السادسة: الدفع ونسبة المنصة:
</span>
<br/>
1.	يلتزم المشتري بسداد ثمن السلعة سواء كانت الصفقة صفقة شراء مباشر أو عبر مزاد، ويتحمل مسؤولية تخلفه أو إخلاله بالتزامه، ويجب عليه تعويض البائع أو المنصة عن الأضرار الناتجة عن هذا الإخلال.
<br/>
<br/>

2.	يجب أن يكون السداد عبر خدمات الدفع الالكتروني المقدمة في المنصة، ويمكن للمشتري والبائع الاتفاق على طريقة سداد أخرى بشرط سداد نسبة المنصة.
<br/>
<br/>

3.	تستحق المنصة نسبة من ثمن بيع السلعة مقابل تمكين المستخدمين من عرض السلع وشرائها والمزايدة عليها عبر المنصة وتسهيل خدمات التوصيل، وهذه النسبة مستحقة على البائع، وتخصم مباشرة من المبلغ المسدد عبر المنصة.
<br/>
<br/>

4.	في حال تم السداد بطريقة أخرى بين المشتري والبائع خارج المنصة، فيجب على البائع سداد النسبة المستحقة للمنصة عن طريق الخانة المخصصة لذلك.
<br/>
<br/>

5.	يتم تحديد نسبة المنصة تلقائيا وذلك وفقًا لما يلي:
<br/>
<br/>

‌أ.	نسبة (7٪) من ثمن البيع عن المبلغ من ريال إلى 2000 ريال.
<br/>
<br/>

‌ب.	نسبة (5٪) من ثمن البيع عن المبلغ من 2001 إلى 5000 ريال.
<br/>
<br/>

‌ج.	نسبة (2.5٪) من ثمن البيع عن المبلغ من 5000 ريال فأكثر.
<br/>
<br/>
6.	النسبة المستحقة للمنصة غير مستردة في حال حدوث عملية استبدال أو استرجاع للسلعة.
<br/>
<br/>

7.	لا يتم إيداع المبلغ في حساب البائع إلا:
<br/>
<br/>

‌أ.	 بعد توصيل السلعة لعنوان المشتري وفقًا لبيانات شركة الشحن في حال كانت السلعة ستسلم عن طريقها، أو
<br/>
<br/>

‌ب.	بعد تأكيد المشتري أنه استلم الشحنة في حال كان التسليم يدًا بيد. وتقع المسؤولية في هذه الحالة على البائع في التحقق من تأكيد المشتري لاستلامه للسلعة عبر المنصة.
<br/>
<br/>
<br/>
<br/>

<span style={{fontWeight:500, marginTop:"2rem"}}>
المادة السابعة: الاسترجاع والاستبدال:
</span>
<br/>

1.	يلتزم البائع بمدة الاسترجاع والاستبدال على جميع السلع التي يعرضها في الموقع وهي مدة (7) أيام من تاريخ توصيل السلعة للمشتري أو تسلمه لها في حالة كان التسليم يدًا بيد.
<br/>
<br/>

2.	تتم عملية الاسترجاع والاستبدال بين البائع والمشتري بشكل مباشر دون أي تدخل من المنصة في أي من جوانب ذلك سواء منها المتعلقة بإعادة توصيل وتسليم السلعة للبائع، أو برد الثمن للمشتري.
<br/>
<br/>

3.	عند ادعاء المشتري اختلاف السلعة المشحونة إليه عن السلعة المعروضة في المنصة سواء في مواصفاتها أو في حالتها من حيث كونها جديدة أو مستعملة فلا تتحمل المنصة أي مسؤولية. ومع ذلك فللمنصة اتخاذ ما يلزم بحق أي مستخدم حال تحققها من وجود عملية تحايل أو غش من قبله، وتقديم ما لديها للجهات المختصة عند الحاجة.
<br/>
<br/>

4.	تخضع أحكام الاسترجاع والاستبدال للأنظمة واللوائح المعمول بها في المملكة، ويكون المستخدم مسؤولًا عن تقيده بذلك.
<br/>
<br/>
<br/>
<br/>

<span style={{fontWeight:500, marginTop:"2rem"}}>

المادة الثامنة: التسجيل في المنصة:
</span>
<br/>

1.	يلتزم المستخدم (الفرد) عند تسجيله في المنصة بتقديم رقم جوال سعودي على أن يكون المستخدم مالكًا للرقم وقت تسجيله، وفي حال اختلاف المستخدم عن مالك الرقم فيكون مالك الرقم هو المسؤول عن جميع العمليات التي يجريها المستخدم في المنصة.
<br/>
<br/>

2.	يلتزم المستخدم الفرد عند رغبته بعرض سلع للبيع عبر المنصة بتقديم شهادة الآيبان لحسابه البنكي صادرة من أحد البنوك في المملكة العربية السعودية لاستلام مبلغ البيع، وبتوثيق الحساب بتقديم بيانات الهوية الوطنية أو جواز السفر والتحقق من ذلك وفقًا لإجراءات المنصة.
<br/>
<br/>

3.	يلتزم المستخدم (المؤسسة أو الشركة) عند تسجيله في المنصة بتقديم رقم السجل التجاري للشركة، وصورة منه، مع تقديم شهادة الآيبان للحساب البنكي صادرة من أحد البنوك في المملكة العربية السعودية. 
<br/>
<br/>
<br/>
<br/>

<span style={{fontWeight:500, marginTop:"2rem"}}>

المادة التاسعة: سياسة البيانات والخصوصية:
</span>
<br/>
<br/>

1.	تحتفظ المنصة وتخزن البيانات الخاصة بالمستخدمين وتشمل البيانات والمعلومات المقدمة من المستخدمين بمحض إرادتهم، والبيانات والمعلومات التي تحصل المنصة عليها بشكل تلقائي والمتعلقة ببيانات تصفح المنصة وزيارتها.
<br/>
<br/>

2.	تجمع المنصة البيانات والمعلومات التالية وتحتفظ بها وتستخدمها، ومن هذه البيانات:
<br/>
<br/>

‌أ.	اسم وبيانات المستخدم الشخصية.
<br/>
<br/>

‌ب.	رقم الجوال.
<br/>
<br/>

‌ج.	البريد الالكتروني.
<br/>
<br/>

‌د.	بيانات الهوية الوطنية أو جوازات السفر – إن وجدت –.
<br/>
<br/>

‌ه.	بيانات السجل التجاري – إن وجدت –.
<br/>
<br/>

‌و.	بيانات الحسابات البنكية – إن وجدت –.
<br/>
<br/>

‌ز.	عنوان الـ (IP) وبيانات التصفح، والأجهزة المستخدمة.
<br/>
<br/>

‌ح.	بيانات البحث في المنصة.
<br/>
<br/>

‌ط.	محتوى المراجعات والتقييمات.
<br/>
<br/>

‌ي.	بيانات العقود والصفقات التي تتم عبر المنصة.
<br/>
<br/>

‌ك.	بيانات الشحن والتوصيل.
<br/>
<br/>

3.	تستخدم المنصة بيانات ومعلومات المستخدمين لـتطوير خدمات المنصة، ومن ذلك:
<br/>
<br/>

‌أ.	 تطوير خدماتها ومنتجاتها وتحسين خدمات المنصة.
<br/>
<br/>

‌ب.	إكمال بيانات العقود بين المستخدمين.
<br/>
<br/>

‌ج.	التواصل مع المستخدمين.
<br/>
<br/>

‌د.	الإعلان عن المنتجات المناسبة وتخصيص الاستخدام لكل مستخدم.
<br/>
<br/>

‌ه.	الصفقات التي تتم في المنصة هي بين المستخدمين
<br/>
<br/>

‌و.	 الوفاء بالالتزامات القانونية والحفاظ على حقوق المستخدمين سواء كانوا بائعين أو مشترين عند وقوع حالات احتيال أو غش أو اختلافات بين المستخدمين، واستخدامها قدر الحاجة مع الجهات المختصة.
<br/>
<br/>
<br/>
<br/>

<span style={{fontWeight:500, marginTop:"2rem"}}>


المادة العاشرة: الشكاوى والمنازعات:
</span>
<br/>

1.	عند وجود شكوى، أو ملاحظة متعلقة بالمنصة، أو المستخدمين، أو الإعلانات، أو السلع المعلن عنها فللمستخدم تقديم شكاواه للمنصة وفقًا للنموذج المخصص لذلك.
<br/>
<br/>

2.	يتم مراجعة الشكوى من قبل الفريق المختص في المنصة وحلها خلال أسرع وقت ممكن.
<br/>
<br/>

3.	الصفقات والعقود الناشئة عن الإعلانات في المنصة هي بين المستخدمين أنفسهم والمنصة ليست طرفًا في أي منها ولا تتحمل مسؤولية تخلف أي طرف عن التزاماته. وفي حال حدوث أي نزاع بين المستخدمين متعلق بالسلعة أو الإعلان الخاص بها أو تسليمها، أو مواصفاتها، أو حالتها، أو الثمن، ومقداره، وآلية سداده أو غير ذلك فإن:
<br/>
<br/>

‌أ.	حل النزاع يكون بين المستخدمين أنفسهم وفق الطرق النظامية والقضائية المخصصة لذلك.
<br/>
<br/>

‌ب.	يمكن رفع الشكوى عبر المنصة وستسعى المنصة وفق تقديرها – دون أن يكون ذلك ملزمًا – للتواصل مع أطراف النزاع وحل النزاع وديًا قدر المستطاع دون أن تتحمل المنصة أدنى مسؤولية عن أي طرف، ودون أن تكون مسؤولة عن الوصول للحل الودي.
<br/>
<br/>
<br/>
<br/>

<span style={{fontWeight:500, marginTop:"2rem"}}>


المادة الحادية عشرة: أحكام عامة:
</span>
<br/>

1.	يقر المستخدم بأهليته القانونية للدخول في هذه الاتفاقية وفقاً لجميع الشروط والأحكام الواردة فيها.
<br/>
<br/>

2.	يخضع استخدام المنصة وتخضع بنود هذه الاتفاقية للأنظمة والتشريعات والتعليمات السارية والمتبعة في المملكة العربية السعودية.
<br/>
<br/>

3.	إذا أصبح أي حكم من أحكام هذه الاتفاقية ملغيًا أو غير سار بموجب أية أنظمة أو قوانين أو أحكام قضائية، فإن باقي الأحكام والشروط الأخرى في هذه الاتفاقية تبقى سارية ومعمول بها.
<br/>




      </p>
      </div>

            </div>
        );
}
 
export default UserAgreement;