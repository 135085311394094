import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CiEdit } from 'react-icons/ci';
import { FaRegTrashCan } from 'react-icons/fa6';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { doc, updateDoc, getDoc, collection, Timestamp, addDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { formatDistanceToNow } from 'date-fns';
import arLocale from 'date-fns/locale/ar-SA';
import { Divider } from '@mui/material';
import { MdOutlineWatchLater } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { Oval } from 'react-loader-spinner'
import { FaStore } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io"; 
import { HiOutlineExclamationCircle } from "react-icons/hi";
import SuccessAnim from '../assets/animations/success.json'
import { getFunctions, httpsCallable } from "firebase/functions";
import { AiFillStar } from "react-icons/ai";


function Comment({ userName, isUpdated, userImg, commentRef, userRating, numRaters, link, comment, timestamp, userId, commentId, postId
,handleDeleteComment, isVerified, handleUpdateComment, isCommentOptionsOpen, onClose, isBusiness, setIsReportCommentOpen

}) {
  const date = timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;

  // Format the date
  const timeAgo = date ? formatDistanceToNow(date, { addSuffix: true, locale: arLocale }) : '';

  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [originalComment, setOriginalComment] = useState(comment);
  const [newComment, setNewComment] = useState('');
  const [myUserId, setMyUserId] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false)
  const [commentOptionsLoading, setCommentOptionsLoading] = useState(false)
  const [shouldRender, setShouldRender] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessAnim, setShowSuccessAnim] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => setShouldRender(false), 500); // Duration matches the animation time
  };


  useEffect(() => {
    const getUserId = async () => {
      if (auth.currentUser && auth.currentUser && !auth.currentUser.isAnonymous ) {
        const userDocRef = doc(db, 'Users', auth.currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        setMyUserId(userDocSnapshot.data().id);
      }
    };
    getUserId();
  }, []);

  const updateComment = async (commentId, updatedCommentText) => {
    try {
      if (!commentId) {
        console.error('CommentId is empty or undefined');
        return;
      }
  
      // Show loading state
      setUpdateLoading(true);
  
      // Reference the Firebase Cloud Function
      const functions = getFunctions();
      const updateCommentFn = httpsCallable(functions, 'updateComment');
  
      // Call the function with commentId and updatedCommentText
      const result = await updateCommentFn({ commentId, updatedCommentText });
  
      // console.log(result.data.message); // Logs the success message
  
      // Update the state/UI after successful update
      setOriginalComment(updatedCommentText);
      setNewComment("");
    } catch (error) {
      console.error('Error updating comment:', error);
    } finally {
      // Hide loading state
      setUpdateLoading(false);
    }
  };
  

  const deleteComment = async (commentId) => {
    try {
      // Show confirmation message before deleting the comment
      const confirmation = window.confirm('حذف التعليق؟');
      
      if (!confirmation) {
        return; // If user cancels, exit the function
      }
      
      setCommentOptionsLoading(true);
  
      // Reference the Firebase Cloud Function
      const functions = getFunctions();
      const deleteCommentFn = httpsCallable(functions, 'deleteComment');
  
      // Call the function with the commentId
      const result = await deleteCommentFn({ commentId });
  
      // console.log(result.data.message); // Logs the success message
  
      // Handle UI updates after successful deletion
      handleDeleteComment(commentId);
    } catch (error) {
      console.error('Error deleting comment:', error);
    } finally {
      setCommentOptionsLoading(false);
    }
  };

  const reportComment = async () => {
    if (reportReason === "") {
      alert("يرجى اختيار نوع البلاغ");
      return;
    }
  
    try {
      setReportLoading(true);
  
      // Reference the Firebase Cloud Function
      const functions = getFunctions();
      const reportCommentFn = httpsCallable(functions, 'reportComment');
  
      // Call the function with the required data
      const result = await reportCommentFn({
        reportReason,
        reportNotes,
        reporterId: parseFloat(userId),
        targetId: commentId,
      });
  
      console.log(result.data.message); // Logs the success message
  
      // Handle UI updates after successful reporting
      setReportCommentIsOpen(false);
      setReportCommentShouldRender(false);
      alert("تم الإبلاغ عن التعليق");
    } catch (error) {
      console.error("Error reporting comment:", error);
    } finally {
      setReportLoading(false);
      setReportNotes("");
    }
  };

const[reportCommentShouldRender, setReportCommentShouldRender] = useState(false);
const[reportCommentIsOpen, setReportCommentIsOpen] = useState(false);
  


useEffect(() => {
  if (reportCommentIsOpen) {
    setIsReportCommentOpen(true)
    setReportCommentShouldRender(true);
  }
}, [reportCommentIsOpen]);

const handleCloseReportComment = () => {
  if(reportLoading) return;
  setReportCommentIsOpen(false);
  setIsReportCommentOpen(false)
  setTimeout(() => setReportCommentShouldRender(false), 500); // Duration matches the animation time
};

const [reportReason, setReportReason] = useState("");
const [reportNotes, setReportNotes] = useState("");
const [reportLoading, setReportLoading] = useState("");

const handleAnimationComplete = () => {
  setShowSuccessAnim(false); // Reset button state after animation completes
};




  return (
    <>
        {/* {
          showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />
        } */}
{reportCommentShouldRender && (
  <div
    style={{
      backgroundColor: "rgba(20, 0, 0, 0.2)",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      zIndex: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      opacity: reportCommentIsOpen ? 1 : 0,
      transition: "opacity 0.1s ease-out",
      top:0,
      left:0
    }}
  >
    <div style={{
      width: window.innerWidth > window.innerHeight ? "35%" : "90%",
      height: "fit-content",
      backgroundColor: "white",
      position: "relative",
      margin: "auto",
      opacity: 1,
      zIndex: 30,
      borderRadius: "16px",
      padding: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      transform: "translateY(-15%)",
    }}
    className={reportCommentIsOpen ? "pop-up" : "pop-down"}
    >
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "8px"
      }}>
        <div style={{ width: 32, height: 32 }}></div> {/* Empty div for balance */}
        <div style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center"
        }}>
          <h5 style={{  }}>الإبلاغ عن تعليق</h5>
        </div>
          <IoIosCloseCircle size={27} style={{ cursor: "pointer" }} color='#ccc' onClick={handleCloseReportComment} />
      </div>
      <div style={{display:"flex", direction:"rtl"}}>


<p style={{color:"red"}}>*</p>
<select
  id="input-field"

  onChange={(event) => {
    const selectedValue = event.target.value;
    setReportReason(selectedValue);
  }}
  style={{ color: "black", borderRadius: "4px", marginBottom:"8px", cursor:"pointer" }}
  >
  <option value="">نوع البلاغ</option>  {/* Default option with descriptive text */}
  <option value="لغة مسيئة" style={{ backgroundColor: "white", textAlign: "right" }}>
  اللغة المسيئة: إذا كان التعليق يحتوي على لغة غير لائقة أو ألفاظ مسيئة تسيء للآخرين.
  </option>
  <option value="معلومة خاطئة" style={{ backgroundColor: "white", textAlign: "right" }}>
  المعلومات الخاطئة: إذا كان التعليق يحتوي على معلومات مضللة أو خاطئة قد تضر بالآخرين.
  </option>
  <option value="آخر" style={{ backgroundColor: "white", textAlign: "right" }}>
  آخر (حدد)
  </option>
</select>
  </div>

  {
    reportReason === "آخر" && (
      <textarea 
placeholder='وصف البلاغ'
style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "180px", borderRadius:"8px", outline:"none", padding:"16px 16px 32px 16px", marginTop:"16px", direction:"rtl"}}
onChange={(e) => { setReportNotes(e.target.value) }}>
</textarea>
    )
  }
              <div style={{marginTop:"14px", display:"flex", justifyContent:"space-between", direction:"rtl"}}>
    <button 
    id='my-button' 
    disabled={reportLoading} 
    style={{height:"35px", padding:"4px 16px", width:"100%", marginLeft:"8px"}} onClick={()=>{reportComment()}} >
    {   
     reportLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{marginBottom:"0px", marginRight:"0px"}}>إبلاغ</p>
              }
    </button>
    <button id='my-button-red' disabled={reportLoading} style={{padding:"4px 8px",  width:"100%", height:"35px"}} onClick={()=>{
                setReportReason("");
                setReportNotes("");
                reportCommentIsOpen(false);

    }} >إلغاء</button>
    </div>
    </div>
  </div>
)}


    <div
      onClick={()=>{setIsOpen(false)}} // Close dropdown when clicking anywhere in the parent component
    >
      <div
        style={{
          width: '100%',
          wordBreak: 'break-word',
          paddingTop: '8px',
          position: 'relative',
        }}
        onClick={() => setIsOpen(false)} // Close dropdown when clicking on the yellow div
      >
        <Link to={link} style={{ display: 'contents', color: 'black', textDecoration: 'none' }}>
          <div style={{ display: 'flex', width: 'fit-content', paddingLeft: '32px' }}>
            <img className='skeleton-profileImg-comment' src={userImg} width={60} height={60} style={{ borderRadius: '1000px', objectFit: 'cover', border:"0.5px solid #E8E9E9", opacity:1 }} />
            <div>
              <div style={{display:"flex"}}>
              <h6 style={{ marginRight: '8px', marginBottom: '0px', width: 'fit-content', whiteSpace: 'nowrap' }}>{userName}</h6>
              {!isBusiness && isVerified && (
                        <RiVerifiedBadgeFill size={22} color='#00c8b8' style={{marginRight:"4px"}}/>
                        )}
            {isBusiness && isVerified && <FaStore size={25} style={{ position: "relative", marginRight: "8px" }} color='#F00057' />}

            <div style={{ display: "flex", alignItems: 'center', marginLeft: 'auto', whiteSpace: 'nowrap', marginRight:"4px" }}>
                <AiFillStar size={17} style={{ marginTop: "3px" }} color='gold' />
                <p style={{ marginBottom: "0px", fontSize: "13px", marginLeft: '4px' }}>{userRating.toFixed(1)}</p>
                <p style={{ marginBottom: "0px", marginLeft: '4px', fontSize: "13px" }}>({numRaters || 0})</p>
              </div>
              </div>
              <div style={{display:"flex", direction:"rtl", marginRight:"8px", marginTop:"4px", alignItems:"center"}}>
              <MdOutlineWatchLater size={14} color='GrayText' />

              <p style={{ marginRight: '2px', marginBottom: '0px', width: 'fit-content', whiteSpace: 'nowrap', fontSize: '12px', color: 'GrayText', direction: 'rtl' }}>{timeAgo}{isUpdated && <CiEdit style={{ marginRight: '4px' }} />}</p>
              </div>
            </div>
          </div>
        </Link>

        {auth.currentUser && !auth.currentUser.isAnonymous && commentRef === 'Post' && (
          !commentOptionsLoading ? (
            <>
                      <div
            style={{
              position: 'absolute',
              padding: '0px 4px',
              left: 0,
              top: 8,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation(); // Stop event propagation to prevent it from reaching the parent div
              toggleDropdown();
            }}
          >
            <BsThreeDotsVertical />
          </div>
            </>
          ) : (
            <div
            style={{
              position: 'absolute',
              padding: '0px 4px',
              left: 0,
              top: 8,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
                    <Oval
          height={"22px"}
          width={"22px"}
          color={"#00C8B8"}
          wrapperClass=""
          visible={true}
          wrapperStyle={{ display: "block" }}
          ariaLabel='oval-loading'
          secondaryColor="#ccc"
          strokeWidth={2}
          strokeWidthSecondary={1}
        />
          </div>
          )
        )}

{shouldRender && (
  userId === myUserId ? (
    <div
    className={isOpen ? 'pop-up' : 'pop-down'}
    style={{
      width: 'fit-content',
      // padding: '8px 16px',
      position: 'absolute',
      backgroundColor: 'white',
      left: 30,
      top: -10,
      direction: 'rtl',
      borderRadius: '8px',
      boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
      // border: "1px solid #E8E9E9"
    }}
  >
    <div
style={{
transition: 'background-color 0.3s',
padding: '4px 0px 4px 32px',
borderTopLeftRadius:"8px",
borderTopRightRadius:"8px",
cursor: 'pointer',
}}
onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#F7F7F7')}
onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
onClick={() => { setEditMode(true); handleClose(); }}

>
<p
style={{ color: '#00C8B8', margin: 0 , padding:"4px 16px"}}
>
<CiEdit size={18} style={{ marginLeft: '4px' }} />تعديل
</p>
</div>

    <Divider sx={{ width: '100%' }} />
    <div
style={{
transition: 'background-color 0.3s',
padding: '4px 0px 4px 32px',
borderBottomLeftRadius:"8px",
borderBottomRightRadius:"8px",
cursor: 'pointer',
}}
onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#F7F7F7')}
onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
onClick={() => { deleteComment(commentId); handleClose(); }}
>
<p
style={{ color: '#F00057', margin: 0,  padding:"4px 16px" }}
>
<FaRegTrashCan size={14} style={{ marginLeft: '4px' }} /> حذف
</p>
</div>
  </div> 
  ) : (
    <div
    className={isOpen ? 'pop-up' : 'pop-down'}
    style={{
      width: 'fit-content',
      // padding: '8px 16px',
      position: 'absolute',
      backgroundColor: 'white',
      left: 30,
      top: -10,
      direction: 'rtl',
      borderRadius: '8px',
      boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
      // border: "1px solid #E8E9E9"
    }}
  >
    <div
style={{
transition: 'background-color 0.3s',
padding: '4px 0px 4px 32px',
borderTopLeftRadius:"8px",
borderTopRightRadius:"8px",
cursor: 'pointer',
}}
onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#F7F7F7')}
onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
onClick={() => { setReportCommentIsOpen(true); handleClose(); }}

>
<p
style={{ color: '#F00057', margin: 0 , padding:"4px 16px"}}
>
<HiOutlineExclamationCircle  size={18} style={{ marginLeft: '4px', marginBottom:"4px" }} />الإبلاغ عن التعليق
</p>
</div>
  </div>
  )
      )}

      </div>

      <div
        style={{
          direction: 'rtl',
          wordBreak: 'break-word',
          // marginLeft: '16px',
          width: '80%',
        }}
        onClick={() => setIsOpen(false)} // Close dropdown when clicking on the cyan div
      >
        {editMode ? (
          <>
            <textarea defaultValue={newComment || originalComment} id='input-comment' 
            style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "180px", borderRadius:"8px", outline:"none", padding:"16px 16px 32px 16px", marginTop:"16px"}}
            onChange={(e) => { setNewComment(e.target.value) }}>
            </textarea>
            <button onClick={() => {
              if (newComment === '') return;
              updateComment(commentId, newComment);
              setEditMode(false);
            }} id='my-button' disabled={updateLoading} style={{ width: 'fit-content', padding: '0px 16px', borderRadius: '4px', marginTop: '8px', marginBottom: '16px', height:"35px" }}>

{   
     updateLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    maxHeight:"35px" 
      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}>حفظ</p>
              }
            </button>
            <button disabled={updateLoading} onClick={() => { setNewComment(''); setEditMode(false); }} id='my-button' style={{ width: 'fit-content', padding: '8px', backgroundColor: 'transparent', color: 'red', marginRight: '12px', fontSize: '14px', boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.0)' }}>الغاء</button>
          </>
        ) : (
          <p style={{ marginRight: '68px', paddingTop: '8px', paddingBottom: '32px', width: '100%' }}>{newComment === '' ? originalComment : newComment}</p>
        )}
      </div>

      <Divider sx={{ width: '100%' }} />
    </div>
    </>

  );
}

export default Comment;
