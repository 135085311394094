import React, {useState, useEffect} from 'react'
import logo from '../assets/logo.png'
import { GoHomeFill, GoReport } from "react-icons/go";

function AboutUsScreen () {
    // window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: 'instant'
    //   });



    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      // Set the div to visible after a slight delay to ensure the transition happens
      setTimeout(() => setIsVisible(true), 100);
    }, []);


        return (
          <div
          style={{
            direction: "rtl",
            width: window.innerWidth > window.innerHeight ? "80%" : "100%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px",
            height: "fit-content",
            minHeight: "calc(100vh - 65px)",
            marginTop: "32px",
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? "translateY(0)" : "translateY(20px)",
            transition: "opacity 0.3s ease-in-out, transform 0.2s ease-in-out", // Adjust duration as needed
          }}
        >
                                          {/* path */}
            <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
          <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8"}} />
          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>من نحن</h6>
        </div>
      </div>
                
                <h4 style={{marginTop:"16px"}}>من نحن</h4>

                <p style={{marginBottom:"32px", fontSize:"18px", fontWeight:"500"}}>
                منصة "بيع" تُعد المنصة العربية الأولى المتخصصة في بيع المنتجات المستعملة التي تتميز بتقديم خدمة المزاد الإلكتروني. هذه المنصة هي وسيط يجمع بين البائعين والمشترين في بيئة آمنة وموثوقة، حيث يمكن للأطراف تبادل وبيع المنتجات بكل سهولة.

تحرص "بيع" على توفير عملية مزاد شفافة وآمنة، حيث يمكن للمشترين تقديم عروضهم بكل سهولة ومتابعة المزاد بدقة. تدعم المنصة خيارات دفع متعددة تشمل البطاقات الائتمانية، مدى، وApple Pay، مما يسهل على المستخدمين عملية الشراء. كما تقدم المنصة خدمة دعم فني متاحة على مدار الساعة لمساعدة المستخدمين في حل أية تحديات قد تواجههم أثناء استخدام المنصة.
<br/>
<br/>
تتميز "بيع" بسهولة الوصول إليها عبر الأجهزة المختلفة، موفرةً بذلك تجربة مستخدم سلسة سواء عبر الهواتف الذكية أو أجهزة الكمبيوتر. نلتزم باستمرار تحديث المنصة لتحسين تجربة المستخدم وإضافة ميزات جديدة تتماشى مع احتياجات وتوقعات العملاء.

تستخدم "بيع" أحدث التقنيات لضمان أمان المعاملات وحماية بيانات المستخدمين، مما يعزز من ثقة العملاء في النظام الإلكتروني للمنصة. كما يمكن للمستخدمين الجدد إنشاء حساباتهم بسهولة وبدء استخدام المنصة بلا تعقيدات تقنية.
<br/>
<br/>

توفر "بيع" تجربة تسوق شاملة تبدأ من البحث عن المنتجات ولا تنتهي إلا بعد استلامها، مما يجعل منها وجهة مفضلة للمشترين الباحثين عن صفقات مميزة. تسعى المنصة باستمرار لتقديم حلول جديدة تحسن من تجربة كل من البائعين والمشترين، مؤكدة بذلك مكانتها كمنصة فعالة في مجال المزادات الإلكترونية للمنتجات المستعملة في العالم العربي.
                
                
من أبرز مزايا "بيع" توفيرها لنظام تقييم للمستخدمين، يعتمد على تقييمات البائعين والمشترين السابقين، مما يعزز الثقة والمصداقية في التجارة الإلكترونية. تحرص المنصة على تقديم وصف دقيق وصور عالية الجودة للمنتجات المعروضة، لضمان تجربة شراء واقعية ومُرضية.

كما تعمل "بيع" على توفير خدمة الشحن بالتعاون مع شركات الشحن المحلية، لضمان وصول المنتجات بأمان وسرعة إلى العملاء.


                </p>


            </div>
        );
}
 
export default AboutUsScreen;