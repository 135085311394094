import React , {useState, useEffect, useRef} from 'react'
import Category from '../components/Category';
import ElectronicsImg from '../assets/electronics.avif';
import FurnitureImg from '../assets/furniture.jpg';
import autoParts from '../assets/auto-parts.jpg';
import musicInstruments from '../assets/music-instruments.jpeg';
import CustomNavbar from '../components/CustomNavbar';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, onSnapshot, where, Timestamp, Firestore, limit, doc, limitToLast, getDoc } from 'firebase/firestore';
import Product from '../components/Product';
import Button from '@mui/material/Button';
import { Select, MenuItem, List } from '@mui/material';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
import { Divider } from '@mui/material';
import { DropdownDivider } from 'react-bootstrap';
import Dropdown from 'react-dropdown';
import InfiniteScroll from 'react-infinite-scroll-component'; 
import { getDatabase, ref, onValue, set, limitToFirst, orderByChild, query, startAfter, child, update, equalTo } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
import { Oval } from 'react-loader-spinner'
import { db, auth, storage } from '../config/firebase'
import LoadingScreen from './LoadingScreen';

function MyPostsScreen (){
  const [Listing, setListing] = useState([]);
  const [moreListings, setMoreListings] = useState([]);
  const [filteredListing, setFilteredListing] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState("");
  const [qu, setQu] = useState(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid)));
  const[lastDoc, setLastDoc] = useState("");
  const listInnerRef = useRef();
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [seller, setSeller] = useState("");


  const [lastKey, setLastKey] = useState("");
  const [lastValue, setLastValue] = useState("");


  useEffect(() => {
    // if(auth.currentUser == null){
    //   signInAnonymously(auth); 
    // }

      const listener = onSnapshot(qu, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const cities = [];
          querySnapshot.forEach((doc) => {
            // if(Date.now() / 1000 - doc.data()['expiryDate'].seconds < 0){  
              if(!doc.data()['isDeleted']){
                cities.push(doc.data());
              }
              // }
            });
            
            setListing(cities);
            setIsLoading(false);
            setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
            
          } else{
            setListing([]);
            setIsLoading(false);
          } //whatever you choose to do when there are no records;
        },
        (err) => {
          //whatever you want to do in case of an error
        }
        );
        

    // onValue(que, (snapshot) => {
    //   const data = snapshot.val();
    //   const cities = [];

    //   if (snapshot.exists()) {
    //     Object.values(data).map((project) => {
    //       cities.push(project);
    //     });
    //     setListing(cities);
    //     setIsLoading(false);
    //   }
    //   else{
    //     //empty
    //   }
    // });
      
    }, [qu]);
    
    
    const fetchMore = () => {
    //   console.log("more")
    //   onSnapshot(query(collection(db, "Posts"), startAfter(lastDoc),  limit(3)), (querySnapshot) => {
    //     if (!querySnapshot.empty) {
    //       const cities = [];
    //       // console.log(Listing[0]['id']);
    //       querySnapshot.forEach((doc) => {
    //         // console.log(doc.data().id);
    //         // if(Date.now() / 1000 - doc.data()['expiryDate'].seconds < 0){  
    //           cities.push(doc.data());
    //           // }
    //         });
        
    //         setListing((Listing)=> [...Listing, ...cities]);
    //         // setListing(cities);
    //         setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
    //       } else{
    //         setHasMore(false);
    //       } //whatever you choose to do when there are no records;
    //     },
    //     (err) => {
    //   //whatever you want to do in case of an error
    // }
    // );
  }

  // if(Listing.length > 0){
  //   console.log(Listing[0].productName = "hhh")
  // }


  // for(var i = 3; i < 100; i++){
  //   var x = Math.round(Math.random() * 30);
  //   console.log(x);

  //   if(i ==x){

  //     update(ref(database, 'posts/' + `post${i}`), {
  //       //   category: "We",
  //       // condition: "used",
  //       // currentBid : Math.round(i * 35.2),
  //       // id: i,
  //       // postId: i,
  //       // numBidders: i * 9,
  //       // productDescription: "DESCRIPTION",
  //       // productName: `name ${i}`,
  //       imgs: ["https://media.karousell.com/media/photos/products/2023/8/7/samsung_40_inch_smart_tv_1691408232_5072a7e1_progressive_thumbnail.jpg"]
  //     });
  //   }
  // }

  if(isLoading){
    return <LoadingScreen/>
  }

  
  let numProducts;
  
  if(Listing.length == 0){
    numProducts = <h4>لم يتم العثور على منتجات</h4>
  }

  else if(Listing.length == 1){
    numProducts = <h4>تم العثور على منتج واحد</h4>
  }
  else if(Listing.length == 2){
    numProducts = <h4>تم العثور على منتجين </h4>
  }
  
  else if (Listing.length <= 10){
    numProducts = <h4>تم العثور على {Listing.length} منتجات</h4>
  }
  
  else if (Listing.length > 10){
    numProducts = <h4>تم العثور على {Listing.length} منتج</h4>
  }
  
  // else{
  //   numProducts = Listing.length
  // }

  return (
    <div 
      id='products-screen-div'  
      >
        
      <div
        id='no-scroll'
        >

        {numProducts} 

        <div
        id='filter-buttons-wrapper'
        >

        <ul
        id='filter-buttons-div'
        >
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid)))}} id='filter-buttons'>عرض جميع المنتجات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("category", "==", "Electronics")))}} id='filter-buttons'>عرض جميع الالكترونيات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Phones")))}} id='filter-buttons'>جوالات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Head Phones")))}} id='filter-buttons'>سماعات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Screens")))}} id='filter-buttons'>شاشات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Laptops")))}} id='filter-buttons'>لابتوبات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Tablets")))}} id='filter-buttons'>أجهزة لوحية</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Cameras")))}} id='filter-buttons'>كاميرات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Printers")))}} id='filter-buttons'>طابعات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Fridgerators")))}} id='filter-buttons'>ثلاجات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Air Conditioner")))}} id='filter-buttons'>مكيفات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Gaming Consoles")))}} id='filter-buttons'>اجهزة العاب</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("category", "==", "Furniture")))}} id='filter-buttons'>عرض جميع قطع الاثاث</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Chairs")))}} id='filter-buttons'>كراسي</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Sofas")))}} id='filter-buttons'>كنب</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Tables")))}} id='filter-buttons'>طاولات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Shelves")))}} id='filter-buttons'>أرفف</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Wardrobes and Drawers")))}} id='filter-buttons'>دواليب و أدراج</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("subCategory", "==", "Paintings")))}} id='filter-buttons'>لوحات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid),where("category", "==", "Car Parts")))}} id='filter-buttons'>عرض جميع قطع غيار السيارات</li>
        <li onClick={()=>{setQu(query(collection(db, "Posts"), where('sellerId', "==", auth.currentUser.uid), where("category", "==", "Music Instruments")))}} id='filter-buttons'>الات موسيقية</li>
      </ul>
        </div>
      </div>

{/* <InfiniteScroll
dataLength={8}
hasMore={!hasMore}
next={fetchMore}
endMessage={
<p style={{textAlign:'center'}}><b>You've reached the bottom</b></p>
}
scrollThreshold={1}
loader={<h5>Loading...</h5>}
> */}

      {
        Listing.map  ((item) => (
          <div 
          key={item.id}
          >
        <Divider
        style={{ borderBottomWidth: 1, width: "100%"}}
        color='#ccc'
        />

        {/* {await getDoc(doc(db, `Users/${item.seller}`)).then((data)=>{
          console.log(data)
        })} */}

        <Product 
        postId={item.id}
        expiryDate={`${item.expiryDate.seconds}`}
        link={`${item.id}`}
        numBidders={item.numBidders}
        currentBid={item.currentBid}
        imgs={item.imgs[0]}
        acceptsOffers={item.postType.includes("عروض")}
        carPlateKind={item.carPlateKind}
        auctionStartDate={item.auctionStartDate.toDate()}
        condition={item.condition}
        isAuction={item.postType.includes("مزاد")}
        productPrice={item.productPrice}
        isSold={item.isSold}
        category={item.category}
        startingPrice={item.startingPrice}
        productDescription={item.productDescription}
        productSeller={item.seller}
        productName={item.productName}/>
        </div>
        ))}
        {/* </InfiniteScroll> */}
        </div>
  );
}

export default MyPostsScreen;