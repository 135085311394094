import React , {useState, useEffect, useRef} from 'react'
import Category from '../components/Category';
import ElectronicsImg from '../assets/electronics.avif';
import FurnitureImg from '../assets/furniture.jpg';
import autoParts from '../assets/auto-parts.jpg';
import musicInstruments from '../assets/music-instruments.jpeg';
import CustomNavbar from '../components/CustomNavbar';
import { initializeApp } from 'firebase/app';
import { onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, getDocs ,onSnapshot, serverTimestamp ,query, where,getDoc, updateDoc, limit,getCountFromServer ,doc, setDoc, Timestamp, FieldValue, getDocsFromCache, getDocFromCache, writeBatch, orderBy, arrayUnion } from 'firebase/firestore';
import Product from '../components/Product';
import Button from '@mui/material/Button';
import { Select, MenuItem } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'react-bootstrap';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
import { Divider } from '@mui/material';
import { PiGavelFill } from "react-icons/pi";
import { MdOutlineWatchLater } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { AiFillStar } from "react-icons/ai";
import { ref, uploadBytesResumable, getDownloadURL, listAll} from "firebase/storage";
import { MdVerifiedUser } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import Comment from '../components/Comment';
import { HiOutlinePencil } from "react-icons/hi";
import { Oval } from 'react-loader-spinner'
import { FaChevronDown } from "react-icons/fa";
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import { db, auth, storage } from '../config/firebase'
import galleryPlaceholder from '../assets/galley-placeholder.jpg'
import { BrowserRouter, NavLink, Outlet, Route, Routes, Navigate } from 'react-router-dom';
import { FaTrashCan } from "react-icons/fa6";
import { IoWalletOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import LoadingScreen from './LoadingScreen';
import SuccessAnim from '../components/SuccessAnim';
import { GoHomeFill, GoReport } from "react-icons/go";
import { BsPerson } from "react-icons/bs";
import { BsPersonFill } from "react-icons/bs";
import { MdOutlineWatch } from "react-icons/md";
import { MdWatch } from "react-icons/md";
import { RiPriceTag3Line } from "react-icons/ri";
import { RiPriceTag3Fill } from "react-icons/ri";
import { IoBagCheckOutline } from "react-icons/io5";
import { IoBagCheck } from "react-icons/io5";
import { RiFileListFill } from "react-icons/ri";
import { RiFileListLine } from "react-icons/ri";
import { BiSolidComment } from "react-icons/bi";
import { BiComment } from "react-icons/bi";
import { RiStarSLine } from "react-icons/ri";
import { RiStarSFill } from "react-icons/ri";
import { PiGavel } from "react-icons/pi";
import { MdOutlinePlaylistAddCheckCircle } from "react-icons/md";
import { MdPlaylistAddCheckCircle } from "react-icons/md";
import { parseISO, isBefore } from 'date-fns'; // Import date-fns for date comparison
import Offer from '../components/Offer'
import MyProduct from '../components/MyProduct';
import { FaStore } from "react-icons/fa";
import { getFunctions, httpsCallable } from "firebase/functions";
import MyOffer from '../components/MyOffer'
import Order from '../components/Order'
import { IoIosCloseCircle } from "react-icons/io"; 
import { GoPaperclip } from "react-icons/go";
import { MdInfo } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import CustomCheckbox from '../components/CustomCheckbox';
import Rating from '../components/Rating';
import { HiOutlineReceiptPercent } from "react-icons/hi2";
import { HiReceiptPercent } from "react-icons/hi2";
import { LuBadgePercent } from "react-icons/lu";
import { RiDiscountPercentFill } from "react-icons/ri";
import * as XLSX from 'xlsx';
import excelFile from '../assets/excel/ServedCities.xlsx'; // Import the Excel file
import { MdInsertChartOutlined } from "react-icons/md";
import { MdInsertChart } from "react-icons/md";
import {VictoryChart, VictoryBar, VictoryTooltip, VictoryAxis, VictoryContainer} from 'victory'
import { TbReportMoney } from "react-icons/tb";
import { FiPackage } from "react-icons/fi";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title,
} from 'chart.js';
import { Bar } from 'react-chartjs-2'; // Import Bar instead of Line


ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title);

function MyAccountScreen({myId}) {

    const [myProducts, setMyProducts] = useState([]);
    const [mySoldProducts, setMySoldProducts] = useState([]);
    const [myBids, setMyBids] = useState([]);
    const [myPurchases, setMyPurchases] = useState([]);

    const [beneficiaryName, setBeneficiaryName] = useState("")
    const [newBeneficiaryName, setNewBeneficiaryName] = useState("")
    const beneficiaryRef = useRef();
    const [isLoading, setLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fullName, setFullName] = useState("");
    const [newFullName, setNewFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [originalImg, setOriginalImg] = useState("");
    const [balance, setBalance] = useState();
    const [myCommission, setMyCommission] = useState();
    const [feeBalance, setFeeBalance] = useState();
    const [blobImg , setBlobImg] = useState("");
    const [city, setCity] = useState("")
    const [newCity, setNewCity] = useState("")
    const [isVerified, setIsVerified] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [userRating, setUserRating] = useState(0);
    const [numRaters, setNumRaters] = useState(0);
    const [comment, setComment] = useState("");
    const [newEmail, setNewEmail] = useState("")
    const [iban, setIban] = useState("")
    const [newIban, setNewIban] = useState("")
    const [userId, setUserId] = useState("")
    const [comments, setComments] = useState([]);
    const [commentsLoading, setCommentsLoading] = useState(true);
    const [updatingDataLoading, setUpdatingDataLoading] = useState(false);
    const [bank, setBank] = useState("");
    const [newBank, setNewBank] = useState("");
    const [successMessage, setSuccessMessage] = useState('');
    const [myInfoIconColor, setMyInfoIconColor] = useState("#FFF")    
    const [myProductsIconColor, setMyProductsIconColor] = useState("#00C8B8")    

    const [userInfoLoading, setUserInfoLoading] = useState(true);
    const [myProductsIsLoading, setMyProductsIsLoading] = useState(true);
    const [mySoldProductsLoading, setMySoldProductsLoading] = useState(false);
    const [myBidsLoading, setMyBidsLoading] = useState(true);
    const [myPurchasesLoading, setMyPurchasesLoading] = useState(true);

    const [isCached, setIsCached] = useState(false)

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const fullNameRef = useRef();
    const cityRef = useRef();
    const commentRef = useRef();
    const emailRef =useRef();
    const ibanRef =useRef();
    const bankRef =useRef();
    
    const navigate = useNavigate();
    const [showSuccessAnim, setShowSuccessAnim] = useState(false);
    const handleAnimationComplete = () => {
      setShowSuccessAnim(false); // Reset button state after animation completes
    };


    const [offerResponseLoading, setOfferResponseLoading] = useState(false)
    
    const newProfilePictureRef = useRef();
    const [profilePictureUpdated, setProfilePictureUpdated] = useState(false);
    const [newUrl, setNewUrl] = useState("");
    const [phoneNumberUpdated, setPhoneNumberUpdated] = useState(false);

    const [offersLoading, setOffersLoading] = useState(false);
    const [offers, setOffers] = useState([]);
    const [myOffers, setMyOffers] = useState([]);


  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        // Fetch the Excel file from the imported URL
        const response = await fetch(excelFile);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        // Convert the response to an ArrayBuffer
        const arrayBuffer = await response.arrayBuffer();
  
        // Parse the ArrayBuffer using XLSX
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  
        const sheetName = workbook.SheetNames[0]; // Get the first sheet
        const sheet = workbook.Sheets[sheetName];
  
        // Read sheet data as an array of arrays
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
        // Assuming the first column is English and the second is Arabic
        const cityList = data.map(row => ({
          english: row[0], // English city name (value)
          arabic: row[1],  // Arabic city name (label)
        })).filter(city => city.english && city.arabic); // Ensure both columns exist
  
        setCities(cityList);
      } catch (error) {
        console.error('Error fetching or parsing the Excel file:', error);
      }
    };
  
    fetchCities();
  }, []);
    

    const ibanLetterRef = useRef();
    const crRef = useRef();

    const [fileName, setFileName] = useState("");

    const handleFileChange = () => {
      if (ibanLetterRef.current && ibanLetterRef.current.files.length > 0) {
        setFileName(ibanLetterRef.current.files[0].name);
        setIbanLetter(ibanLetterRef.current.files[0])
      } else {
        setFileName("");
      }
    };

    const [crFileName, setCRfileName] = useState("")

    const handleCRFileChange = () => {
      if (crRef.current && crRef.current.files.length > 0) {
        setCRfileName(crRef.current.files[0].name);
        setCR(crRef.current.files[0])
      } else {
        setCRfileName("");
      }
    };


    const [idNumber, setIdNumber] = useState("")
    const [threeNames, setThreeNames] = useState("")
    const [ibanLetter, setIbanLetter] = useState("")

    const idNumberRef = useRef();
    const crNumberRef = useRef();

    const businessVerificationNumberRef = useRef()


    const [verificationRequestIsOpen, setVerificationRequestIsOpen] = useState(false);
    const [verificationRequestShouldRender, setVerificationRequestShouldRender] = useState(false);

    useEffect(() => {
      if (verificationRequestIsOpen) {
        document.body.style.overflow = "hidden";
        setVerificationRequestShouldRender(true);
      }
      else{
        document.body.style.overflow = "auto";
      }
    }, [verificationRequestIsOpen]);
  
    useEffect(() => {
      if (!verificationRequestIsOpen) {
        setTimeout(() => setVerificationRequestShouldRender(false), 100); // Duration matches the animation time
      }
    }, [verificationRequestIsOpen]);

    const [verificationSentLoading, setVerificationSentLoading] = useState(false)

    async function sendVerificationRequest() {

      // Request validation
      if (idNumber === "") {
        alert("حقل رقم الهوية/الإقامة مطلوب");
        return;
      }
      if (idNumber.length !== 10) {
        alert("يجب أن يتكون رقم الهوية/الإقامة من 10 أرقام");
        return;
      }
      if (threeNames === "") {
        alert("حقل الاسم الثلاثي مطلوب");
        return;
      }
    
      const nameParts = threeNames.trim().split(/\s+/);
      if (nameParts.length !== 3 || nameParts.some(part => part.length < 2)) {
        alert("يرجى ادخال الاسم الثلاثي بشكل صحيح");
        return;
      }
      if (!ibanLetter) {
        alert("يجب ارفاق شهادة الايبان");
        return;
      }
    
      // Convert file to base64
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64File = reader.result.split(',')[1]; // Get the base64 data
    
        const data = {
          userId: myId,
          idNumber,
          threeNames,
          ibanLetter: {
            name: ibanLetter.name,
            data: base64File,
            type: ibanLetter.type
          }
        };
    
        try {
          setVerificationSentLoading(true);
    
          const functions = getFunctions();
          const verifyUser = httpsCallable(functions, 'verificationRequest');
    
          const response = await verifyUser(data);
    
          if (response.data.success) {
            setVerificationRequestIsOpen(false);
            setVerificationSentLoading(false);
            setSuccessMessage("!تم ارسال طلبك بنجاح");
            setShowSuccessAnim(true);

            setIdNumber("")
            setThreeNames("")
            setIbanLetter(null)
            setFileName("");
          } else {
            console.error("Error reporting user: ", response.data.error);
            setVerificationRequestIsOpen(false);
            setVerificationSentLoading(false);
          }
        } catch (error) {
          console.error("Error reporting user: ", error);
          setVerificationRequestIsOpen(false);
          setVerificationSentLoading(false);
        }
      };
    
      reader.readAsDataURL(ibanLetter);
    }
    


    const [storeName, setStoreName] = useState("");
    const [CRNumber, setCRNumber] = useState("");
    const [businessVerificationNumber, setBusinessVerificationNumber] = useState("");
    const [CR, setCR] = useState("");








    const [switchToStoreRequestIsOpen, setSwitchToStoreRequestIsOpen] = useState(false);
    const [switchToStoreRequestShouldRender, setSwitchToStoreRequestShouldRender] = useState(false);

    useEffect(() => {
      if (switchToStoreRequestIsOpen) {
        document.body.style.overflow = "hidden";
        setSwitchToStoreRequestShouldRender(true);
      }
      else{
        document.body.style.overflow = "auto";
      }
    }, [switchToStoreRequestIsOpen]);
  
    useEffect(() => {
      if (!switchToStoreRequestIsOpen) {
        setTimeout(() => setSwitchToStoreRequestShouldRender(false), 100); // Duration matches the animation time
      }
    }, [switchToStoreRequestIsOpen]);

    const [switchToStoreRequestLoading, setSwitchToStoreRequestLoading] = useState(false)

    async function sendSwitchToStoreRequest() {
      // Request validation
      if (!storeName) {
        alert("حقل اسم المتجر مطلوب");
        return;
      }
      if (!CRNumber) {
        alert("حقل رقم السجل التجاري مطلوب");
        return;
      }
      if (!businessVerificationNumber) {
        alert("حقل رقم التوثيق من مركز الأعمال السعودي مطلوب");
        return;
      }
      if (!CR) {
        alert("يجب ارفاق السجل التجاري");
        return;
      }
      if (!ibanLetter) {
        alert("يجب ارفاق شهادة الايبان");
        return;
      }
    
      // Function to convert file to base64
      const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(',')[1]); // Get the base64 data
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };
    
      try {
        setSwitchToStoreRequestLoading(true);
    
        // Convert both files to base64
        const crBase64 = await fileToBase64(CR);
        const ibanLetterBase64 = await fileToBase64(ibanLetter);
    
        const data = {
          userId: myId,
          storeName,
          CRNumber,
          businessVerificationNumber,
          CR: {
            name: CR.name,
            data: crBase64,
            type: CR.type
          },
          ibanLetter: {
            name: ibanLetter.name,
            data: ibanLetterBase64,
            type: ibanLetter.type
          }
        };
    
        const functions = getFunctions();
        const verifyUser = httpsCallable(functions, 'switchToStoreRequest');
    
        const response = await verifyUser(data);
    
        if (response.data.success) {
          setSwitchToStoreRequestIsOpen(false);
          setSwitchToStoreRequestLoading(false);
          setSuccessMessage("!تم ارسال طلبك بنجاح");
          setShowSuccessAnim(true);
    
          // Clear fields
          setStoreName("");
          setCRNumber("");
          setBusinessVerificationNumber("");
          setCR(null);
          setIbanLetter(null);
        } else {
          console.error("Error reporting user: ", response.data.error);
          setSwitchToStoreRequestIsOpen(false);
          setSwitchToStoreRequestLoading(false);
        }
      } catch (error) {
        console.error("Error reporting user: ", error);
        setSwitchToStoreRequestIsOpen(false);
        setSwitchToStoreRequestLoading(false);
      }
    }
    
    const handleUpdateUserInfo = async () => {
      const functions = getFunctions();
      const updateUserInfo = httpsCallable(functions, 'updateUserInfo');
    
      try {
        setUpdatingDataLoading(true)
        // Gather user data
        const userData = {
          originalImg: originalImg, // Original profile image URL
          fullName: newFullName,
          email: newEmail,
          city: newCity,
          bank: newBank,
          iban: newIban, // Corrected capitalization
          beneficiaryName: newBeneficiaryName,
          newProfilePictureBase64: profilePictureUpdated
            ? await convertToBase64(newProfilePictureRef.current.files[0])
            : null,
          receiveDMEmail,
          receiveCommentsEmail,
          receiveBuyerCommentsEmail,
          receiveNewBidderEmail,
          receiveNewBidEmail,
          receiveOffersEmail,
          receiveOrderUpdatesEmail,
        };
    
        // Call the backend function with the filtered user data
        const result = await updateUserInfo(userData);
    
        if (result.data.success) {
    
          setSuccessMessage("!تم تحديث معلوماتك بنجاح");
          setShowSuccessAnim(true);
    
          // Update state with the new profile image URL
          const newProfileImgUrl = result.data.profileImgUrl;
          setNewUrl(newProfileImgUrl);
          setOriginalImg(newProfileImgUrl);
          setBlobImg(""); // Clear blob image state
          setUpdatingDataLoading(false);
    
        } else {
          console.error("Error updating user information:", result.data.error);
        }
      } catch (error) {
        console.error("Error calling updateUserInfo function:", error);
      }
      setUpdatingDataLoading(false)

    };
    
    // Utility function to convert file to base64
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result.split(',')[1]); // Return only the base64 part
        };
        reader.onerror = error => {
          console.error("Error converting file to base64:", error);
          reject(error);
        };
      });
    };
    
    
    

    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial call to set the state with current window dimensions
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    
    const location = useLocation();
    const [activeSubScreen, setActiveSubScreen] = useState("");
  
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    useEffect(() => {
      if(lastSegment === "my_sales"){
        setActiveSubScreen("مبيعاتي")
      }
        else if(lastSegment === "my_info"){
        setActiveSubScreen("معلوماتي")
      } else if(lastSegment === "my_products"){
        setActiveSubScreen("منتجاتي")
      } else if(lastSegment === "my_bids"){
        setActiveSubScreen("مزايداتي")
      } else if(lastSegment === "my_purchases"){
        setActiveSubScreen("مشترياتي")
      } else if(lastSegment === "my_sold_products"){
        setActiveSubScreen("الطلبات")
      } else if(lastSegment === "my_products_offers"){
        setActiveSubScreen("عروض على منتجاتي")
      } else if(lastSegment === "my_offers"){
        setActiveSubScreen("عروض تم تقديمها")
      } else if(lastSegment === "my_comments"){
        setActiveSubScreen("التعليقات")
      } else if(lastSegment === "my_ratings"){
        setActiveSubScreen("التقييمات")
      } else{
        navigate("my_sales")
      }
    }, [location.pathname]);


    useEffect(()=>{

    }, [blobImg])

    const [signUpPhoneNumber, setSignUpPhoneNumber] = useState("");
    const [userData, setUserData] = useState("");

    useEffect(() => {
      const handleAuthStateChange = async (user) => {
        if (auth.currentUser && !auth.currentUser.isAnonymous) {
          try {
            setUserInfoLoading(true);
            setLoading(true);
    
            const docRef = doc(db, 'Users', user.uid);
    
            // Try to read from cache first
            const cachedDoc = await getDoc(docRef, { source: 'cache' }).catch(() => null);
    
            if (cachedDoc && cachedDoc.exists()) {
              const userData = cachedDoc.data();
              setUserData(userData);
            } else {
              // Read from server if cache is not available
              const docSnapshot = await getDoc(docRef);
              if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                setUserData(userData);
              } else {
                // Document doesn't exist, create it
                try {
                  const userDetails = {
                    phoneNumber: `+966${signUpPhoneNumber}`,
                    uid: auth.currentUser.uid,
                  };
    
                  const functions = getFunctions();
                  const createUserDoc = httpsCallable(functions, 'createUserDoc');
                  const response = await createUserDoc(userDetails);
    
                  console.log("createUserDoc function response:", response);
    
                  if (response.data.success) {
                    console.log("User document created with ID:", response.data.userId);
                  } else {
                    console.error("Error creating user document:", response.data.error);
                  }
                } catch (error) {
                  console.error("Error calling createUserDoc function:", error);
                }
              }
            }
    
            // Real-time updates
            const unsubscribeUser = onSnapshot(docRef, (docu) => {
              if (docu.exists()) {
                const userData = docu.data();
                setUserData(userData);
              } else {
                console.error('User data not found');
              }
            });
    
            // Ensure loader is set to false
            setLoading(false);
    
            return () => {
              if (unsubscribeUser) unsubscribeUser();
            };
          } catch (error) {
            console.error('Error fetching user data:', error);
          } finally {
            setUserInfoLoading(false);
            setFetching(false); // Ensure fetching state is updated
          }
        } else {
          setLoading(false);
          setUserInfoLoading(false);
          setFetching(false);
        }
      };
    
      const setUserData = (userData) => {
        setEmail(userData.email);
        setNewEmail(userData.email);
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setFullName(userData.fullName);
        setNewFullName(userData.fullName);
        setPhoneNumber(userData.phoneNumber);
        setOriginalImg(userData.profileImgUrl);
        setBalance(userData.balance);
        setMyCommission(userData.commission)
        setBeneficiaryName(userData.beneficiaryName);
        setNewBeneficiaryName(userData.beneficiaryName);
        setFeeBalance(userData.feeBalance);
        setCity(userData.city);
        setNewCity(userData.city);
        setUserId(userData.id);
        setIsVerified(userData.isVerified);
        setIsBusiness(userData.isBusiness);
        setIban(userData.iban);
        setNewIban(userData.iban);
        setBank(userData.bank);
        setNewBank(userData.bank);
        setNumRaters(userData.numRaters);
        setUserRating(userData.rating);
      };
    
      // Monitor auth state changes
      const unsubscribeAuth = onAuthStateChanged(auth, handleAuthStateChange);
    
      return () => {
        if (unsubscribeAuth) unsubscribeAuth();
      };
    }, [signUpPhoneNumber]); // Include any dependencies, e.g., `signUpPhoneNumber`
    

    // fetch my products
    useEffect(() => {
      const fetchMyProducts = async () => {
        setMyProductsIsLoading(true); // Start loading
    
        try {
          // Query to fetch products by userId and non-deleted
          const productsQuery = query(
            collection(db, 'Products'),
            where('sellerId', '==', userId),
            where('isDeleted', '==', false)
          );
    
          // Attempt to get cached data first
          const cachedSnapshot = await getDocs(productsQuery, { source: 'cache' });
    
          if (!cachedSnapshot.empty) {
            // Map cached data to state
            const cachedPosts = cachedSnapshot.docs
              .map(doc => doc.data())
              .sort((a, b) => b.createdAt - a.createdAt);
    
            setMyProducts(cachedPosts); // Set cached products
          }
    
          // Always set up a real-time listener to sync with Firestore
          const unsubscribe = onSnapshot(productsQuery, (querySnapshot) => {
            const posts = querySnapshot.docs
              .map(doc => doc.data())
              .sort((a, b) => b.createdAt - a.createdAt);
            
            setMyProducts(posts);
          }, (error) => {
            console.error('Error fetching my products:', error);
          });
    
          setMyProductsIsLoading(false); // End loading after snapshot is ready
    
          // Cleanup listener on unmount
          return () => unsubscribe();
          
        } catch (error) {
          console.error("Error during initial fetch:", error);
          setMyProductsIsLoading(false); // Ensure loading stops on error
        }
      };
    
      fetchMyProducts();
    }, [userId]);
    
    
    
    
    
    // fetch my bids
    useEffect(() => {
      let unsubscribe; // Declare unsubscribe in the outer scope
    
      const fetchMyBids = async () => {
        setMyBidsLoading(true); // Start loading
    
        try {
          // Query to fetch all products
          const bidsQuery = query(collection(db, 'Products'));
    
          // Attempt to get cached data first
          const cachedSnapshot = await getDocs(bidsQuery, { source: 'cache' }).catch(() => null);
          
          if (cachedSnapshot && !cachedSnapshot.empty) {
            const cachedBids = cachedSnapshot.docs
              .filter(doc => {
                const bidders = doc.data().bidders || {};
                const numericUserId = parseFloat(userId);
                return Object.keys(bidders).map(Number).includes(numericUserId);
              })
              .map(doc => {
                const data = doc.data();
                const images = data.imgs || []; // Extract images if available
                return {
                  ...data,
                  images,
                };
              })
              .sort((a, b) => (b.expiryDate || 0) - (a.expiryDate || 0)); // Sort by expiryDate in descending order
            
            setMyBids(cachedBids); // Set bids from cache
          }
    
          // Subscribe to real-time updates using onSnapshot
          unsubscribe = onSnapshot(bidsQuery, (querySnapshot) => {
            const liveBids = querySnapshot.docs
              .filter(doc => {
                const bidders = doc.data().bidders || {};
                const numericUserId = parseFloat(userId);
                return Object.keys(bidders).map(Number).includes(numericUserId);
              })
              .map(doc => {
                const data = doc.data();
                const images = data.imgs || []; // Extract images if available
                return {
                  ...data,
                  images,
                };
              })
              .sort((a, b) => (b.expiryDate || 0) - (a.expiryDate || 0)); // Sort by expiryDate in descending order
    
            setMyBids(liveBids); // Update state with live bids
            setMyBidsLoading(false); // End loading after receiving live updates
          }, (err) => {
            console.error('Error fetching my bids:', err);
            setMyBidsLoading(false); // End loading on error
          });
    
        } catch (error) {
          console.error('Error fetching my bids:', error);
          setMyBidsLoading(false); // End loading on catch
        }
      };
    
      fetchMyBids();
    
      return () => {
        if (unsubscribe) {
          unsubscribe(); // Clean up on unmount
        }
      };
    }, [userId]);
    
    
    
    
    // fetch my purchases
    useEffect(() => {
      let unsubscribe; // Declare unsubscribe in the outer scope
    
      const fetchMyPurchases = async () => {
        setMyPurchasesLoading(true); // Set loading to true at the start
    
        try {
          // Define the query for fetching orders
          const purchasesQuery = query(
            collection(db, 'Orders'),
            where('buyerId', '==', userId),
            where('type', '==', 'Purchase'),
            where('payment_status', '==', 'success'),
            orderBy('date', 'desc')
          );
    
          // Attempt to get cached data first
          const cachedSnapshot = await getDocs(purchasesQuery, { source: 'cache' }).catch(() => null);
          
          if (cachedSnapshot && !cachedSnapshot.empty) {
            const cachedPurchases = cachedSnapshot.docs.map(doc => {
              const data = doc.data();
              return {
                ...data,
                amount: data.amount || 0, // Ensure amount is included
              };
            }).sort((a, b) => (b.date || 0) - (a.date || 0)); // Sort by date in descending order
            
            setMyPurchases(cachedPurchases); // Set purchases from cache
          }
    
          // Subscribe to real-time updates using onSnapshot
          unsubscribe = onSnapshot(purchasesQuery, (querySnapshot) => {
            const livePurchases = querySnapshot.docs.map(doc => {
              const data = doc.data();
              return {
                ...data,
                amount: data.amount || 0, // Ensure amount is included
              };
            }).sort((a, b) => (b.date || 0) - (a.date || 0)); // Sort by date in descending order
    
            setMyPurchases(livePurchases); // Update purchases with live data
            setMyPurchasesLoading(false); // Stop loading after receiving live updates
          }, (err) => {
            console.error('Error fetching my purchases:', err);
            setMyPurchasesLoading(false); // Stop loading on error
          });
    
        } catch (error) {
          console.error('Error fetching purchases:', error);
          setMyPurchasesLoading(false); // Stop loading on catch
        }
      };
    
      fetchMyPurchases();
    
      return () => {
        if (unsubscribe) {
          unsubscribe(); // Clean up on unmount
        }
      };
    }, [userId]);
    

    // fetch my offers
    useEffect(() => {
      let unsubscribe; // Declare unsubscribe in the outer scope
    
      const fetchMyOffers = async () => {
        setOffersLoading(true); // Start loading state
    
        try {
          // Define the query for fetching offers
          const offersQuery = query(
            collection(db, 'Offers'),
            where('userId', '==', myId),
            where('isRejected', '==', false),
            where('isAccepted', '==', false),
            where('isCanceled', '==', false),
            orderBy('productId')
          );
    
          // Fetch from cache first
          const cachedSnapshot = await getDocs(offersQuery, { source: 'cache' }).catch(() => null);
    
          if (cachedSnapshot && !cachedSnapshot.empty) {
            const cachedOffersData = await Promise.all(
              cachedSnapshot.docs.map(async doc => {
                const offer = { id: doc.id, ...doc.data() };
                const userSnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', offer.userId)));
                const user = userSnapshot.docs[0]?.data() || null;
    
                const productSnapshot = await getDocs(query(collection(db, 'Products'), where('id', '==', offer.productId)));
                const product = productSnapshot.docs[0]?.data() || null;
    
                return { ...offer, user, product };
              })
            );
            setMyOffers(cachedOffersData); // Set cached data
          }
    
          // Subscribe to real-time updates using onSnapshot
          unsubscribe = onSnapshot(offersQuery, async (querySnapshot) => {
            if (!querySnapshot.empty) {
              const offersData = await Promise.all(
                querySnapshot.docs.map(async doc => {
                  const offer = { id: doc.id, ...doc.data() };
                  const userSnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', offer.userId)));
                  const user = userSnapshot.docs[0]?.data() || null;
    
                  const productSnapshot = await getDocs(query(collection(db, 'Products'), where('id', '==', offer.productId)));
                  const product = productSnapshot.docs[0]?.data() || null;
    
                  return { ...offer, user, product };
                })
              );
              setMyOffers(offersData); // Update offers with live data
            } else {
              setMyOffers([]); // Set to empty if no live data
            }
            setOffersLoading(false); // Stop loading after real-time update
          }, (err) => {
            console.error('Error fetching offers:', err);
            setOffersLoading(false); // Stop loading on error
          });
    
        } catch (error) {
          console.error('Error fetching offers:', error);
          setOffersLoading(false); // Stop loading on catch
        }
      };
    
      fetchMyOffers();
    
      return () => {
        if (unsubscribe) {
          unsubscribe(); // Clean up on unmount
        }
      };
    }, [userId]);
    


    // fetch offers
    useEffect(() => {
      let unsubscribe; // Declare unsubscribe in the outer scope
    
      const fetchOffers = async () => {
        setOffersLoading(true); // Start loading state
    
        try {
          // Define the query for fetching offers
          const offersQuery = query(
            collection(db, 'Offers'),
            where('sellerId', '==', myId),
            where('isRejected', '==', false),
            where('isAccepted', '==', false),
            where('isCanceled', '==', false),
            orderBy('productId')
          );
    
          // Fetch from cache first
          const cachedSnapshot = await getDocs(offersQuery, { source: 'cache' }).catch(() => null);
    
          if (cachedSnapshot && !cachedSnapshot.empty) {
            const cachedOffersData = await Promise.all(
              cachedSnapshot.docs.map(async doc => {
                const offer = { id: doc.id, ...doc.data() };
                const userSnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', offer.userId)));
                const user = userSnapshot.docs[0]?.data() || null;
    
                const productSnapshot = await getDocs(query(collection(db, 'Products'), where('id', '==', offer.productId)));
                const product = productSnapshot.docs[0]?.data() || null;
    
                return { ...offer, user, product };
              })
            );
            setOffers(cachedOffersData); // Set cached data
          }
    
          // Subscribe to real-time updates using onSnapshot
          unsubscribe = onSnapshot(offersQuery, async (querySnapshot) => {
            if (!querySnapshot.empty) {
              const offersData = await Promise.all(
                querySnapshot.docs.map(async doc => {
                  const offer = { id: doc.id, ...doc.data() };
                  const userSnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', offer.userId)));
                  const user = userSnapshot.docs[0]?.data() || null;
    
                  const productSnapshot = await getDocs(query(collection(db, 'Products'), where('id', '==', offer.productId)));
                  const product = productSnapshot.docs[0]?.data() || null;
    
                  return { ...offer, user, product };
                })
              );
              setOffers(offersData); // Update offers with live data
            } else {
              setOffers([]); // Set to empty if no live data
            }
            setOffersLoading(false); // Stop loading after real-time update
          }, (err) => {
            console.error('Error fetching offers:', err);
            setOffersLoading(false); // Stop loading on error
          });
    
        } catch (error) {
          console.error('Error fetching offers:', error);
          setOffersLoading(false); // Stop loading on catch
        }
      };
    
      fetchOffers();
    
      return () => {
        if (unsubscribe) {
          unsubscribe(); // Clean up on unmount
        }
      };
    }, [userId]);
    


    const[reviewLoading, setReviewLoading] = useState(true)
    const[reviews, setReviews] = useState([])

    // fetch ratings
    useEffect(() => {
      let unsubscribe; // Declare unsubscribe in the outer scope
    
      const fetchRatings = async () => {
        setReviewLoading(true); // Start loading state
    
        try {
          // Define the query for fetching ratings
          const reviewsQuery = query(
            collection(db, 'Ratings'),
            where('sellerId', '==', myId),
            orderBy('timestamp', 'desc')
          );
    
          // Fetch from cache first
          const cachedSnapshot = await getDocs(reviewsQuery, { source: 'cache' }).catch(() => null);
    
          if (cachedSnapshot && !cachedSnapshot.empty) {
            const cachedReviewsData = await Promise.all(
              cachedSnapshot.docs.map(async (doc) => {
                const review = { id: doc.id, ...doc.data() };
                const userSnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', review.reviewerId)));
                const user = userSnapshot.docs[0]?.data() || null;
    
                return { ...review, reviewer: user };
              })
            );
            setReviews(cachedReviewsData); // Set cached data
          }
    
          // Subscribe to real-time updates using onSnapshot
          unsubscribe = onSnapshot(reviewsQuery, async (querySnapshot) => {
            if (!querySnapshot.empty) {
              const reviewsData = await Promise.all(
                querySnapshot.docs.map(async (doc) => {
                  const review = { id: doc.id, ...doc.data() };
                  const userSnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', review.reviewerId)));
                  const user = userSnapshot.docs[0]?.data() || null;
    
                  return { ...review, reviewer: user };
                })
              );
              setReviews(reviewsData); // Update reviews with live data
            } else {
              setReviews([]); // Set to empty if no live data
            }
            setReviewLoading(false); // Stop loading after real-time update
          }, (err) => {
            console.error('Error fetching reviews:', err);
            setReviewLoading(false); // Stop loading on error
          });
    
        } catch (error) {
          console.error('Error fetching ratings:', error);
          setReviewLoading(false); // Stop loading on catch
        }
      };
    
      fetchRatings();
    
      return () => {
        if (unsubscribe) {
          unsubscribe(); // Clean up on unmount
        }
      };
    }, [userId]); // Add myId as a dependency to refetch when it changes
    


    // fetch comments
    useEffect(() => {
      let unsubscribe; // Declare unsubscribe in the outer scope
    
      const fetchComments = async () => {
        setCommentsLoading(true); // Start loading state
    
        try {
          // Define the query for fetching comments
          const commentsQuery = query(
            collection(db, 'Comments'),
            where('commentType', '==', "User"),
            where('targetId', '==', userId),
            orderBy('timestamp', "desc") // Order by timestamp
          );
    
          // Fetch from cache first
          const cachedSnapshot = await getDocs(commentsQuery, { source: 'cache' }).catch(() => null);
    
          if (cachedSnapshot && !cachedSnapshot.empty) {
            const cachedCommentsData = await Promise.all(
              cachedSnapshot.docs.map(async (doc) => {
                const comment = { id: doc.id, ...doc.data() };
                const userSnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', comment.user.userId)));
                const user = userSnapshot.docs[0]?.data() || null;
    
                return { ...comment, user };
              })
            );
            setComments(cachedCommentsData); // Set cached data
          }
    
          // Subscribe to real-time updates using onSnapshot
          unsubscribe = onSnapshot(commentsQuery, async (querySnapshot) => {
            if (!querySnapshot.empty) {
              const commentsData = await Promise.all(
                querySnapshot.docs.map(async (doc) => {
                  const comment = { id: doc.id, ...doc.data() };
                  const userSnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', comment.user.userId)));
                  const user = userSnapshot.docs[0]?.data() || null;
    
                  return { ...comment, user };
                })
              );
              setComments(commentsData); // Update comments with live data
            } else {
              setComments([]); // Set to empty if no live data
            }
            setCommentsLoading(false); // Stop loading after real-time update
          }, (err) => {
            console.error('Error fetching comments:', err);
            setCommentsLoading(false); // Stop loading on error
          });
    
        } catch (error) {
          console.error('Error fetching comments:', error);
          setCommentsLoading(false); // Stop loading on catch
        }
      };
    
      fetchComments();
    
      return () => {
        if (unsubscribe) {
          unsubscribe(); // Clean up on unmount
        }
      };
    }, [lastSegment, userId]); // Add lastSegment and userId as dependencies
    



const [receiveOrderUpdatesEmail, setReceiveOrderUpdatesEmail] = useState(true)
const [receiveDMEmail, setReceiveDMEmail] = useState(true)
const [receiveCommentsEmail, setReceiveCommentsEmail] = useState(true)
const [receiveBuyerCommentsEmail, setReceiveBuyerCommentsEmail] = useState(true)
const [receiveNewBidderEmail, setReceiveNewBidderEmail] = useState(true)
const [receiveNewBidEmail, setReceiveNewBidEmail] = useState(true)
const [receiveBuyerNewBidEmail, setReceiveBuyerNewBidEmail] = useState(true)
const [receiveOffersEmail, setReceiveOffersEmail] = useState(true)



  const handleReceiveDMEmail = (newCheckedStatus) => {
    setReceiveDMEmail(newCheckedStatus);
  };
  const handleReceiveCommentsEmail = (newCheckedStatus) => {
    setReceiveCommentsEmail(newCheckedStatus);
  };
  const handleReceiveBuyerNewBidEmail = (newCheckedStatus) => {
    setReceiveBuyerNewBidEmail(newCheckedStatus);
  };
  const handleReceiveBuyerCommentsEmail = (newCheckedStatus) => {
    setReceiveBuyerCommentsEmail(newCheckedStatus);
  };
  const handleReceiveNewBidEmail = (newCheckedStatus) => {
    setReceiveNewBidEmail(newCheckedStatus);
  };
  const handleReceiveNewBidderEmail = (newCheckedStatus) => {
    setReceiveNewBidderEmail(newCheckedStatus);
  };
  const handleReceiveOffersEmail = (newCheckedStatus) => {
    setReceiveOffersEmail(newCheckedStatus);
  };
  const handleReceiveShipmentUpdate = (newCheckedStatus) => {
    setReceiveOrderUpdatesEmail(newCheckedStatus);
  };


    const [hasSentRequest, setHasSentRequest] = useState(false)
    const [requestStatus, setRequestStatus] = useState("")
    const [requestType, setRequestType] = useState("")

    useEffect(() => {
      const fetchRequest = async () => {
        const requestQuery = query(
          collection(db, 'Requests'),
          where('userId', '==', myId),
          orderBy('timestamp', "desc"),
          limit(1) // Limit the query to fetch only one request
        );
  
        const unsubscribe = onSnapshot(requestQuery, (querySnapshot) => {
          if (!querySnapshot.empty) {
            const requestData = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
            setRequestStatus(requestData.status)
            setRequestType(requestData.type)
            setHasSentRequest(true);
          } else {
            setHasSentRequest(false);
          }
          // setRequestLoading(false);
        });
  
        return () => unsubscribe();
      };
  
      fetchRequest();
    }, [myId]);



    const [orders, setOrders] = useState([]); // All orders fetched from Firestore
    const [totalSales, setTotalSales] = useState(0); // Total sales for the current month
    const [totalOrders, setTotalOrders] = useState(0); // Total number of orders for the current month
    const [salesChartData, setSalesChartData] = useState([]); // Data for the chart
  
    // Utility function to calculate the number of days in the current month
    const getDaysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };


    useEffect(() => {
      const fetchOrders = () => {
        setMySoldProductsLoading(true);
    
        const ordersQuery = query(
          collection(db, 'Orders'),
          where('sellerId', '==', myId),
          where('payment_status', '==', "success"),
          where('type', '==', "Purchase"),
          orderBy('date', "desc")
        );
    
        const unsubscribe = onSnapshot(ordersQuery, (querySnapshot) => {
          if (!querySnapshot.empty) {
            const ordersData = querySnapshot.docs.map(doc => {
              const orderData = { id: doc.id, ...doc.data() };
    
              return orderData;
            });
    
            setOrders(ordersData);
    
            // Process orders for the current month's daily sales chart
            const result = filterOrdersForCurrentMonth(ordersData);
            const { salesByDay, ordersByDay, totalSales: monthlyTotalSales, totalOrders: monthlyTotalOrders } = result;
    
            // Get number of days in the current month
            const currentDate = new Date();
            const daysInMonth = getDaysInMonth(currentDate.getMonth() + 1, currentDate.getFullYear());
    
            // Prepare chart data for both sales and orders
            const processedChartData = Array.from({ length: daysInMonth }, (_, i) => ({
              day: i + 1, // Day of the month (1-based)
              sales: salesByDay[i + 1] || 0, // Sales for the day
              orders: ordersByDay[i + 1] || 0 // Orders for the day
            }));
    
            // Update state with chart data and totals
            setSalesChartData(processedChartData);
            setTotalSales(monthlyTotalSales);
            setTotalOrders(monthlyTotalOrders);
          } else {
            // Reset if no orders are found
            setOrders([]);
            setSalesChartData([]);
            setTotalSales(0);
            setTotalOrders(0);
          }
    
          setMySoldProductsLoading(false);
        });
    
        return () => unsubscribe();
      };
    
      if (myId) {
        fetchOrders();
      }
    }, [myId, db]);
    
    

    const [isVisible, setIsVisible] = useState(false); // Visibility state

    useEffect(() => {
      // Set the div to visible after a slight delay to ensure the transition happens
      if(!isLoading){
        setTimeout(() => setIsVisible(true), 100);
      }
    }, [isLoading]);


    const filterOrdersForCurrentMonth = (orders) => {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
    
      let totalSales = 0;
      let totalOrders = 0;
      const salesByDay = {};
      const ordersByDay = {}; // Track orders by day
    
      orders.forEach(order => {
        const orderDate = order.date.toDate();
        const orderMonth = orderDate.getMonth();
        const orderYear = orderDate.getFullYear();
    
        // Only process orders for the current month and year
        if (orderMonth === currentMonth && orderYear === currentYear) {
          const day = orderDate.getDate();
          let orderAmount = order.amount || 0;
    
          // Check if the delivery option is "شحن" (shipping)
          if (order.deliveryOption === "شحن") {
            orderAmount -= 23;  // Deduct 23 from the order amount if shipping
          }
    
          // Track sales by day
          salesByDay[day] = (salesByDay[day] || 0) + orderAmount;
    
          // Track orders by day
          ordersByDay[day] = (ordersByDay[day] || 0) + 1;
    
          // Add the adjusted order amount to the total sales
          totalSales += orderAmount;
          totalOrders += 1;
        }
      });
    
      return {
        salesByDay,
        ordersByDay,  // Return orders by day
        totalSales,
        totalOrders
      };
    };
    
    const renderChart = () => {
      const labels = salesChartData.map(data => data.day);
      const salesData = salesChartData.map(data => data.sales);
      const ordersData = salesChartData.map(data => data.orders || 0);
    
      const chartData = {
        labels,
        datasets: [
          {
            label: 'المبيعات (ر.س)',
            data: salesData,
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            yAxisID: 'y',
            barThickness: 12, // Increase bar width to 12 pixels for thicker bars
          },
          {
            label: 'عدد الطلبات',
            data: ordersData,
            backgroundColor: 'rgba(192, 75, 192, 0.5)',
            borderColor: 'rgba(192, 75, 192, 1)',
            borderWidth: 1,
            yAxisID: 'y1',
            barThickness: 12, // Increase bar width to 12 pixels for thicker bars
          },
        ],
      };
    
      const options = {
        responsive: true,
        maintainAspectRatio: false, // Allow more flexibility in aspect ratio
        scales: {
          x: {
            title: { display: false },
            type: 'category',
            ticks: {
              autoSkip: true,
              maxTicksLimit: window.innerWidth < 600 ? 4 : 6,
              callback: (value, index) => labels[index],
            },
            grid: {
              drawOnChartArea: false,
              borderWidth: 0,
            },
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: { display: false },
            beginAtZero: true,
            ticks: {
              display: true, // Show y-axis labels
            },
            grid: {
              drawOnChartArea: true,
              borderWidth: 1,
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            title: { display: false },
            beginAtZero: true,
            ticks: {
              display: true, // Show y1-axis labels
              precision: 0, // Ensure no decimals
            },
            grid: {
              drawOnChartArea: false,
              borderWidth: 0,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                const day = context[0].label;
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1;
                const currentYear = currentDate.getFullYear();
                return `${day}/${currentMonth}/${currentYear}`;
              },
              label: (context) => {
                const value = context.raw;
                const label = context.dataset.label || '';
                return `${label}: ${value}`;
              },
            },
          },
          legend: { display: true, position: 'top' },
          title: {
            display: false,
            // text: `المبيعات: ${formatAccountingArabic(totalSales)}, عدد الطلبات: ${totalOrders} (للشهر الحالي)`,
            font: {
              size: window.innerWidth < 600 ? 12 : 14,
              weight: 'bold',
            },
            padding: { top: 5, bottom: 5 },
            maxWidth: window.innerWidth < 600 ? 250 : 400,
            fullSize: true,
          },
        },
      };
    
      return (
        <div className='chart-container' style={{ 
          width: '100%', 
          height: '30vh',  
          minWidth:"100%",
          padding: 0, 
          margin: 0, 
          overflowX: 'auto',  // Enable scrollbar when overflow
          whiteSpace: 'nowrap',
          WebkitOverflowScrolling: 'touch', // Ensures smooth scrolling on iOS devices
          overflowY: 'hidden',  // Prevent vertical scrolling
          msOverflowStyle: 'scrollbar', // Force scrollbars to show on IE/Edge
        }}>
          <div style={{ 
            width: `${labels.length * 30}px`, 
            height: '100%',
            minWidth:"100%",
          }}>
            <Bar data={chartData} options={options} style={{ padding: 0, margin: 0, width: '100%', height: '100%' }} />
          </div>
        </div>
      );
      
      
    };
    
    
    
    
    
    
    
    
    
    
    
    
    

    

    
    





      function formatAccountingArabic(number) {
        // Check if the input is a valid number
        if (typeof number !== 'number' || isNaN(number)) {
          return 'Invalid input';
        }
      
        // Use toLocaleString to format the number with commas and two decimal places
        const formattedNumber = number.toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      
        // Add the Arabic currency symbol manually
        const arabicFormattedNumber = `${formattedNumber} ر.س`;
      
        return arabicFormattedNumber;
    }
      
    if(isLoading){
      return <LoadingScreen/>
    }

    if((auth.currentUser && auth.currentUser.isAnonymous) && !isLoading){
      return <SignInOrSignUpScreen prompt={"الرجاء التسجيل او تسجيل الدخول لرؤية هذه الصفحة"} setSignUpPhoneNumber={setSignUpPhoneNumber} redirectUrl={'/my_account/my_sales'} />
     }



     const MySales = () => {
      if(mySoldProductsLoading){
        return (
          <div style={{
            width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex",
            borderRadius: "24px", border: "none", backgroundColor: "transparent", minHeight: "100vh"
          }}>
            <Oval
              height={"30px"} width={"30px"} color={"#00C8B8"} wrapperClass="" visible={true}
              wrapperStyle={{ display: "block", margin: "auto" }} ariaLabel='oval-loading'
              secondaryColor="#ccc" strokeWidth={1} strokeWidthSecondary={1}
            />
          </div>
        );
      }
    
      // Adjust the order prices if the deliveryOption is "شحن"
      const adjustedOrders = orders.map(order => {
        // Check if the deliveryOption is "شحن" and deduct 23
        if (order.deliveryOption === "شحن" && order.totalAmount) {
          console.log("order is shipping")
          order.totalAmount = parseFloat(order.totalAmount) - 23;
        }
        return order;
      });
    
      // Process the adjusted orders for the current month's daily sales chart
      const { monthlyOrdersCount, totalSales } = filterOrdersForCurrentMonth(adjustedOrders);
    
      // Commission and total sales after commission deduction
      const commission = myCommission / 100 * totalSales;
      const totalAfterFees = totalSales - commission;
    
      return (
        <div style={{
          width: "100%", height: "100%", padding: window.innerWidth > 600 ? "16px" : "8px",
          display: "flex", flexDirection: window.innerWidth > window.innerHeight ? "column" : "column"
        }}>
          <div style={{ width: window.innerWidth > window.innerHeight ? "100%" : "100%", height: "fit-content" }}>
            {/* Your chart rendering logic here */}
            {renderChart()}
          </div>
    
          <div style={{
            width: window.innerWidth > window.innerHeight ? "100%" : "100%", height: "fit-content", marginTop: "16px"
          }}>
            <div style={{
              display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: window.innerWidth > 600 ? "16px" : "8px",
              marginTop: window.innerWidth > window.innerHeight ? "0px" : "16px"
            }}>
              <div style={{ backgroundColor: "#F7F7F7", borderRadius: "8px", padding: "10px" }}>
                <p style={{ fontWeight: 500 }}>المبيعات الشهرية</p>
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <TbReportMoney size={28} color='#00C8B8' />
                  <p style={{ fontWeight: 500, marginBottom: "0px", marginRight: "6px", fontSize: "14px" }}>
                    {formatAccountingArabic(totalSales)}
                  </p>
                </div>
              </div>
    
              <div style={{ backgroundColor: "#F7F7F7", borderRadius: "8px", padding: "10px" }}>
                <p style={{ fontWeight: 500 }}>عدد الطلبات</p>
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <FiPackage size={28} color='#00C8B8' />
                  <p style={{ fontWeight: 500, marginBottom: "0px", marginRight: "6px", fontSize: "14px" }}>
                    {totalOrders}
                  </p>
                </div>
              </div>
    
              <div style={{ backgroundColor: "#F7F7F7", borderRadius: "8px", padding: "10px" }}>
                <p style={{ fontWeight: 500 }}>المبيعات بعد خصم العمولة</p>
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <TbReportMoney size={28} color='#00C8B8' />
                  <p style={{ fontWeight: 500, marginBottom: "0px", marginRight: "6px", fontSize: "14px" }}>
                    {formatAccountingArabic(totalAfterFees || 0)}
                  </p>
                </div>
              </div>
    
            </div>
          </div>
        </div>
      );
    };
    

     const MyInfo = () => {
      if (userInfoLoading) {
        return (
          <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
            <Oval
              height={"30px"}
              width={"30px"}
              color={"#00C8B8"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{ display: "block", margin: "auto" }}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
          </div>
        );
      }
      return (
        <div style={{ height: "100%", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", width: window.innerWidth > window.innerHeight ? "70%" : "90%", marginLeft: "auto", marginRight: "auto", padding: "16px" }}>
          <div style={{ display: "flex", flexDirection: "column", position: "relative", marginBottom: "32px", alignItems: "center" }}>
            <img
              onClick={() => newProfilePictureRef.current.click()}
              style={{
                width: "140px",
                opacity: 1,
                height: "140px",
                borderRadius: "1000px",
                objectFit: "cover",
                cursor: "pointer",
                border: ".5px solid #ddd"
              }}
              src={blobImg === "" ? originalImg : blobImg}
              className='skeleton'
              loading='eager'
            />
            <input
              ref={newProfilePictureRef}
              type='file'
              style={{ display: "none", visibility: "hidden" }}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  const blobUrl = URL.createObjectURL(file);
                  setBlobImg(blobUrl);
                }
                setProfilePictureUpdated(true);
              }}
            />
            {
              blobImg != "" &&
              <button style={{ backgroundColor: "#F00057", border: "none", borderRadius: "4px", width: "20px", height: "20px", marginTop: "4px" }} onClick={() => {
                setBlobImg("")
                newProfilePictureRef.current.value = "";
                setProfilePictureUpdated(false);
              }}>
                <FaTrashCan size={11} style={{ marginBottom: "17px", color: "white", marginTop: "4px" }} />
              </button>
            }
            <div style={{ display: "flex", direction: "rtl", marginTop: "8px" }}>
              <IoWalletOutline size={20} color='#F00057' />
              <h6 style={{ marginRight: "4px" }}>{formatAccountingArabic(balance  || 0)}</h6>
            </div>
            <div style={{ display: "flex", marginTop: "0px" }}>
              <AiFillStar size={20} style={{ marginTop: "3px" }} color='gold' />
              <p style={{ marginBottom: "0px" }}>{parseFloat(userRating.toFixed(1))}</p>
              <p style={{ marginBottom: "0px", marginRight: "4px" }}>({numRaters})</p>
            </div>
          </div>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <input
            disabled={isVerified}
              onChange={(e) => {
                setNewFullName(e.target.value);
              }}
              ref={fullNameRef}
              id='input-field'
              placeholder='الإسم'
              style={{ color: "#00C8B8", fontWeight: "500" }}
              type='text' defaultValue={fullName}
            />
            {isVerified && !isBusiness && <RiVerifiedBadgeFill size={25} style={{ position: "relative", marginRight: "8px" }} color='#00C8B8' />}
            {isBusiness && isVerified && <FaStore size={25} style={{ position: "relative", marginRight: "8px" }} color='#F00057' />}
          </div>
          <input
            id='input-field'
            style={{ color: "#646464", marginTop: "32px", direction: "ltr" }}
            readOnly
            type='text' value={phoneNumber}
          />
          <input
            placeholder='البريد الالكتروني'
            autoComplete='email'
            onChange={(e) => {
              setNewEmail(e.target.value);
            }}
            ref={emailRef}
            style={{ marginTop: "32px", color: "#00C8B8" }}
            id='input-field'
            type='email' defaultValue={newEmail !== "" ? newEmail : email}
          />
          {
            email === "" && newEmail === "" &&
            <div style={{ display: "flex", textAlign: "right", width: "100%" }}>
              <p style={{ marginBottom: "0px", color: "#646464",marginTop:"4px" }}>قم بإضافة بريدك الإلكتروني لتصلك إشعارات مثل اذا قام شخص بإضافة تعليق على منتجك</p>
            </div>
          }
          {
            email !== "" && (
              <div style={{width:"100%", display: window.innerWidth > window.innerHeight ? "flex" : "block",marginTop:"8px", justifyContent:"space-between", color:"#00A49B"}}>
              
                <div style={{flex:3}}>
                  <h6 style={{color:"#00A49B"}}>إشعارات عامة</h6>
                  <CustomCheckbox
                      checked={receiveDMEmail}
                      onChange={(newCheckedStatus) =>
                        handleReceiveDMEmail(
                          newCheckedStatus
                        )
                      }
                      label="الرسائل الخاصة"
                      labelStyle={{
                      fontSize: "15px",
                      marginBottom: "6px",
                      marginRight: "8px",
                    }}
                  />
                </div>

                <div style={{marginTop: window.innerWidth > window.innerHeight ? "0px" : "12px", flex:3}}>
                  <h6 >إشعارات البائع</h6>
                  <CustomCheckbox
                      checked={receiveCommentsEmail}
                      onChange={(newCheckedStatus) =>
                        handleReceiveCommentsEmail(
                          newCheckedStatus
                        )
                      }
                      label="التعليقات و الاسئلة"
                      labelStyle={{
                      fontSize: "15px",
                      marginBottom: "6px",
                      marginRight: "8px",
                    }}
                  />
                  <CustomCheckbox
                      checked={receiveNewBidderEmail}
                      onChange={(newCheckedStatus) =>
                        handleReceiveNewBidderEmail(
                          newCheckedStatus
                        )
                      }
                      label="دخول مزايد جديد للمزاد"
                      labelStyle={{
                      fontSize: "15px",
                      marginBottom: "6px",
                      marginRight: "8px",
                    }}
                  />
                  <CustomCheckbox
                      checked={receiveNewBidEmail}
                      onChange={(newCheckedStatus) =>
                        handleReceiveNewBidEmail(
                          newCheckedStatus
                        )
                      }
                      label="مزايدة جديدة على منتج"
                      labelStyle={{
                      fontSize: "15px",
                      marginBottom: "6px",
                      marginRight: "8px",
                    }}
                  />
                  <CustomCheckbox
                      checked={receiveOffersEmail}
                      onChange={(newCheckedStatus) =>
                        handleReceiveOffersEmail(
                          newCheckedStatus
                        )
                      }
                      label="تقديم عرض على منتج"
                      labelStyle={{
                      fontSize: "15px",
                      marginBottom: "6px",
                      marginRight: "8px",
                    }}
                  />
                </div>

                <div style={{marginTop: window.innerWidth > window.innerHeight ? "0px" : "12px", flex:3}}>
                  <h6 >إشعارات المشتري</h6>
                  <CustomCheckbox
                      checked={receiveBuyerCommentsEmail}
                      onChange={(newCheckedStatus) =>
                        handleReceiveBuyerCommentsEmail(
                          newCheckedStatus
                        )
                      }
                      label="تعليقات و رد البائع على منتج قمت بالتعليق عليه"
                      labelStyle={{
                      fontSize: "15px",
                      marginBottom: "6px",
                      marginRight: "8px",
                    }}
                  />
                      <CustomCheckbox
                      checked={receiveBuyerNewBidEmail}
                      onChange={(newCheckedStatus) =>
                        handleReceiveBuyerNewBidEmail(
                          newCheckedStatus
                        )
                      }
                      label="مزايدات على منتج قمت بالمزايدة عليه"
                      labelStyle={{
                      fontSize: "15px",
                      marginBottom: "6px",
                      marginRight: "8px",
                    }}
                  />
                  <CustomCheckbox
                      checked={receiveOrderUpdatesEmail}
                      onChange={(newCheckedStatus) =>
                        handleReceiveShipmentUpdate(
                          newCheckedStatus
                        )
                      }
                      label="تحديثات حالة الطلب"
                      labelStyle={{
                      fontSize: "15px",
                      marginBottom: "6px",
                      marginRight: "8px",
                    }}
                  />
                </div>



              </div>
            )
          }
          <div style={{
            display: "flex",
            width: "100%", justifyContent: "center",
            alignItems: "center",
            marginTop: "32px",
          }}>
            <select 
  ref={cityRef} 
  defaultValue={city || ""}
  id={"input-field"}
  style={{ marginTop: "8px" }} 
  onChange={(e)=>{
    setNewCity(e.target.value);
  }}
>
  <option value="">المدينة</option>
  {cities.map((city, index) => (
    <option 
      style={{ backgroundColor: "white", color:"black" }} 
      key={index} 
      value={city.arabic} // Set the English city as the value
    >
      {city.arabic}
    </option>
  ))}
</select>
            {
              newCity !== "" &&
              <IoCloseSharp style={{ marginRight: "8px", color: "red", marginTop: "auto", marginBottom: "auto" }} size={19} onClick={() => { cityRef.current.value = ""; setNewCity("") }} />
            }
          </div>
          {
            city === "" &&
            <div style={{ display: "flex", textAlign: "right", width: "100%" }}>
              <p style={{ marginBottom: "0px", color: "#646464",marginTop:"4px" }}>قم بإضافة مدينتك لاظهارها في حسابك كبائع</p>
            </div>
          }
          <input
            disabled={isVerified}
            placeholder='الآيبان'
            onChange={(e) => {
              setNewIban(e.target.value);
            }}
            ref={ibanRef}
            id='input-field'
            defaultValue={iban}
            style={{ marginTop: "32px", color: "#646464" }}
          />
          {
            newIban === "" &&
            <div style={{ display: "flex", textAlign: "right", width: "100%" }}>
              <span style={{ color: "red" }}>*</span>
              <p style={{ marginBottom: "0px", color: "#646464",marginTop:"4px" }}>مطلوب لتحويل مبالغ مبيعاتك</p>
            </div>
          }
          <input
            disabled={isVerified}
            placeholder='اسم المستفيد الكامل'
            onChange={(e) => {
              setNewBeneficiaryName(e.target.value);
            }}
            ref={beneficiaryRef}
            id='input-field'
            defaultValue={beneficiaryName}
            style={{ marginTop: "32px", color: "#646464" }}
          />
          {
            newBeneficiaryName === "" &&
            <div style={{ display: "flex", textAlign: "right", width: "100%" }}>
              <span style={{ color: "red" }}>*</span>
              <p style={{ marginBottom: "0px", color: "#646464" }}>مطلوب للتأكد من الحساب البنكي</p>
            </div>
          }
          <div style={{
            display: "flex",
            width: "100%", justifyContent: "center",
            alignItems: "center",
            marginTop: "32px",
          }}>
            <select
              ref={bankRef}
              disabled={isVerified}
              id='input-field'
              style={{ color: "#646464" }}
              defaultValue={bank}
              onChange={(value) => {
                setNewBank(value.target.value);
              }}
            >
              <option disabled value="">البنك</option>
              <option value="الأهلي">الأهلي</option>
              <option value="بنك ساب">بنك ساب</option>
              <option value="مصرف الإنماء">مصرف الإنماء</option>
              <option value="البنك السعودي الفرنسي">البنك السعودي الفرنسي</option>
              <option value="بنك الرياض">بنك الرياض</option>
              <option value="مصرف الراجحي">مصرف الراجحي</option>
              <option value="البنك العربي الوطني">البنك العربي الوطني</option>
              <option value="بنك البلاد">بنك البلاد</option>
              <option value="بنك الجزيرة">بنك الجزيرة</option>
              <option value="البنك السعودي للاستثمار">البنك السعودي للاستثمار</option>
            </select>
            {
              newBank !== "" && !isVerified &&
              <IoCloseSharp style={{ marginRight: "8px", color: "red", marginTop: "auto", marginBottom: "auto" }} size={19} onClick={() => { bankRef.current.value = ""; setNewBank("") }} />
            }
          </div>
          {
            bank && newBank === "" &&
            <div style={{ display: "flex", textAlign: "right", width: "100%" }}>
              <span style={{ color: "red" }}>*</span>
              <p style={{ marginBottom: "0px", color: "#646464" }}>يرجى اختيار البنك</p>
            </div>
          }
          <button disabled={updatingDataLoading} style={{ width: "100%", marginTop: "32px" }} id='my-button' onClick={async () => {
            if (
              !profilePictureUpdated &&
              fullName.toString().trim() === newFullName.toString().trim() &&
              email.toString().trim() === newEmail.toString().trim() &&
              city.toString().trim() === newCity.toString().trim() &&
              iban.toString().trim() === newIban.toString().trim() &&
              beneficiaryName.toString().trim() === newBeneficiaryName.toString().trim() &&
              bank.toString().trim() === newBank.toString().trim() &&
              receiveDMEmail &&
              receiveCommentsEmail &&
              receiveBuyerCommentsEmail &&
              receiveNewBidderEmail &&
              receiveNewBidEmail &&
              receiveOffersEmail &&
              receiveOrderUpdatesEmail
            ) return;
    
            handleUpdateUserInfo()

          }}>
            {
              updatingDataLoading ? <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", paddingTop: "4px" }}>
                <Oval
                  height={"20px"}
                  width={"20px"}
                  color={"white"}
                  wrapperClass=""
                  visible={true}
                  wrapperStyle={{ display: "block" }}
                  ariaLabel='oval-loading'
                  secondaryColor="#ccc"
                  strokeWidth={1}
                  strokeWidthSecondary={1}
                />
              </div> : <p style={{ marginBottom: "0px" }}>حفظ</p>
            }
          </button>

          {
  !isBusiness && !isVerified && (
    requestStatus === "مرفوض" || !hasSentRequest ? (
      <>
        <button
          style={{ width: "100%", marginTop: "16px", backgroundColor: "#F00057" }}
          id="my-button"
          onClick={() => setVerificationRequestIsOpen(true)}
        >
          <p style={{ marginBottom: "0px" }}>طلب توثيق الحساب</p>
        </button>

        <button
          style={{ width: "100%", marginTop: "8px", backgroundColor: "#F00057" }}
          id="my-button"
          onClick={() => setSwitchToStoreRequestIsOpen(true)}
        >
          <p style={{ marginBottom: "0px" }}>ترقية لحساب تاجر</p>
        </button>
      </>
    ) : (
      <div style={{ width: "100%", marginTop: "32px" }}>
        <p style={{ marginBottom: "0px", color: "#00A49B" }}>
          حالة طلب {requestType === "User Verification" ? "التوثيق" : "التحويل لحساب تاجر"}: 
          <span style={{ fontWeight: 500, color: "#00A49B" }}> {requestStatus}</span>
        </p>
      </div>
    )
  )
}



        </div>
      );
    };
    


    const MyBids = () => {
      if(myBidsLoading){
        return <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex", borderRadius:"24px", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
        <Oval
                    height={"30px"}
                    width={"30px"}
                    color={"#00C8B8"}
                    wrapperClass=""
                    visible={true}
                    wrapperStyle={{display: "block", margin:"auto"}}
                    ariaLabel='oval-loading'
                    secondaryColor="#ccc"
                    strokeWidth={1}
                    strokeWidthSecondary={1}
                  /> 

        </div>
      }
      return (
        myBids.length > 0 ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "8px",
              boxSizing: "border-box"
            }}
          >
            <div
              style={{
                display: "grid",
                width: "100%",
                height: "auto", // Adjust height to fit content

        gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth > 1500 ? `repeat(auto-fill, minmax(9vw, 1fr))` : window.innerWidth < 1100 ? `repeat(auto-fill, minmax(18vw, 1fr))` : `repeat(auto-fill, minmax(14vw, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(18vw, 1fr))` : `repeat(auto-fill, minmax(30vw, 1fr))`,
                
                gap: "16px",
                justifyContent: "center",
                alignItems: "stretch",
                direction: "rtl"
              }}
            >
{myBids.map((product) => {
  // Convert Firestore Timestamp to JavaScript Date object
  const expiryDate = product.expiryDate.toDate();
  
  // Check if the expiryDate is before the current date
  const isExpired = expiryDate < new Date();

  return (
    <Product
      key={product.id}
      imgs={product.imgs[0]}
      productName={product.productName}
      numBidders={product.numBidders}
      expiryDate={product.expiryDate}
      carPlateKind={product.carPlate.kind}
      productSeller={product.sellerId}
      myId={parseFloat(userId)}
      auctionStartDate={product.auctionStartDate.toDate()}
      highestBidder={parseFloat(userId)}
      acceptsOffers={product.postType.includes("عروض")}
      currentBid={product.currentBid}
      condition={product.condition}
      isAuction={true}
      category={product.category}
      productPrice={product.productPrice}
      link={isExpired ? `/my_expired_bids/${product.id}` : `/product/${product.id}`}
      views={product.views}
    />
  );
})}

            </div>
          </div>
        ) : (
          <div style={{ width: "100%", textAlign: "center", marginTop: "8px" }}>
            <h6>أنت لم تقم بأي مزايدة بعد</h6>
          </div>
        )
      );
      
    };

// MyProducts.js
const MyProducts = () => {
  if(myProductsIsLoading){
    return <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex", borderRadius:"24px", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
    <Oval
                height={"30px"}
                width={"30px"}
                color={"#00C8B8"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block", margin:"auto"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              /> 

    </div>
  }
  
  return (
    myProducts.length > 0 && !myProductsIsLoading ? (
      <div style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "8px",
        boxSizing: "border-box"
      }}>
        <div
          style={{
            display: "grid",
            width: "100%",
            height: "auto", // Adjust height to fit content
            gridTemplateColumns: window.innerWidth > window.innerHeight
              ? window.innerWidth < 1100
                ? `repeat(auto-fill, minmax(180px, 1fr))`
                : `repeat(auto-fill, minmax(200px, 1fr))`
              : window.innerWidth > 600
                ? `repeat(auto-fill, minmax(200px, 1fr))`
                : `repeat(auto-fill, minmax(40vw, 1fr))`,
            gap: "16px",
            justifyContent: "center",
            alignItems: "stretch",
            direction: "rtl"
          }}
        >
          {myProducts.map(product => (
            <div key={product.id} style={{ width: "100%" }}>
              <MyProduct
                postId={product.id}
                imgs={product.imgs[0]}
                productName={product.productName}
                auctionStartDate={product.auctionStartDate}
                numBidders={product.numBidders}
                expiryDate={product.expiryDate}
                acceptsOffers={product.postType.includes("عروض")}
                currentBid={product.currentBid}
                condition={product.condition}
                isAuction={product.postType.includes("مزاد")}
                category={product.category}
                carPlateKind={product.carPlate.kind}
                productPrice={product.productPrice}
                link={`/my_products/${product.id}`}
                views={product.views}
                auctionHasSellOrder={product.auctionHasSellOrder}
              />
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div style={{ width: "100%", textAlign: "center", marginTop: "8px" }}>
        <h6>أنت لم تقم بإضافة منتجات بعد</h6>
      </div>
    )
  );
  
}


const MySoldProducts = () => {

  if(mySoldProductsLoading){
    return <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex", borderRadius:"24px", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
    <Oval
                height={"30px"}
                width={"30px"}
                color={"#00C8B8"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block", margin:"auto"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              /> 

    </div>
  }

  return (
    orders.length > 0 ? (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "8px",
          boxSizing: "border-box"
        }}
      >
        <div
          style={{
            display: "grid",
            width: "100%",
            height: "auto", // Adjust height to fit content
            gridTemplateColumns: window.innerWidth > window.innerHeight
              ? window.innerWidth < 1100
                ? `repeat(auto-fill, minmax(150px, 1fr))`
                : `repeat(auto-fill, minmax(200px, 1fr))`
              : window.innerWidth > 600
                ? `repeat(auto-fill, minmax(200px, 1fr))`
                : `repeat(auto-fill, minmax(40vw, 1fr))`,
            gap: "16px",
            justifyContent: "center",
            alignItems: "stretch",
            direction: "rtl"
          }}
        >
          {orders.map(order => (
            <>
            {console.log(order)}
            <Order
              key={order.id}
              imgs={order.productData.imgs[0]}
              productName={order.productData.productName}
              numBidders={order.productData.numBidders}
              expiryDate={order.productData.expiryDate}
              currentBid={order.productData.currentBid}
              carPlateKind={order.productData.carPlate.kind}
              condition={order.order_status}
              isAuction={order.productData.isAuction}
              category={order.productData.category}
              productPrice={order.productData.productPrice}
              link={`/my_sold_products/${order.id}`}
              orderId={order.id}
              />
              </>
          ))}
        </div>
      </div>
    ) : (
      <div style={{ width: "100%", textAlign: "center", marginTop: "8px" }}>
        <h6>لا توجد طلبات بعد</h6>
      </div>
    )
  );
  
}

const MyPurchases = () => {

  if(myPurchasesLoading){
    return <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex", borderRadius:"24px", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
    <Oval
                height={"30px"}
                width={"30px"}
                color={"#00C8B8"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block", margin:"auto"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              /> 

    </div>
  }
  return (
    myPurchases.length > 0 ? (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "8px",
          boxSizing: "border-box"
        }}
      >
        <div
          style={{
            display: "grid",
            width: "100%",
            height: "auto", // Adjust height to fit content
        gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth > 1500 ? `repeat(auto-fill, minmax(9vw, 1fr))` : window.innerWidth < 1100 ? `repeat(auto-fill, minmax(18vw, 1fr))` : `repeat(auto-fill, minmax(14vw, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(18vw, 1fr))` : `repeat(auto-fill, minmax(30vw, 1fr))`,
            gap: "16px",
            justifyContent: "center",
            alignItems: "stretch",
            direction: "rtl"
          }}
        >
          {myPurchases.map(purchase => (
            <Product
              key={purchase.id}
              auctionStartDate={purchase.productData.auctionStartDate && purchase.auctionStartDate.toDate()}
              imgs={purchase.productData.imgs[0]} // Access images from productData
              productName={purchase.productData.productName} // Access product name from productData
              numBidders={purchase.productData.numBidders} // Access number of bidders from productData
              expiryDate={purchase.productData.expiryDate} // Access expiry date from productData
              currentBid={purchase.productData.currentBid} // Access current bid from productData
              carPlateKind={purchase.productData.carPlate.kind} // Access car plate kind from productData
              acceptsOffers={purchase.productData.postType.includes("عروض")} // Access postType from productData
              condition={purchase.productData.condition} // Access condition from productData
              isAuction={purchase.productData.postType.includes("مزاد")} // Access postType for auction status
              category={purchase.productData.category} // Access category from productData
              productPrice={purchase.amount} // Access price from productData
              link={`/my_orders/${purchase.id}`} // Use purchase id for link
              views={purchase.views}
            />
          ))}
        </div>
      </div>
    ) : (
      <div style={{ width: "100%", textAlign: "center", marginTop: "8px" }}>
        <h6>أنت لم تقم بشراء أي منتج بعد</h6>
      </div>
    )
  );
  
  
};


const MyComments = () => {

  return (
    comments.length > 0 ? (

      <div style={{height:"100%", justifyContent:"center", flexDirection:"column", width: "100%", marginLeft:"auto", marginRight:"auto",padding:"16px"}}>
        

        <div 
        style={{
          width: "100%",
          height: "fit-content",
        }}
        >
        {comments.map((comment) => (
          <Comment
          key={comment.timestamp} // Make sure to add a unique key
          comment={comment.comment}
          userRating={comment.user.rating}
          numRaters={comment.user.numRaters}
          userImg={comment.user.profileImgUrl}
          commentId={comment.id}
          isUpdated={comment.isUpdated}
            userName={comment.user.fullName}
            timestamp={comment.timestamp}
            userId={comment.userId}
            link={`/user/${comment.user.id}`}
            />
            ))}
          </div>
    </div>
            ) : <div style={{width:"100%", textAlign:"center", marginTop:"8px"}}><h6 > لم يقم أي شخص بالتعليق على حسابك  بعد</h6  ></div>
          )
};


const handleAcceptOffer = async (offerId, productId, tran_ref) => {
  setOfferResponseLoading(true)
  try {
    const functions = getFunctions();
    const acceptOffer = httpsCallable(functions, 'acceptOffer');

    
    const result = await acceptOffer({
      offerId,
      productId,
      tran_ref,
    });

    console.log('Offer accepted successfully:', result.data);
    // Handle success, update UI or state as needed
  } catch (error) {
      if (error.code === 'failed-precondition' && error.message === 'Product already sold') {
          alert('تم بيع المنتج بالفعل');
      } else {
          alert('An error occurred: ' + (error.message || 'Unknown error'));
      }
    }
  setOfferResponseLoading(false)
};


const handleRejectOffer = async (offerId, productId, tran_ref) => {
  const functions = getFunctions();
  const rejectOffer = httpsCallable(functions, 'rejectOffer');
  setOfferResponseLoading(true)

  try {
    const response = await rejectOffer({
      offerId: offerId,
      productId: productId,
      tran_ref: tran_ref
    });

    console.log('Offer rejected successfully:', response.data);
  } catch (error) {
    console.error('Error rejecting offer:', error);
  }
  setOfferResponseLoading(false)

}


const handleCancelOffer = async (offerId) => {
  const functions = getFunctions();
  const rejectOffer = httpsCallable(functions, 'cancelOffer');
  setOfferResponseLoading(true)

  try {
    const response = await rejectOffer({
      offerId: offerId,
    });

    console.log('Offer cancelled successfully:', response.data);
  } catch (error) {
    console.error('Error cancelled offer:', error);
  }
  setOfferResponseLoading(false)
}


const MyProductsOffers = () => {
  return (
    offersLoading ? (
      <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
      <Oval
        height={"30px"}
        width={"30px"}
        color={"#00C8B8"}
        wrapperClass=""
        visible={true}
        wrapperStyle={{ display: "block", margin: "auto" }}
        ariaLabel='oval-loading'
        secondaryColor="#ccc"
        strokeWidth={1}
        strokeWidthSecondary={1}
      />
    </div>
    ) : (
      offers.length > 0 ? (
        <div
          style={{
            height: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "8px",
          }}
        >
          <div
            style={{
              display: "grid",
              width: "100%",
              height: "fit-content",
              direction: "rtl",
              rowGap: "16px",
              columnGap: "16px",
              gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth < 1100 ? `repeat(auto-fill, minmax(150px, 1fr))` : `repeat(auto-fill, minmax(200px, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(200px, 1fr))` : `repeat(auto-fill, minmax(40vw, 1fr))`,
              justifyContent: "center",
              justifyItems: "stretch",
              alignItems: "stretch",
            }}
          >
            {offers.map((offer) => {
              return (
                <div key={offer.id}>
                <Offer
                offerId={offer.id}
                  imgs={offer.product.imgs[0]}
                  productName={offer.product.productName}
                  amount={offer.amount}
                  category={offer.product.category}
                  carPlateKind={offer.product.carPlate.kind}
                  link={`/product/${parseFloat(offer.product.id)}`}
                  acceptOffer={() => handleAcceptOffer(offer.id, offer.product.id, offer.tran_ref, offer.product)}
                  rejectOffer={() => handleRejectOffer(offer.id, offer.product.id, offer.tran_ref)}
                />
                  </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", textAlign: "center", marginTop: "8px" }}>
          <h6>لا توجد عروض على منتجاتك بعد</h6>
        </div>
      )
    )
  );
};


const MyOffers = () => {
  return (
    offersLoading ? (
      <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
      <Oval
        height={"30px"}
        width={"30px"}
        color={"#00C8B8"}
        wrapperClass=""
        visible={true}
        wrapperStyle={{ display: "block", margin: "auto" }}
        ariaLabel='oval-loading'
        secondaryColor="#ccc"
        strokeWidth={1}
        strokeWidthSecondary={1}
      />
    </div>
    ) : (
      myOffers.length > 0 ? (
        <div
          style={{
            height: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "8px",
          }}
        >
          <div
            style={{
              display: "grid",
              width: "100%",
              height: "fit-content",
              direction: "rtl",
              rowGap: "16px",
              columnGap: "16px",
              gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth < 1100 ? `repeat(auto-fill, minmax(150px, 1fr))` : `repeat(auto-fill, minmax(200px, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(200px, 1fr))` : `repeat(auto-fill, minmax(40vw, 1fr))`,
              justifyContent: "center",
              justifyItems: "stretch",
              alignItems: "stretch",
            }}
          >
            {myOffers.map((offer) => {
              return (
                <div key={offer.id}>
                <MyOffer
                offerId={offer.id}
                  imgs={offer.product.imgs[0]}
                  productName={offer.product.productName}
                  amount={offer.amount}
                  category={offer.product.category}
                  carPlateKind={offer.product.carPlate.kind}
                  link={`/product/${parseFloat(offer.product.id)}`}
                  cancelOffer={() => handleCancelOffer(offer.id)}
                />
                  </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", textAlign: "center", marginTop: "8px" }}>
          <h6>أنت لم تقدم أي عروض بعد</h6>
        </div>
      )
    )
  );
};

const MyRatings = () => {
  return (
    reviewLoading ? (
      <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex", border:"none", backgroundColor:"transparent", minHeight: "100vh"}}>
      <Oval
        height={"30px"}
        width={"30px"}
        color={"#00C8B8"}
        wrapperClass=""
        visible={true}
        wrapperStyle={{ display: "block", margin: "auto" }}
        ariaLabel='oval-loading'
        secondaryColor="#ccc"
        strokeWidth={1}
        strokeWidthSecondary={1}
      />
    </div>
    ) : (
      reviews.length > 0 ? (
        <div
          style={{
            height: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "8px",
          }}
        >
            {reviews.map((review) => {
              return (
                <div key={review.id} style={{width:"100%"}}>
                  <Rating 
                  userImg={review.reviewer.profileImgUrl} 
                  timestamp={review.timestamp} 
                  userName={review.reviewer.fullName} 
                  numRaters={review.reviewer.numRaters}
                  userRating={review.reviewer.rating}
                  ratingId={review.id}
                  orderId={review.orderId}
                  link={`/user/${review.reviewer.id}`}
                  userId={review.reviewer.id}
                  isVerified={review.reviewer.isVerified}
                  isBusiness={review.reviewer.isBusiness}
                  rating={review.rating}
                  notes={review.notes}
                  />
                  </div>
              );
            })}
        </div>
      ) : (
        <div style={{ width: "100%", textAlign: "center", marginTop: "8px" }}>
          <h6>
          لم يقم أي شخص بتقييم منتجاتك بعد
            </h6>
        </div>
      )
    )
  );
};

  return ( 

    <>
    {showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}
{offerResponseLoading && (
  <>
    <div style={{width:"100vw", height:"100vh", backgroundColor: 'rgba(0, 0, 0, 0.2)',  position:"fixed", zIndex:"1010", display:"flex", justifyContent:"center", alignItems:"center", top:0}}>
      <div style={{backgroundColor:"white",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", padding:"3% 2%", borderRadius:"12px"}}>
      <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor:"white",
              marginBottom:"8px"
            }}
          >
                    <Oval
          height={"35px"}
          width={"35px"}
          color={"#00C8B8"}
          wrapperClass=""
          visible={true}
          wrapperStyle={{ display: "block" }}
          ariaLabel='oval-loading'
          secondaryColor="#ccc"
          strokeWidth={2}
          strokeWidthSecondary={1}
        />
          </div>

          <p style={{marginBottom:"0px"}}>جاري المعالجة</p>
      </div>
    </div>
  </>
)}



{verificationRequestShouldRender && (
   <div
   style={{
    backgroundColor: "rgba(0, 0, 0, 0.2)",
     width: "100vw",
     height: "100vh",
     position: "fixed",
     zIndex: 1000,
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     opacity: verificationRequestIsOpen ? 1 : 0,
     transition: "opacity 0.1s ease-out",
     top: 0, left:0
    }}
    
    >
          <div style={{
            width: window.innerWidth > window.innerHeight ? "fit-content" : "90%",
            height: "70vh",
            backgroundColor: "white",
            position: "relative",
            margin: "auto",
            opacity: 1,
            zIndex: 2,
            borderRadius: "16px",
            padding: "8px 16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY:"auto",
            scrollbarWidth: 'none', /* لـ Firefox */
            msOverflowStyle: 'none', /* لـ Internet Explorer و Edge */

            
          }}
          className={verificationRequestIsOpen ? "pop-up" : "pop-down"}
          >
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "8px"
            }}>
              <IoIosCloseCircle size={27} style={{ cursor: "pointer" }} color='#ccc' onClick={() => {
                if(verificationSentLoading) return;
                if(ibanLetterRef.current){
                  ibanLetterRef.current.value = null;
                }
                setIdNumber("")
                setThreeNames("")
                setIbanLetter(null)
                setFileName("");
                setVerificationRequestIsOpen(false);
              }} />
              <div style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center"
              }}>
                <h5 style={{ marginTop: "16px" }}> <RiVerifiedBadgeFill size={22}  style={{marginLeft: "4px", marginBottom:"4px"}} color='#00C8B8'/> طلب توثيق حساب فرد </h5>
              </div>
              <div style={{ width: 32, height: 32 }}></div> {/* Empty div for balance */}
            </div>
            <div style={{direction:"rtl"}}>
                <h5 style={{color:"#00A49B", fontWeight:500}}>ضاعف فرص بيعك لمنتجاتك بتوثيق حسابك لكسب ثقة العملاء و رفع تقييمك! </h5>

                <p style={{color:"#00A49B", marginTop:"16px", marginBottom:"6px", fontWeight:"bolder"}}>مميزات توثيق الحساب:</p>
                <li style={{color:"#00A49B"}}>كسب ثقة العملاء</li>
                <li style={{color:"#00A49B"}}>الحصول على شارة التوثيق <RiVerifiedBadgeFill size={20} style={{ position: "relative", marginRight: "0px", marginBottom:"3px" }} color='#00C8B8' /></li>

                <div
              style={{
                textAlign: 'center',
                position: "relative",
                borderRadius: "16px",
                width: "100%",
                padding: "16px 8px",
                backgroundColor: "#F7F7F7",
                textAlign: 'right',
                display:"flex",
                marginTop:"8px"
              }}
            >
                  <MdInfo size={20} color='#a0a0a0' style={{minHeight:"20px", minWidth:"20px"}} />
                  <div style={{marginRight:"4px"}}>
                    
              <p style={{marginBottom:"0px"}}>
              عند توثيق حسابك سيتم عرض اسمك الثنائي و لن يكون بإمكانك تغييره الا عند إلغاء التوثيق
                </p>
                  </div>
            </div>

                <p style={{marginTop:"16px", color:"#00A49B", fontWeight:500, marginBottom:"4px"}}>لإرسال طلب التوثيق, يرجى ارفاق التالي: </p>

                <p style={{marginBottom:"0px", color:"#00A49B", fontWeight:500}}><span style={{color:"red"}}>*</span>رقم الهوية/الإقامة</p>
                <input
                ref={idNumberRef}
                placeholder='1234567890'
                type='tel'
                autoComplete='id'
                onChange={(e) => {
                  const inputValue = e.target.value;

                  if(inputValue.length > 10){
                    setIdNumber("");
                    idNumberRef.current.value = "";
                    alert("يجب أن يتكون رقم الهوية/الإقامة من 10 أرقام");
                    
                  }
          
                  // Check if the input contains non-numeric characters
                  if (/[^0-9]/.test(inputValue)) {
                    alert("يسمح بالأرقام فقط");
                    // Clear the input field
                    setIdNumber("");
                    idNumberRef.current.value = "";
                  } else {
                    // Update the state with the numeric value
                    setIdNumber(inputValue);
                  }
                }}
                style={{backgroundColor:"#F0F0F0", border: "none", width: window.innerWidth > window.innerHeight ? "50%" : "100%", height: "40px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right", marginTop:"8px"}}
                />

                <p style={{marginBottom:"0px", marginTop:"16px", color:"#00A49B", fontWeight:500}}><span style={{color:"red"}}>*</span>الاسم الثلاثي</p>
                <input
                placeholder='الاسم الثلاثي'
                type='text'
                defaultValue={fullName}
                autoComplete='name'
                onChange={(e)=>{
                  setThreeNames(e.target.value)
                }}
                style={{backgroundColor:"#F0F0F0", border: "none", width: window.innerWidth > window.innerHeight ? "50%" : "100%", height: "40px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right", marginTop:"8px"}}
                />


                <p style={{marginBottom:"0px", marginTop:"16px", color:"#00A49B", fontWeight:500}}><span style={{color:"red"}}>*</span>شهادة الايبان <span style={{fontSize:"15px", fontWeight:400}}>(يجب مطابقة الاسم الثلاثي و رقم الهوية لصاحب الحساب البنكي)</span></p>

                <input
                ref={ibanLetterRef}
                onChange={handleFileChange}
                  type='file'
                  accept='.pdf, image/*'
                  style={{
                    backgroundColor: "#F0F0F0",
                    border: "none",
                    width: window.innerWidth > window.innerHeight ? "50%" : "100%",
                    height: "40px",
                    borderRadius: "8px",
                    outline: "none",
                    padding: "8px 16px",
                    textAlign: "right",
                    marginTop: "8px",
                    display:"none"
                  }}
                />

                  <div 
                  onClick={() => { 
                    if (ibanLetterRef.current) {
                      ibanLetterRef.current.click();
                    }
                  }}
                  style={{marginBottom:"16px",display:"flex", alignItems:"center", justifyContent:"flex-start", cursor: "pointer", marginTop:"8px", width:"fit-content"}}>
                  <GoPaperclip
                          size={32} color='#00C8B8' style={{padding: "4px", borderRadius: "50%"}}
                          onMouseEnter={(e) => e.target.style.backgroundColor = "#F7F7F7"} // Change color on hover
                          onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                        />

                        <p style={{marginBottom:"0px", fontSize:"11px", marginRight:"2px"}}>إرفاق ملف أو صورة (PNG, JPG, PDF)</p>
                    </div>

                        {fileName && (
                          <div style={{display:"flex", justifyContent:"space-between"}}>
                          <p>{fileName}</p>
                          <IoMdClose size={20} color='#F00057' style={{cursor:"pointer", minHeight:"20px", minWidth:"20px"}} 
                        onClick={()=>{
                          if(!verificationSentLoading){
                            if(ibanLetterRef.current){
                              setIbanLetter(null)
                              ibanLetterRef.current.value =null;
                              setFileName("")
                            }
                          }
                          else{
                            return;
                          }
                        }}
                        />
                        </div>
                        )
                        
                        }






              </div>

            <div style={{marginTop:"auto", display:"flex", justifyContent:"space-between", direction:"rtl",  marginBottom:"8px"}}>
    <button 
    id='my-button' 
    disabled={verificationSentLoading} 
    style={{height:"35px", padding:"4px 16px", width:"100%", marginLeft:"8px", minWidth:"5vw"}} onClick={()=>{sendVerificationRequest()}} >
    {   
     verificationSentLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    display:"flex"

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{marginBottom:"0px"}}>إرسال</p>
              }
    </button>
    <button disabled={verificationSentLoading} id='my-button-red' style={{padding:"4px 8px",  width:"100%", minWidth:"5vw"}} onClick={()=>{
                if(verificationSentLoading) return;
                                if(ibanLetterRef.current){
                                  setIbanLetter(null)
                                  ibanLetterRef.current.value = null;
                                }
                                setIdNumber("")
                                setThreeNames("")
                                setFileName("");
                                setVerificationRequestIsOpen(false);

    }} >إلغاء</button>
    </div>
          </div>
        </div>
      )}




{switchToStoreRequestShouldRender && (
   <div
   style={{
    backgroundColor: "rgba(0, 0, 0, 0.2)",
     width: "100vw",
     height: "100vh",
     position: "fixed",
     zIndex: 1000,
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     opacity: switchToStoreRequestIsOpen ? 1 : 0,
     transition: "opacity 0.1s ease-out",
     top: 0, left:0
    }}
    
    >
          <div style={{
            width: window.innerWidth > window.innerHeight ? "fit-content" : "90%",
            height: "70vh",
            backgroundColor: "white",
            position: "relative",
            margin: "auto",
            opacity: 1,
            zIndex: 2,
            borderRadius: "16px",
            padding: "8px 16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY:"auto",
            scrollbarWidth: 'none', /* لـ Firefox */
            msOverflowStyle: 'none', /* لـ Internet Explorer و Edge */

            
          }}
          className={switchToStoreRequestIsOpen ? "pop-up" : "pop-down"}
          >
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "8px"
            }}>
              <IoIosCloseCircle size={27} style={{ cursor: "pointer" }} color='#ccc' onClick={() => {
                if(switchToStoreRequestLoading) return;

                if(crRef.current){
                  setCR(null)
                  crRef.current.value =null;
                  setCRfileName("")
                }
                                if(ibanLetterRef.current){
                                  setIbanLetter(null)
                                  ibanLetterRef.current.value = null;
                                }
                                setIdNumber("")
                                setThreeNames("")
                                setFileName("");
                                setSwitchToStoreRequestIsOpen(false); 
              }} />
              <div style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center"
              }}>
                <h5 style={{ marginTop: "16px" }}> <FaStore size={22} style={{ position: "relative", marginRight: "0px",  marginBottom:"4px" }} color='#F00057' /> طلب ترقية لحساب تاجر </h5>
              </div>
              <div style={{ width: 32, height: 32 }}></div> {/* Empty div for balance */}
            </div>
            <div style={{direction:"rtl"}}>
                <p style={{color:"#00A49B", fontWeight:500}}>استفد من ميزات التاجر و اكسب ثقة العملاء لبيع أكبر عدد ممكن من المنتجات!</p>
                <p style={{color:"#00A49B", marginTop:"16px", marginBottom:"6px", fontWeight:"bolder"}}>مميزات حساب التاجر:</p>
                <li style={{color:"#00A49B"}}>إضافة كمية للمنتجات</li>
                <li style={{color:"#00A49B"}}>كسب ثقة العملاء</li>
                <li style={{color:"#00A49B"}}>الحصول على شارة التاجر <FaStore size={20} style={{ position: "relative", marginRight: "0px" ,  marginBottom:"4px"}} color='#F00057' /></li>

                <div
              style={{
                textAlign: 'center',
                position: "relative",
                borderRadius: "16px",
                width: "100%",
                padding: "16px 8px",
                backgroundColor: "#F7F7F7",
                textAlign: 'right',
                display:"flex",
                marginTop:"8px"
              }}
            >
                  <MdInfo size={20} color='#a0a0a0' style={{minHeight:"20px", minWidth:"20px"}} />
                  <div style={{marginRight:"4px"}}>
                    
              <p style={{marginBottom:"0px"}}>
              عند توثيق حسابك سيتم عرض اسم المتجر و لن يكون بإمكانك تغييره الا عند إلغاء التوثيق
                </p>
                  </div>
            </div>

                <p style={{marginTop:"16px", color:"#00A49B", fontWeight:500, marginBottom:"4px"}}>لإرسال طلب التوثيق, يرجى ارفاق التالي: </p>



                <p style={{marginBottom:"0px",color:"#00A49B", fontWeight:500}}><span style={{color:"red"}}>*</span>اسم المتجر</p>
                <input
                placeholder='اسم المتجر'
                type='text'
                onChange={(e)=>{
                  setStoreName(e.target.value)
                }}
                style={{backgroundColor:"#F0F0F0", border: "none", width: window.innerWidth > window.innerHeight ? "50%" : "100%", height: "40px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right", marginTop:"8px"}}
                />




                <p style={{marginBottom:"0px", color:"#00A49B", fontWeight:500, marginTop:"16px",}}><span style={{color:"red"}}>*</span>رقم السجل التجاري/وثيقة العمل الحر</p>
                <input
                ref={crNumberRef}
                placeholder='1234567890'
                onChange={(e) => {
                  const inputValue = e.target.value;
                    setCRNumber(inputValue);
                }}
                style={{backgroundColor:"#F0F0F0", border: "none", width: window.innerWidth > window.innerHeight ? "50%" : "100%", height: "40px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right", marginTop:"8px"}}
                />




<p style={{marginBottom:"0px", color:"#00A49B", fontWeight:500, marginTop:"16px",}}><span style={{color:"red"}}>*</span>رقم التوثيق من مركز الأعمال االسعودي</p>
                <input
                ref={businessVerificationNumberRef}
                placeholder='0000000000'
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // Check if the input contains non-numeric characters
                  if (/[^0-9]/.test(inputValue)) {
                    alert("يسمح بالأرقام فقط");
                    // Clear the input field
                    setBusinessVerificationNumber("");
                    businessVerificationNumberRef.current.value = "";
                  } else {
                    // Update the state with the numeric value
                    setBusinessVerificationNumber(inputValue);
                  }
                }}
                style={{backgroundColor:"#F0F0F0", border: "none", width: window.innerWidth > window.innerHeight ? "50%" : "100%", height: "40px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right", marginTop:"8px"}}
                />


              <p style={{marginBottom:"0px", marginTop:"16px", color:"#00A49B", fontWeight:500}}><span style={{color:"red"}}>*</span>السجل التجاري/وثيقة العمل الحر</p>

              <input
              ref={crRef}
              onChange={handleCRFileChange}
                type='file'
                accept='.pdf, image/*'
                style={{
                  backgroundColor: "#F0F0F0",
                  border: "none",
                  width: window.innerWidth > window.innerHeight ? "50%" : "100%",
                  height: "40px",
                  borderRadius: "8px",
                  outline: "none",
                  padding: "8px 16px",
                  textAlign: "right",
                  marginTop: "8px",
                  display:"none"
                }}
              />

<div 
                  onClick={() => { 
                    if (crRef.current) {
                      crRef.current.click();
                    }
                  }}
                  style={{marginBottom:"16px",display:"flex", alignItems:"center", justifyContent:"flex-start", cursor: "pointer", marginTop:"8px", width:"fit-content"}}>
                  <GoPaperclip
                          size={32} color='#00C8B8' style={{padding: "4px", borderRadius: "50%"}}
                          onMouseEnter={(e) => e.target.style.backgroundColor = "#F7F7F7"} // Change color on hover
                          onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                        />

                        <p style={{marginBottom:"0px", fontSize:"11px", marginRight:"2px"}}>إرفاق ملف أو صورة (PNG, JPG, PDF)</p>
                    </div>

                        {crFileName && (
                          <div style={{display:"flex", justifyContent:"space-between"}}>
                          <p>{crFileName}</p>
                          <IoMdClose size={20} color='#F00057' style={{cursor:"pointer", minHeight:"20px", minWidth:"20px"}} 
                        onClick={()=>{
                          if(!switchToStoreRequestLoading){
                            if(crRef.current){
                              setCR(null)
                              crRef.current.value =null;
                              setCRfileName("")
                            }
                          } else{
                            return
                          }
                        }}
                        />
                        </div>
                        )


                      }


                <p style={{marginBottom:"0px", marginTop:"16px", color:"#00A49B", fontWeight:500}}><span style={{color:"red"}}>*</span>شهادة الايبان <span style={{fontSize:"15px", fontWeight:400}}>(يجب مطابقة رقم السجل التجاري/وثيقة العمل الحر)</span></p>

                <input
                ref={ibanLetterRef}
                onChange={handleFileChange}
                  type='file'
                  accept='.pdf, image/*'
                  style={{
                    backgroundColor: "#F0F0F0",
                    border: "none",
                    width: window.innerWidth > window.innerHeight ? "50%" : "100%",
                    height: "40px",
                    borderRadius: "8px",
                    outline: "none",
                    padding: "8px 16px",
                    textAlign: "right",
                    marginTop: "8px",
                    display:"none"
                  }}
                />

                  <div 
                  onClick={() => { 
                    if (ibanLetterRef.current) {
                      ibanLetterRef.current.click();
                    }
                  }}
                  style={{marginBottom:"16px",display:"flex", alignItems:"center", justifyContent:"flex-start", cursor: "pointer", marginTop:"8px", width:"fit-content"}}>
                  <GoPaperclip
                          size={32} color='#00C8B8' style={{padding: "4px", borderRadius: "50%"}}
                          onMouseEnter={(e) => e.target.style.backgroundColor = "#F7F7F7"} // Change color on hover
                          onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                        />

                        <p style={{marginBottom:"0px", fontSize:"11px", marginRight:"2px"}}>إرفاق ملف أو صورة (PNG, JPG, PDF)</p>
                    </div>

                        {fileName && (
                          <div style={{display:"flex", justifyContent:"space-between"}}>
                          <p>{fileName}</p>
                          <IoMdClose size={20} color='#F00057' style={{cursor:"pointer", minHeight:"20px", minWidth:"20px"}} 
                        onClick={()=>{
                          if(!switchToStoreRequestLoading){
                            if(ibanLetterRef.current){
                              setIbanLetter(null)
                              ibanLetterRef.current.value =null;
                              setFileName("")
                            }
                          }
                          else{
                            return;
                          }
                        }}
                        />
                        </div>
                        )
                        
                        }






              </div>

            <div style={{marginTop:"auto", display:"flex", justifyContent:"space-between", direction:"rtl", marginBottom:"8px"}}>
    <button 
    id='my-button' 
    disabled={switchToStoreRequestLoading} 
    style={{height:"35px", padding:"4px 16px", width:"100%", marginLeft:"8px", minWidth:"5vw"}} onClick={()=>{sendSwitchToStoreRequest()}} >
    {   
     switchToStoreRequestLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    display:"flex"

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{marginBottom:"0px"}}>إرسال</p>
              }
    </button>
    <button disabled={switchToStoreRequestLoading} id='my-button-red' style={{padding:"4px 8px",  width:"100%", minWidth:"5vw"}} onClick={()=>{
                if(switchToStoreRequestLoading) return;

                if(crRef.current){
                  setCR(null)
                  crRef.current.value =null;
                  setCRfileName("")
                }
                                if(ibanLetterRef.current){
                                  setIbanLetter(null)
                                  ibanLetterRef.current.value = null;
                                }
                                setIdNumber("")
                                setThreeNames("")
                                setFileName("");
                                setSwitchToStoreRequestIsOpen(false);

    }} >إلغاء</button>
    </div>
          </div>
        </div>
      )}
    
    <div
    style={{
      opacity: isVisible ? 1 : 0, // Change opacity based on isVisible
      transform: isVisible ? "translateY(0)" : "translateY(20px)", // Reset translateY based on isVisible
      transition: "opacity 0.3s ease, transform 0.2s ease",
    }}
  >

<div style={{width:"100%", height:"fit-content", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>

          {/* path */}
          <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: window.innerWidth > window.innerHeight ? "80%" : "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
          <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8"}} />
          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px",
      fontWeight:"bold"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>حسابي</h6>

          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>{activeSubScreen}</h6>
        </div>
      </div>

<div style={{ display: window.innerWidth > window.innerHeight ? 'flex' : "block", height: 'fit-content', minHeight:"calc(100vh - 65px)" ,width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginLeft:  "auto", marginRight: "auto", direction: "rtl", marginTop: window.innerWidth > window.innerHeight ? "42px" : "8px", paddingBottom:"65px"}}>
  
  <div style={{
    flex: 0.57,
    display: "flex", // Use flexbox for vertical alignment
    flexDirection: window.innerWidth > window.innerHeight ? 'column' : "row", // Stack children vertically
    height: '100%', // Fill the available height
    position: "relative",
    minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "fit-content",
    alignItems:"center",
    marginBottom:"8px",
    overflowX: window.innerWidth > window.innerHeight ? "hidden" : "scroll",
    minWidth:"10vw"
  }}>
    {/* Navigation Links with NavLink */} 
    <NavLink to="my_sales" className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
    
    {
      activeSubScreen === "مبيعاتي" ? (
        <MdInsertChart style={{marginLeft:"4px"}} size={19}  />
        
      ) : (
        <MdInsertChartOutlined style={{marginLeft:"4px"}} size={19} />

      )
    }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>مبيعاتي</p> 
    
    </NavLink>

    <NavLink style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} to="my_info" className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
    
    {
      activeSubScreen === "معلوماتي" ? (
        <BsPersonFill style={{marginLeft:"4px"}} size={19}  />
        
      ) : (
        <BsPerson style={{marginLeft:"4px"}} size={19} />

      )
    }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>معلوماتي</p> 
    
    </NavLink>
    <NavLink to="my_products" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
      {
        activeSubScreen === "منتجاتي" ? (
          <MdWatch style={{marginLeft:"4px"}} size={19}  />
          
        ) : (
          <MdOutlineWatch style={{marginLeft:"4px"}} size={19} />
          
        )
      }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>منتجاتي</p> 

      </NavLink>
    <NavLink to="my_bids" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
      <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
        <div  style={{display:"flex"}}>
        {
        activeSubScreen === "مزايداتي" ? (
          <PiGavelFill style={{marginLeft:"4px"}} size={19}  />
          
        ) : (
          <PiGavel style={{marginLeft:"4px"}} size={19} />
          
        )
      }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>مزايداتي</p> 
        </div>
      </div>
      {/* <div style={{minWidth:"16px", minHeight:"8px", borderRadius:"50%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"red"}}><p style={{marginBottom:"0px", color:"white", fontSize:"10px"}}>1</p>
      </div> */}
      
      </NavLink>
    <NavLink to="my_purchases" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    {
      activeSubScreen === "مشترياتي" ? (
        <RiPriceTag3Fill style={{marginLeft:"4px"}} size={19}  />
        
      ) : (
        <RiPriceTag3Line style={{marginLeft:"4px"}} size={19} />

      )
    }
      
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>مشترياتي</p> 
      
      </NavLink>
    <NavLink to="my_sold_products" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px", whiteSpace:"nowrap"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
        <div  style={{display:"flex"}}>
        {
        activeSubScreen === "الطلبات" ? (
          <IoBagCheck style={{marginLeft:"4px"}} size={19}  />
          
        ) : (
          <IoBagCheckOutline style={{marginLeft:"4px"}} size={19} />
          
        )
      }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>الطلبات</p> 
        </div>
      </div>
      {/* <div style={{minWidth:"16px", minHeight:"8px", borderRadius:"50%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"red"}}><p style={{marginBottom:"0px", color:"white", fontSize:"10px"}}>1</p>
      </div> */}
      </NavLink>
    <NavLink to="my_products_offers" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px", whiteSpace:"nowrap"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    {
      activeSubScreen === "عروض على منتجاتي" ? (
        <LuBadgePercent style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19}  />
        
      ) : (
        <LuBadgePercent  style={{marginLeft:"4px", minHeight:"19px", minWidth:"20px"}} size={19} />

      )
    }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>عروض شراء على منتجاتي</p> 
      
      </NavLink>
    <NavLink to="my_offers" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px", whiteSpace:"nowrap"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    {
      activeSubScreen === "عروض تم تقديمها" ? (
        <HiReceiptPercent style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19}  />
        
      ) : (
        <HiOutlineReceiptPercent style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19} />

      )
    }
      <p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>عروض شراء تم تقديمها</p> 
      
      </NavLink>
    <NavLink to="my_comments" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      

    {
      activeSubScreen === "التعليقات" ? (
        <BiSolidComment style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19}  />
        
      ) : (
        <BiComment style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19} />

      )
    }

<p style={{marginBottom:"0px", whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>التعليقات</p> 

      </NavLink>
    <NavLink to="my_ratings" style={{marginTop: window.innerWidth > window.innerHeight ? "8px" : "0px"}} className={({ isActive }) => (isActive ? "sidebar-active" : "sidebar-inActive")}>
      
    {
      activeSubScreen === "التقييمات" ? (
        <RiStarSFill style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19}  />
        
      ) : (
        <RiStarSLine style={{marginLeft:"4px", minHeight:"19px", minWidth:"19px"}} size={19} />

      )
    }
      
      <p style={{marginBottom:"0px",whiteSpace:  window.innerWidth > window.innerHeight ? "break-spaces" : "nowrap", fontSize:"14px"}}>التقييمات
      </p>
      </NavLink>
    {/* Logout div positioned at the bottom */}
    <div style={{
      fontSize: "18px",
      fontWeight: "400",
      cursor: 'pointer', // Changes the cursor to pointer when hovering over the div
      width:"fit-content",
      padding: window.innerWidth > window.innerHeight? "8px" : "8px 16px",
      marginTop:window.innerWidth > window.innerHeight ?  "16px" : "0px",
      // marginRight: window.innerWidth > window.innerHeight ? "auto" : "0px",
      marginLeft: window.innerWidth > window.innerHeight ? "auto" : "0px",
    }}
    onClick={()=>{
      if (window.confirm("تسجيل الخروج؟")) {
        navigate("/")
        auth.signOut();
      }
    }}
    >
      <p style={{marginBottom: "0px", color: "red", textAlign: "right"}}><MdLogout size={17} /></p>
    </div>
  </div>
  <div style={{flex:2.5, width:"100%", border: "1.5px solid #00C8B8", marginRight: window.innerWidth > window.innerHeight ?"16px" : "0px", borderRadius:"24px", minHeight: window.innerWidth > window.innerHeight ? "20vh" : "80vh", maxWidth: window.innerWidth > window.innerHeight ? "80%" : "100%"}}>
    {/* Route Configuration */}
    <Routes>
      <Route index element={<Navigate replace to="my_sales" />} />
      <Route path="my_sales" element={MySales()} />
      <Route path="my_info" element={MyInfo()} />
      <Route path="my_products" element={<MyProducts />} />
      <Route path="my_bids" element={<MyBids />} />
      <Route path="my_purchases" element={<MyPurchases />} />
      <Route path="my_sold_products" element={<MySoldProducts />} />
      <Route path="my_comments" element={<MyComments />} />
      <Route path="my_ratings" element={<MyRatings />} />
      <Route path="my_products_offers" element={<MyProductsOffers />} />
      <Route path="my_offers" element={<MyOffers />} />
    </Routes>
  </div>
</div>
</div>
</div>
</>

   );
  }

export default MyAccountScreen;