import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { GoHomeFill, GoReport } from "react-icons/go";
import { BsListCheck, BsFillPersonFill } from "react-icons/bs";
import { BsPersonCircle } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdAddCircleOutline, IoMdSettings } from "react-icons/io";
import Dropdown from 'react-bootstrap/Dropdown';
import image from '../assets/logo.jpg';
import { TextField, Button, Divider } from "@mui/material"; 
import { Link, NavLink } from 'react-router-dom';
import { DropdownDivider } from 'react-bootstrap';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, {useState, useRef} from 'react';
import ReactDom from 'react-dom'; 
import Category from './Category';
import HomeScreen from '../screens/ProductsScreen';
import { RxHamburgerMenu } from "react-icons/rx";
import { useSearchParams , useNavigate} from 'react-router-dom';
import furnitureImg from '../assets/furniture.jpg'
import logo2 from '../assets/logo.png'
import { BiSolidWatch } from "react-icons/bi";
import SebhahIcon from '../assets/sebhah00C8B8.PNG'
import { BsFillPenFill } from "react-icons/bs";
import { BiSolidWalletAlt } from "react-icons/bi";
import { LiaPenAltSolid } from "react-icons/lia";
import { IoWalletOutline } from "react-icons/io5";
import { PiWatch } from "react-icons/pi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlinePerson } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { BsPerson } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";
import plateIcon from '../assets/carPlateF000572.png'
import carPlateIcon from '../assets/car-plate-icon00C8B8.png'
import { IoAddCircleOutline } from "react-icons/io5";
import { RiShoppingBagLine } from "react-icons/ri";
import { FaPhone } from "react-icons/fa";
import phoneNumberIcon from '../assets/phoneNumbersNav.png'
import { PiGavelFill } from "react-icons/pi";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { IoCloseCircleOutline } from "react-icons/io5";
import { AiOutlineMessage, AiOutlineAlert, AiOutlineComment, AiOutlineNotification } from 'react-icons/ai';
import { getFunctions, httpsCallable } from "firebase/functions";
import { Oval } from 'react-loader-spinner'
import Lottie from 'lottie-react';
import NoNotificatoinsAnim from '../assets/animations/noNotificationsAnim.json'
import { LuBadgePercent } from "react-icons/lu";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import { useChat } from '../hooks/ChatContext'
import { doc, updateDoc, getDoc, collection, Timestamp, addDoc } from 'firebase/firestore';
import { formatDistanceToNow } from 'date-fns';
import arLocale from 'date-fns/locale/ar-SA';


function CustomNavbar({numNotifications, notifications, myId, setNotifications, notificationsLoading, setNotificationsLoading}) {
 const [openDrawer, setOpenDrawer] = useState(false);
 const [openSearch, setOpenSearch] = useState(false);
 const [searchParams, setSearchParams] = useSearchParams();
 const navigate = useNavigate();

 const searchRef = useRef();
 const desktopSearchRef = useRef();
 const [searchTerm, setSearchTerm] = useState("");

 const formRef = useRef();








 const { setChatIsOpen, setNewChatUser, setDrawerOpen, setActiveChatId, usersInfo, chats } = useChat();




 const [isDropdownVisible, setDropdownVisible] = useState(false);
 const [isNotificationsVisible, setNotificationsVisible] = useState(false);

 const handleMouseEnter = () => {
   setDropdownVisible(true);
 };

 const handleMouseLeave = () => {
   setDropdownVisible(false);
 };

 const handleMouseEnterNotifications = () => {
  setNotificationsVisible(true);
 };

 const handleMouseLeaveNotifications = () => {
  setNotificationsVisible(false);
 };




 function handleKeyDown(event) {
  if (event.key === 'Enter') {
    setSearchParams(`?${searchParams}`);
    if(searchRef.current.value.trim().length == 0){
      navigate("/");
      searchRef.current.value = "";
    }
    else{
      navigate(`?${searchParams}`)
    }
 }
}

const readNotifications = async () => {
  const confirmed = window.confirm("قراءة جميع الإشعارات؟");

  if (!confirmed) {
    console.log("User cancelled marking notifications as read.");
    return;
  }

  try {
    setNotificationsLoading(true)
    const functions = getFunctions(); // Initialize Firebase functions
    const markNotificationsFunction = httpsCallable(functions, 'markAllNotificationsAsRead'); // Call the backend function

    const result = await markNotificationsFunction({
      myId // Pass the myId to the backend function
    });

    if (result.data.success) {
      console.log("All notifications marked as read.");
      // Update the local state or UI if necessary
      setNotifications(prevNotifications =>
        prevNotifications.map(notification => ({
          ...notification,
          isRead: true // Mark notifications as read in the local state
        }))
      );
    } else {
      console.error("Error marking notifications as read:", result.data.error);
    }
  } catch (error) {
    console.error("Error calling markAllNotificationsAsRead function:", error);
  } finally {
    setTimeout(() => {
      setNotificationsLoading(false);
    }, 3000); // 3000 milliseconds = 3 seconds
  }
};



  return (
    <>

    <div className='desktop-navbar'>
     <div style={{width:"80%", display:"flex", margin:"0px", height:"100%"}}>

    <div style={{ width: "fit-content", height: "100%", display: "flex", alignItems: "center", position: "relative"}}>
  <a href='/'><img style={{ height: "55px", marginRight: "0px" }} src={logo2} /></a>
  <a style={{
     textDecoration: "none",
     color: "#01635c",
     marginBottom: "0px",
     fontSize: "15px",
    //  fontWeight: "bold",
     display: "inline-block",
     height: "100%",
     marginRight: "22px",
     display: "flex",
     alignItems: "center",  // Center the content vertically
     padding: '0 10px',  // Add padding for better appearance
   }} href='/'
   onMouseEnter={(e) => e.target.style.color = "#00A49B"} // Change color on hover
    onMouseLeave={(e) => e.target.style.color = "#01635c"} 
   >الرئيسية</a>
  <a
    onMouseEnter={(e)=>{handleMouseEnter(); e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{handleMouseLeave(); e.target.style.color = "#01635c"}}
    style={{
      textDecoration: "none",
      color: "#01635c",
      marginBottom: "0px",
      fontSize: "15px",
      // fontWeight: "bold",
      display: "inline-block",
      height: "100%",
      marginRight: "22px",
      display: "flex",
      alignItems: "center",  // Center the content vertically
      padding: '0 10px',  // Add padding for better appearance
    }}
    className='navbar-option'
  >
    التصنيفات <BiChevronDown className='chevron' style={{marginRight:"2px"}} />
  </a>
  <a style={{ textDecoration: "none", color: "#01635c", fontSize: "15px", display: "block", marginRight: "22px" }}    onMouseEnter={(e) => e.target.style.color = "#00A49B"} // Change color on hover
    onMouseLeave={(e) => e.target.style.color = "#01635c"}  href='/contact_us'>تواصل معنا</a>
    <div
    style={{
      position: 'absolute',
      top: isDropdownVisible ? '70%' : '60%', // Adjusts top for sliding down
      right: '150px',
      backgroundColor: 'white',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      zIndex: 1000,
      opacity: isDropdownVisible ? 1 : 0, // Smooth fade
      pointerEvents: isDropdownVisible ? 'auto' : 'none', // Prevent interaction when hidden
      transition: 'top 0.1s ease, opacity 0.2s ease', // Smooth transitions for top and opacity
    }}
    
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{display:"flex", flexDirection: "column", textAlign:"right", direction: "rtl"}}>
  <a onClick={()=>{
    handleMouseLeave();
    // Clear all search parameters
    const newSearchParams = new URLSearchParams();
    // Set the category parameter to the desired value
    newSearchParams.set('category', "ساعات");
    // Navigate to the updated URL
    navigate(`/products?${newSearchParams.toString()}`);
  }} className='desktop-dropdown-item' style={{direction: "rtl", cursor: "pointer", width:"100%", marginLeft:"64px", padding: "8px 8px 8px 32px", borderTopLeftRadius: "4px", borderTopRightRadius :"4px", color:"#01635c"}}>
    <PiWatch size={20} style={{ marginLeft:"8px", color:"#00C8B8" }} />
    ساعات
    </a>
  <a onClick={()=>{
    handleMouseLeave();
    // Clear all search parameters
    const newSearchParams = new URLSearchParams();
    // Set the category parameter to the desired value
    newSearchParams.set('category', "سبح");
    // Navigate to the updated URL
    navigate(`/products?${newSearchParams.toString()}`);
  }} className='desktop-dropdown-item' style={{direction: "rtl", cursor: "pointer", width:"100%", marginLeft:"32px", padding: "8px 8px 8px 32px", borderTopLeftRadius: "4px", borderTopRightRadius :"4px", color:"#01635c"}}>
    <img src={SebhahIcon} alt="" width="20" height="20" style={{marginLeft:"8px"}} />

    سبح
    </a>
  <a onClick={()=>{
    handleMouseLeave();
    // Clear all search parameters
    const newSearchParams = new URLSearchParams();
    // Set the category parameter to the desired value
    newSearchParams.set('category', "اقلام");
    // Navigate to the updated URL
    navigate(`/products?${newSearchParams.toString()}`);
  }} className='desktop-dropdown-item' style={{direction: "rtl", cursor: "pointer", width:"100%", marginLeft:"32px", padding: "8px 8px 8px 32px", borderTopLeftRadius: "4px", borderTopRightRadius :"4px", color:"#01635c"}}>
      <LiaPenAltSolid size={20} style={{ marginLeft:"8px", color:"#00C8B8" }} />

    أقلام
    </a>
  <a onClick={()=>{
    handleMouseLeave();
    // Clear all search parameters
    const newSearchParams = new URLSearchParams();
    // Set the category parameter to the desired value
    newSearchParams.set('category', "ابواك");
    // Navigate to the updated URL
    navigate(`/products?${newSearchParams.toString()}`);
  }} className='desktop-dropdown-item' style={{direction: "rtl", cursor: "pointer", width:"100%", marginLeft:"32px", padding: "8px 8px 8px 32px", borderBottomLeftRadius: "4px", borderBottomRightRadius :"4px", color:"#01635c"}}>
    <IoWalletOutline size={20} style={{ marginLeft:"8px", color:"#00C8B8" }} />

    أبواك
    </a>
  <a onClick={()=>{
    handleMouseLeave();
    // Clear all search parameters
    const newSearchParams = new URLSearchParams();
    // Set the category parameter to the desired value
    newSearchParams.set('category', "حقائب نسائية");
    // Navigate to the updated URL
    navigate(`/products?${newSearchParams.toString()}`);
  }} className='desktop-dropdown-item' style={{direction: "rtl", cursor: "pointer", width:"100%", marginLeft:"32px", padding: "8px 8px 8px 32px", borderBottomLeftRadius: "4px", borderBottomRightRadius :"4px", color:"#01635c"}}>
    <RiShoppingBagLine size={20} style={{ marginLeft:"8px", color:"#00C8B8" }} />

    حقائب نسائية
    </a>
  <a onClick={()=>{
    handleMouseLeave();
    // Clear all search parameters
    const newSearchParams = new URLSearchParams();
    // Set the category parameter to the desired value
    newSearchParams.set('category', "لوح سيارات");
    // Navigate to the updated URL
    navigate(`/products?${newSearchParams.toString()}`);
  }} className='desktop-dropdown-item' style={{direction: "rtl", cursor: "pointer", width:"100%", marginLeft:"32px", padding: "8px 8px 8px 32px", borderBottomLeftRadius: "4px", borderBottomRightRadius :"4px", color:"#01635c"}}>
    <img src={carPlateIcon} style={{width:"20px", height:"20px", marginLeft:"8px"}} />

    لوح سيارات
    </a>
  <a onClick={()=>{
    handleMouseLeave();
    // Clear all search parameters
    const newSearchParams = new URLSearchParams();
    // Set the category parameter to the desired value
    newSearchParams.set('category', "ارقام جوالات");
    // Navigate to the updated URL
    navigate(`/products?${newSearchParams.toString()}`);
  }} className='desktop-dropdown-item' style={{direction: "rtl", cursor: "pointer", width:"100%", marginLeft:"32px", padding: "8px 8px 8px 16px", borderBottomLeftRadius: "4px", borderBottomRightRadius :"4px", color:"#01635c"}}>
    <img src={phoneNumberIcon} alt="" width="20" height="20" style={{marginLeft:"8px", marginBottom:"4  px"}} />
    
    {/* <FaPhone size={17} style={{ marginLeft:"4px", color:"#00C8B8", marginBottom:"4px" }} /> */}

    أرقام جوالات 
    </a>

      </div>
    </div>
</div>




     <div style={{
      width: "40%",
      height: "100%",
      display: "flex",
      flexDirection:"row",
      justifyContent: "start",
      position: "relative",
      alignItems: "center",
      marginRight:"auto",

    }}>

      <div style={{position:"relative", width:"70%"}}>
      <input
        style={{
          backgroundColor: "#fcfcfc",
          outline: "none",
          border: "1px solid #ededed",
          borderRadius: "4px",
          height: "30px",
          fontSize: "13px",
          width: "100%",
          paddingRight:"8px",
          paddingLeft: "27px", // Padding left to accommodate the icon,
        }}
        ref={desktopSearchRef}
        onChange={(e) => {
          const keys = Array.from(searchParams.keys());
          keys.forEach(key => searchParams.delete(key));
          searchParams.set('search', e.target.value.trim());
          // setSearchTerm(e.target.value)
          // setSearchParams(`?${searchParams}`);
        }}
        onKeyDown={
          (e) => {
            if (e.key === 'Enter') {
              // const keys = Array.from(searchParams.keys());
              // keys.forEach(key => searchParams.delete(key));
              setSearchParams(`?${searchParams}`);
              if (desktopSearchRef.current.value.trim().length === 0) {
                // navigate("/");
                desktopSearchRef.current.value = "";
              } else {
                navigate(`/products?${searchParams}`)
                // desktopSearchRef.current.value = "";
              }
            }
          }
        }
        placeholder='البحث عن منتج'
      />

      <div style={{ position: "absolute", left: "2%", top: "43%", transform: "translateY(-50%)" }}>
        <FiSearch style={{ color: "#ccc", fontSize: "18px" , transform: 'rotate(90deg)'}}/>
      </div>
      </div>

      <div style={{width:"20%", display:"flex", marginRight:"auto", justifyContent:"space-between", alignItems:"center"}}>
        
        <div style={{position:"relative"}}

        >

       <a 
       style={{ 
        textDecoration: "none", 
        color: "#01635c", 
        display:"flex", 
        flexDirection: "column",
        cursor:"pointer",
        }}>
        <IoNotificationsOutline size={22} 
                onMouseEnter={(e) => {handleMouseEnterNotifications(); e.target.style.color = "#00A49B"}} // Change color on hover
                onMouseLeave={(e) => {handleMouseLeaveNotifications(); e.target.style.color = "#01635c"}} 
         />
        
        </a>
        {
            numNotifications > 0 && (
        <div 
        onMouseEnter={(e) => {handleMouseEnterNotifications(); e.target.style.color = "white"}} // Change color on hover
        
        style={{backgroundColor:"red", position:"absolute", top: -4, left: -4, width:"15px", height:"15px" ,borderRadius:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}><p style={{marginBottom:"0px", color:"white", fontSize:"11px"}}>{numNotifications}</p>
        </div>
            )
          }
        </div>
        
            <div
style={{
  position: 'absolute',
  top: isNotificationsVisible ? '65%' : '0%', // Move out of view initially
  left: '0%', // Keep horizontal position consistent
  backgroundColor: 'white',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
  borderRadius: '16px',
  zIndex: 1000,
  width: isNotificationsVisible ? '25vw' : '0', // Expand from 0 to 25vw
  height: isNotificationsVisible ? (notifications.length > 0 ? '50vh' : 'fit-content') : '0', // Expand from 0 to 50vh
  maxHeight: '50vh', // Limit the height to 50vh
  opacity: isNotificationsVisible ? 1 : 0, // Fade in/out
  pointerEvents: isNotificationsVisible ? 'auto' : 'none', // Enable interaction when visible
  transition: 'top 0.5s ease, width 0.5s ease, height 0.5s ease, opacity 0.2s ease', // Smooth transitions
  overflowY: isNotificationsVisible ? 'auto' : "initial", // Show scrollbar if needed
  display: 'flex',
  flexDirection: 'column',
}}




    
      onMouseEnter={handleMouseEnterNotifications}
      onMouseLeave={handleMouseLeaveNotifications}
    >
      <div style={{display:"flex", flexDirection: "column", textAlign:"right", direction: "rtl", position:"relative"}}>
        {
  notificationsLoading ? (
    <div
      style={{
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "50vh",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: notificationsLoading ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
      }}
    >
      <Oval
        height={"30px"}
        width={"30px"}
        color={"white"}
        wrapperClass=""
        visible={true}
        wrapperStyle={{ display: "block" }}
        ariaLabel="oval-loading"
        secondaryColor="#00C8B8"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  ) : notifications.length > 0 ? (
    <>
      <div
        style={{
          position: "sticky",
          top: 0,
          padding: "12px",
          borderBottom: '1px solid #ddd',
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 1000,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <div style={{ display: "flex" }}>
          <h6 style={{ fontWeight: "bold", color: "#00A49B", textAlign: "right", marginBottom: "0px" }}>
            الإشعارات
          </h6>
          <div style={{
            backgroundColor: "#00C8B8",
            borderRadius: "50px",
            width: "30px",
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "6px"
          }}>
            <p style={{ marginBottom: "0px", margin: "auto", color: "white", fontSize: "12px" }}>
              {notifications.length}
            </p>
          </div>
        </div>
        <div
          style={{ marginBottom: "6px", cursor: "pointer" }}
          onMouseEnter={(e) => { e.target.style.color = "#00A49B"; }}
          onMouseLeave={(e) => { e.target.style.color = "#01635c"; }}
          onClick={readNotifications}
        >
          <IoCheckmarkDoneOutline color='#01635c' size={20} />
        </div>
      </div>

      <div style={{ padding: "8px 8px 16px 8px" }}>
        {notifications.map((notification) => {
          const { type, timestamp, title, message, relatedId, actionUrl, isActionable, id } = notification;
          let icon = null;

          const date = timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;
          const timeAgo = date ? formatDistanceToNow(date, { addSuffix: true, locale: arLocale }) : '';

          switch (type) {
            case "Message":
              icon = <AiOutlineMessage size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            case "Offer":
              icon = <LuBadgePercent size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            case "Purchase":
              icon = <BiSolidPurchaseTagAlt size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            case "Alert":
              icon = <AiOutlineAlert size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            case "Rating":
              icon = <FaStar size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            case "Sell":
              icon = <p style={{ marginLeft: "8px", color: "#00C8B8", fontSize: "20px" }}>🥳</p>;
              break;
            case "Comment":
              icon = <AiOutlineComment size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            default:
              icon = <AiOutlineNotification size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
          }

          return (
            <div key={id} style={{ display: "flex", padding: "8px 16px 16px 4px", cursor: "pointer" }}
              onClick={() => {
                if (type === "Message") {
                  setActiveChatId(relatedId);
                  setChatIsOpen(true);
                  setDrawerOpen(true);
                } else if (isActionable) {
                  handleMouseLeaveNotifications();
                  navigate(actionUrl);
                }
              }}
            >
              {icon}
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                  <p style={{ fontWeight: 500, color: "#01635c", marginBottom: "4px" }}>{title}</p>
                  <p style={{
                    marginBottom: "0px",
                    fontSize: "14px",
                    direction: "ltr",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexShrink: 0
                  }}>{timeAgo}</p>
                </div>
                <p style={{
                  color: "#01635c",
                  wordBreak: "break-word",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2
                }}>
                  {message}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '20%' }}>
      <Lottie animationData={NoNotificatoinsAnim} loop={true} style={{ height: "10%", marginTop: "auto", maxHeight: "40vh", width: "50%" }} />
      <p style={{ marginBottom: '8px', textAlign: 'center', fontWeight: '500', whiteSpace: 'nowrap', overflow: 'hidden', color: "#01635c" }}>
        لا توجد لديك إشعارات
      </p>
    </div>
  )
}


      </div>
    </div>
       <a style={{ textDecoration: "none", color: "#01635c",display:"flex", flexDirection:"column"}} href='/add_post'>
        <IoAddCircleOutline size={22}   onMouseEnter={(e) => e.target.style.color = "#00A49B"} // Change color on hover
    onMouseLeave={(e) => e.target.style.color = "#01635c"}  /></a>
       <a style={{ textDecoration: "none", color: "#01635c", display:"flex", flexDirection:"column"}} href='/my_account'>
       <div style={{position:"relative"}}>
            <BsPerson size={22} style={{marginBottom:"2px"}}   onMouseEnter={(e) => e.target.style.color = "#00A49B"}
    onMouseLeave={(e) => e.target.style.color = "#01635c"} />
    </div>
    
    </a>
    </div>
    </div>
    </div>

    </div>



    <nav id="Cnavbar-phone">
  <div id='phone-navbar-logo-div'
  >
    <RxHamburgerMenu
      id='phone-navbar-hamburger-icon'
      size={32}
      onClick={() => {
        setOpenDrawer(true)
      }}
    />

    <a href="/" style={{marginRight:"4px", margin:"0px 8px"}}>
      <img id="logo-phone" src={logo2} alt='' />
    </a>


    <div style={{
      width: '100%',
      textAlign: "center",
      height: '100%',
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
      display:"flex",
      padding:"0px 0px 0px 8px"
    }}>
      <input
        type="search"
        ref={searchRef}
        onChange={(e) => {
          const keys = Array.from(searchParams.keys());
          keys.forEach(key => searchParams.delete(key));
          searchParams.set('search', e.target.value.trim());
          // setSearchTerm(e.target.value)
          // setSearchParams(`?${searchParams}`);
        }}
        placeholder="البحث عن منتج"
        id='phone-navbar-search-input'
        style={{backgroundColor:"white", touchAction:"unset"}}
        
        onKeyDown={
          (e) => {
            if (e.key === 'Enter') {
              // const keys = Array.from(searchParams.keys());
              // keys.forEach(key => searchParams.delete(key));

              setSearchParams(`?${searchParams}`);
              if (searchRef.current.value.trim().length === 0) {
                // navigate("/");
                searchRef.current.value = "";
              } else {
                navigate(`/products?${searchParams}`)
              }
            }
          }
        }
        // enterKeyHint="go"
      />

      <div style={{ position: 'absolute', top: '27%', left: '4vw', color: "#ccc" }}>
        <FiSearch size={17} style={{transform: 'rotate(90deg)'}} />
      </div>
    </div>

    <div style={{
    height: "100%",
    marginLeft: "16px",
    display: "flex",
    // justifyContent: "space-between",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight:"auto"
    }}>

       {/* <Link to="/my_account" color='black' style={{color: "black"}}>
        <MdOutlinePerson size={22}/>
      </Link> */}
       {/* <Link to="/contact_us" color='#444' style={{color: "black"}}>
        <BiSupport size={22} color='#00C8B8'/>
      </Link> */}

        <div style={{position:"relative"}}>
        <a 
       style={{ 
        textDecoration: "none", 
        color: "#01635c", 
        display:"flex", 
        flexDirection: "column",
        cursor:"pointer",
        }}
        onClick={()=>{
          if(isNotificationsVisible){
            handleMouseLeaveNotifications(); 
          }
          else
            handleMouseEnterNotifications()
        }}
        >
        <IoNotificationsOutline size={22} color='#00C8B8'  /></a>
                {
            numNotifications > 0 && (
        <div 
        onMouseEnter={(e) => {handleMouseEnterNotifications(); e.target.style.color = "white"}} // Change color on hover
        
        style={{backgroundColor:"red", position:"absolute", top: -4, left: -4, width:"15px", height:"15px" ,borderRadius:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}><p style={{marginBottom:"0px", color:"white", fontSize:"11px"}}>{numNotifications}</p>
        </div>
            )
          }
        </div>
        <div
style={{
  position: 'absolute',
  top: isNotificationsVisible ? '65%' : '0%', // Move out of view initially
  left: '5%', // Keep horizontal position consistent
  backgroundColor: 'white',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
  borderRadius: '16px',
  zIndex: 1002,
  width: isNotificationsVisible ? '90vw' : '0', // Expand from 0 to 25vw
  height: isNotificationsVisible ? (notifications.length > 0 ? '80vh' : 'fit-content') : '0', // Expand from 0 to 50vh
  maxHeight: '80vh', // Limit the height to 50vh
  opacity: isNotificationsVisible ? 1 : 0, // Fade in/out
  pointerEvents: isNotificationsVisible ? 'auto' : 'none', // Enable interaction when visible
  transition: 'top 0.5s ease, width 0.5s ease, height 0.5s ease, opacity 0.2s ease', // Smooth transitions
  overflowY: isNotificationsVisible ? 'auto' : "initial", // Show scrollbar if needed
  display: 'flex',
  flexDirection: 'column',
}}




    
      onMouseEnter={handleMouseEnterNotifications}
      onMouseLeave={handleMouseLeaveNotifications}
    >
      <div style={{display:"flex", flexDirection: "column", textAlign:"right", direction: "rtl", position:"relative"}}>
        {
          !notificationsLoading && (
            <div style={{
              position: "sticky", // Use sticky to keep it fixed within the orange container
              top: 0, // Fixed to the top of the container
              padding: "12px",
              borderBottom: '1px solid #ddd',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              zIndex: 1000,
              width: "100%",
              backgroundColor:"white"
                  }}>
                    <div style={{
                    display:"flex",
                    }}>
                    <h6 style={{ fontWeight: "bold", color:"#00A49B", textAlign:"right", marginBottom:"0px"}}>الإشعارات</h6>
                  <div style={{backgroundColor:"#00C8B8", borderRadius:"50px", width:"30px", height:"20px", padding:"0px", display:"flex", justifyContent:"center", alignItems:"center", marginRight:"6px"}}>
                  <p style={{marginBottom:"0px", margin:"auto", color:"white", fontSize:"12px"}}>{notifications.length}</p>
          
                  </div>
                    </div>
          
                    <div style={{marginBottom:"6px", cursor:"pointer"}}
                  >
                    <div style={{display:"flex"}}
                    >
                    <IoCheckmarkDoneOutline 
                    onClick={readNotifications}
                    color='#01635c' size={22}/>
                    <IoCloseCircleOutline onClick={handleMouseLeaveNotifications} style={{marginRight:"8px", color:"#F00057"}} color='#F00057' size={22}/>

                      </div>
          
          
                  </div>
          
                  </div>
          )
        }

{
  notificationsLoading ? (
<div
  style={{
    position: "relative", // This makes the div float on top
    top: 0,
    left: 0,
    width: "100%", // Stretches to the parent's width
    height: "50vh", // Stretches to the parent's height
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: notificationsLoading ? 1 : 0, // Conditionally set opacity
    transition: "opacity 0.5s ease-in-out", // Smooth transition
  }}
>
  <Oval
    height={"30px"}
    width={"30px"}
    color={"white"}
    wrapperClass=""
    visible={true}
    wrapperStyle={{ display: "block" }}
    ariaLabel="oval-loading"
    secondaryColor="#00C8B8"
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
</div>

  ) : (
<>
  {
    notifications.length > 0 ? (
      <div style={{ padding: "16px 8px" }}>
        {notifications.map((notification) => {
          const { type, timestamp, title, message, relatedId, actionUrl, isActionable, id } = notification;
          let icon = null;

          const date = timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;
          const timeAgo = date ? formatDistanceToNow(date, { addSuffix: true, locale: arLocale }) : '';

          switch (type) {
            case "Message":
              icon = <AiOutlineMessage size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            case "Offer":
              icon = <LuBadgePercent size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            case "Purchase":
              icon = <BiSolidPurchaseTagAlt size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            case "Alert":
              icon = <AiOutlineAlert size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            case "Rating":
              icon = <FaStar size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            case "Sell":
              icon = <p style={{ marginLeft: "8px", color: "#00C8B8", fontSize: "20px" }}>🥳</p>;
              break;
            case "Comment":
              icon = <AiOutlineComment size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
              break;
            default:
              icon = <AiOutlineNotification size={20} style={{ marginLeft: "8px", color: "#00C8B8" }} />;
          }

          return (
            <div key={id} style={{ display: "flex", padding: "8px 16px 16px 4px", cursor: "pointer" }}
              onClick={() => {
                if (type === "Message") {
                  setActiveChatId(relatedId);
                  setChatIsOpen(true);
                  setDrawerOpen(true);
                } else if (isActionable) {
                  handleMouseLeaveNotifications();
                  navigate(actionUrl);
                }
              }}
            >
              {icon}
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                  <p style={{ fontWeight: 500, color: "#01635c", marginBottom: "4px" }}>{title}</p>
                  <p style={{
                    marginBottom: "0px",
                    fontSize: "14px",
                    direction: "ltr",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexShrink: 0
                  }}>{timeAgo}</p>
                </div>
                <p style={{
                  color: "#01635c",
                  wordBreak: "break-word",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2
                }}>
                  {message}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
        <Lottie animationData={NoNotificatoinsAnim} loop={true} style={{ height: "70%", marginTop: "auto", width: "70%", marginBottom:"auto"}} />
        <p style={{ marginBottom: '8px', textAlign: 'center', fontWeight: '500', whiteSpace: 'nowrap', overflow: 'hidden', color: "#01635c",  marginBottom:"auto" }}>لا توجد لديك إشعارات</p>
      </div>
    )
  }
</>

  )
}


      </div>
    </div>
        
     </div>
  </div>
</nav>

     
    <nav className="bottom-navbar">

      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive ? 'Cnav-link-active' : 'Cnav-link-inActive'
        }
      >
      <GoHomeFill id="navbar-icons" size={24}/>
              <span id="Cnav-link-active">الرئيسية</span>
      </NavLink>

      <NavLink
        to="/my_bids" 
        className={({ isActive }) =>
          isActive ? 'Cnav-link-active' : 'Cnav-link-inActive'
        }
      >     
      <PiGavelFill id="navbar-icons" size={24}/>
        <span id="Cnav-link-active">مزايداتي</span>
      </NavLink>


      <NavLink
        to="/add_post"
        className={({ isActive }) =>
          isActive ? 'Cnav-link-active' : 'Cnav-link-inActive'
        }
      >
      <FaPlus id="navbar-icons" style={{}} size={24}/>
              <span id="Cnav-link-active">إضافة منتج</span>
      </NavLink>
      <NavLink
        to="/my_account"
        className={({ isActive }) =>
          isActive ? 'Cnav-link-active' : 'Cnav-link-inActive'
        }
      >
        <div style={{position:"relative"}}>

      <BsFillPersonFill id="navbar-icons" size={24}/>
      <span id="Cnav-link-active">حسابي</span>
        </div>
      </NavLink>
      <NavLink
        to="/more"
        className={({ isActive }) =>
          isActive ? 'Cnav-link-active' : 'Cnav-link-inActive'
        }
      >
      <BsThreeDotsVertical id="navbar-icons" size={24}/>
              <span id="Cnav-link-active">المزيد</span>
      </NavLink>

      <a href="/">
        <img id="logo" src = {logo2} alt=''/>
      </a>

      {/* <Dropdown id="dropdown" 
      >
      <Dropdown.Toggle id="dropdown-toggle">
          التصنيفات 
      </Dropdown.Toggle>

      <Dropdown.Menu id="dropdown-menu">
        <a id='category-header'>الكترونيات</a>
        <Dropdown.Item href="/phones">جوالات</Dropdown.Item>
        <Dropdown.Item href="/screens">شاشات</Dropdown.Item>
        <Dropdown.Item href="/speakers">سماعات</Dropdown.Item>
        <Dropdown.Item href="/tablets">اجهزة لوحية</Dropdown.Item>
        <Dropdown.Item href="/cameras">كاميرات</Dropdown.Item>
        <Dropdown.Item href="/printers">طابعات</Dropdown.Item>
        <Dropdown.Item href="/fridgerators">ثلاجات</Dropdown.Item>
        <Dropdown.Item href="/air-conditioners">مكيفات</Dropdown.Item>
        <Dropdown.Item href="/pc-parts">قطع كمبيوتر</Dropdown.Item>
        <DropdownDivider/>
        <a id='category-header'>أثاث</a>
        <Dropdown.Item href="/chairs">كراسي</Dropdown.Item>
        <Dropdown.Item href="/sofas">كنب</Dropdown.Item>
        <Dropdown.Item href="/tables">طاولات</Dropdown.Item>
        <Dropdown.Item href="/shelves">أرفف</Dropdown.Item>
        <Dropdown.Item href="/drawers-and-wardrobes">دواليب و أدراج</Dropdown.Item>
        <Dropdown.Item href="/paintings">لوحات</Dropdown.Item>
        <DropdownDivider/>
        <a id='category-header'>قطع غيار</a>
        <Dropdown.Item href="/auto-parts">قطع غيار سيارات</Dropdown.Item>
        <DropdownDivider/>
        <Dropdown.Item href="/music-instruments">الات موسيقية</Dropdown.Item>

      </Dropdown.Menu>
    </Dropdown> */}

{/* 
<div id='navbar-search-input-div'>
  <input
    ref={searchRef}
    onChange={(e) => {
      searchParams.set('search', e.target.value.trim());
    }}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        searchParams.delete("category")
        searchParams.delete("subCategory")
        setSearchParams(`?${searchParams}`);
        if (searchRef.current.value.trim().length === 0) {
          searchRef.current.value = "";
        } else {
          navigate(`/products?${searchParams}`)
        }
      }
    }}
    placeholder='ابحث هنا'
    id='navbar-search-input'
  />

  <div>
    <FiSearch id='navbar-search-icon' size={20} />
  </div>
</div>


    <Button 
    onClick={()=>{
      searchParams.delete("category")
      searchParams.delete("subCategory")
        setSearchParams(`?${searchParams}`);
        if (searchRef.current.value.trim().length === 0) {
          // navigate("/");
          searchRef.current.value = "";
        } else {
          navigate(`/products?${searchParams}`)
        }
    }}
    id="search-button" style={{
      width: "90px",
      height: "40px",
      fontSize: "16px",
      marginRight: "8px",
      borderRadius: "4px"
    }} ><span>البحث</span></Button>  

  <div 
  id="left-icons-div"
  >

      <Link
    to="/my_bids"
  >
<BsListCheck className="floating-icons" size={24}/>
  </Link>



      <Link
    to="/add_post"
  >
  <FaPlus 
  className="floating-icons" size={24}/>
  </Link>


      <Link
    to="/my_account"
  >
  <BsPersonCircle 
  className="floating-icons" size={24}/>
  </Link>

<Link to="contact_us">

  <BiSupport 
  className="floating-icons" size={24}/>
  </Link>
  </div>
     */}

    </nav>
    

{/*     
    <nav id="Cnavbar-phone">
  <div id='phone-navbar-logo-div'
  >
    <RxHamburgerMenu
      id='phone-navbar-hamburger-icon'
      size={24}
      onClick={() => {
        setOpenDrawer(true)
      }}
    />

    <a href="/">
      <img id="logo-phone" src={logo2} alt='' />
    </a>


    <div style={{
      width: 'fit-content',
      textAlign: "center",
      height: '100%',
      position: "relative",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <input
        type="search"
        ref={searchRef}
        onChange={(e) => {
          searchParams.delete("category")
          searchParams.delete("subCategory")
          searchParams.set('search', e.target.value.trim());
          // setSearchTerm(e.target.value)
          // setSearchParams(`?${searchParams}`);
        }}
        placeholder="البحث"
        id='phone-navbar-search-input'
        style={{backgroundColor:"white"}}
        
        onKeyDown={
          (e) => {
            if (e.key === 'Enter') {
              searchParams.delete("category")
              searchParams.delete("subCategory")
              setSearchParams(`?${searchParams}`);
              if (searchRef.current.value.trim().length === 0) {
                // navigate("/");
                searchRef.current.value = "";
              } else {
                navigate(`/products?${searchParams}`)
              }
            }
          }
        }
        // enterKeyHint="go"
      />

      <div style={{ position: 'absolute', top: '45%', right: '32px', transform: 'translateY(-50%)', color: "#ccc" }}>
        <FiSearch size={17} />
      </div>
    </div>

    <div style={{
    height: "100%",
    width: "15%",
    marginLeft: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    }}>

      {/* <Link to="/my_account" color='black' style={{color: "black"}}>
        <MdOutlinePerson size={24}/>
      </Link> */}
      {/* <Link to="/contact_us" color='black' style={{color: "black"}}>
        <BiSupport size={24}/>
      </Link> */}
        
    {/* </div>
  </div>
</nav>
 */} 



{/* <Drawer 
      anchor='right' open={openSearch} onClose={()=>{
        setOpenSearch(false);
      }}>
        <Box
        width={"80vw"}
        > 
        <h2>home</h2>

        </Box>
      </Drawer> */}

      <Drawer 
      
      anchor='right' open={openDrawer} onClose={()=>{
        setOpenDrawer(false);
      }}>
        <Box
        width={"80vw"}
        >
          <div style={{
            direction: "rtl",
            height: "100vh",
            width: "100%", 
            paddingRight: "16px"


            }}>
<div
  style={{
    height: "10%", // Set the height to 100% for the entire height of the parent container
    display: "flex", // Use flex display
    alignItems: "center",
    width:"fit-content",
    paddingLeft:"16px"
  }}
  onClick={()=>{
    const keys = Array.from(searchParams.keys());
    keys.forEach(key => searchParams.delete(key));

      navigate(`/`)
      setOpenDrawer(false);
  }}
>
  <img src = {logo2} style={{height: "100%", window: "50px", height: "50px"}} alt=''/>
  <p style={{marginBottom: "0px", marginRight: "8px", fontSize: "22px", color: "#00A49B", fontWeight:"500"}}>بيع</p>
</div>

            <Divider 
            style={{height:"4px"}}
            />
            <br/>
            <div style={{display: "flex", flexDirection:"column"}}>

              <p style={{marginBottom:"8px", fontSize: "17px", color: "#01635c", fontWeight:"500"}}>التصفح السريع</p>


              <div style={{
              display: "flex",
              alignItems: "center", // Center items vertically
              padding: "8px 0px 8px 16px",
              cursor: "pointer",
              width: "50%",
              }}
              onClick={
                () => {
                  const keys = Array.from(searchParams.keys());
                  keys.forEach(key => searchParams.delete(key));

                    searchParams.set('category', 'ساعات');
                    navigate(`/products?${searchParams.toString()}`)
                    setOpenDrawer(false);
            }}
              >
  <PiWatch size={20} style={{ size: "24px" }} color='#00C8B8' />
  <p
  
    style={{
      textDecoration: "none",
      color: "#01635c",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "7px"
    }}
  >
    ساعات
  </p>
</div>


<div style={{
  display: "flex",
  alignItems: "center", // Center items vertically
  padding: "8px 0px 8px 16px",
  cursor: "pointer",
  width: "50%",

}}
onClick={
  () => {
    const keys = Array.from(searchParams.keys());
    keys.forEach(key => searchParams.delete(key));

      searchParams.set('category', 'سبح');
      navigate(`/products?${searchParams.toString()}`)
      setOpenDrawer(false);
}}
>
<img src={SebhahIcon} alt="" width="20" height="20" />
  <p
    style={{
      textDecoration: "none",
      color: "#01635c",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "8px"
    }}
  >
    سبح
  </p>
</div>

<div style={{
  display: "flex",
  alignItems: "center", // Center items vertically
  padding: "8px 0px 8px 16px",
  cursor: "pointer",
  width: "50%",

}}
onClick={
  () => {
    const keys = Array.from(searchParams.keys());
    keys.forEach(key => searchParams.delete(key));

      searchParams.set('category', 'اقلام');
      navigate(`/products?${searchParams.toString()}`)
      setOpenDrawer(false);
}}
>
  <LiaPenAltSolid size={20} style={{ size: "24px" }} color='#00C8B8' />
  <p
    style={{
      textDecoration: "none",
      color: "#01635c",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "8px"
    }}
  >
    أقلام
  </p>
</div>


<div style={{
  display: "flex",
  alignItems: "center", // Center items vertically
  padding: "8px 0px 8px 16px",
  cursor: "pointer",
  width: "50%",

}}
onClick={
  () => {
    const keys = Array.from(searchParams.keys());
    keys.forEach(key => searchParams.delete(key));

      searchParams.set('category', 'ابواك');
      navigate(`/products?${searchParams.toString()}`)
      setOpenDrawer(false);
}}
>
  <IoWalletOutline size={20} color='#00C8B8' />
  <p
    style={{
      textDecoration: "none",
      color: "#01635c",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "8px"
    }}
  >
    أبواك
  </p>
</div>

<div style={{
  display: "flex",
  alignItems: "center", // Center items vertically
  padding: "8px 0px 8px 16px",
  cursor: "pointer",
  width: "50%",

}}
onClick={
  () => {
    const keys = Array.from(searchParams.keys());
    keys.forEach(key => searchParams.delete(key));

      searchParams.set('category', 'حقائب نسائية');
      navigate(`/products?${searchParams.toString()}`)
      setOpenDrawer(false);
}}
>
  <RiShoppingBagLine size={20} style={{ size: "24px" }} color='#00C8B8' />
  <p
    style={{
      textDecoration: "none",
      color: "#01635c",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "8px"
    }}
  >
    حقائب نسائية 
  </p>
</div>
<div style={{
  display: "flex",
  alignItems: "center", // Center items vertically
  padding: "8px 0px 8px 16px",
  cursor: "pointer",
  width: "50%",

}}
onClick={
  () => {
    const keys = Array.from(searchParams.keys());
    keys.forEach(key => searchParams.delete(key));
      searchParams.set('category', 'لوح سيارات');
      navigate(`/products?${searchParams.toString()}`)
      setOpenDrawer(false);
}}
>
  {/* <IoWalletOutline size={20} style={{ size: "24px" }} color='#F00057' /> */}
<img src={carPlateIcon} alt="" width="20" height="20" style={{objectFit:"contain"}}/>


  <p
    style={{
      textDecoration: "none",
      color: "#01635c",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "8px"
    }}
  >
    لوح سيارات
  </p>
</div>

<div style={{
  display: "flex",
  alignItems: "center", // Center items vertically
  padding: "8px 0px 8px 16px",
  cursor: "pointer",
  width: "50%",

}}
onClick={
  () => {
    const keys = Array.from(searchParams.keys());
    keys.forEach(key => searchParams.delete(key));
      searchParams.set('category', 'ارقام جوالات');
      navigate(`/products?${searchParams.toString()}`)
      setOpenDrawer(false);
}}
>
<img src={phoneNumberIcon} alt="" width="20" height="20" style={{marginLeft:"4px"}} />

  {/* <FaPhone size={17}color='#00C8B8' /> */}
  <p
    style={{
      textDecoration: "none",
      color: "#01635c",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "8px"
    }}
  >
    أرقام جوالات 
  </p>
</div>



<Divider 
            style={{height:"4px", marginTop: "16px"}}
            />
                <div style={{display: 'block'}}>

                {/* <a 
                href="/electronics/phones"
                id='drawer-option'
                >جوالات</a>
                <a 
                href="/electronics/screens"
                id='drawer-option'
                >شاشات</a>
                <a 
                href="/electronics/speakers"
                id='drawer-option'
                >سماعات</a>
                <a 
                href="/electronics/tablets"
                id='drawer-option'
                >اجهزة لوحية</a>
                <a 
                href="/electronics/cameras"
                id='drawer-option'
                >كاميرات</a>
                <a 
                href="/electronics/printers"
                id='drawer-option'
                >طابعات</a>
                <a 
                href="/electronics/fridgerators"
                id='drawer-option'
                >ثلاجات</a>
                <a 
                href="/electronics/air-conditioners"
                id='drawer-option'
                >مكيفات</a>
                <a 
                href="/electronics/pc-parts"
                id='drawer-option'
                >قطع كمبيوتر</a> */}
                {/* </div> */}
                {/* <Divider 
            style={{height:"4px"}}
            />
            <a 
              href='/furniture'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.8rem",
                padding:"8px 16px", display: "block"}}>أثاث</a>
                
                <div style={{display: 'block'}}>

                <a 
                href="/furniture/chairs"
                id='drawer-option'
                >كراسي</a>
                <a 
                href="/furniture/sofas"
                id='drawer-option'
                >كنب</a>
                <a 
                href="/furniture/tables"
                id='drawer-option'
                >طاولات</a>
                <a 
                href="/furniture/shelves"
                id='drawer-option'
                >أرفف</a>
                <a 
                href="/furniture/drawers-and-wardrobes"
                id='drawer-option'
                >دواليب و أدراج</a>
                <a 
                href="/furniture/paintings"
                id='drawer-option'
                >لوحات</a>
                </div>
                <Divider 
            style={{height:"4px"}}
            />
            <a 
              href='/car-parts'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.8rem",
                padding:"8px 16px", display: "block"}}>قطع غيار</a>
                
                <div style={{display: 'block'}}>

                <a 
                href='car-parts'
                id='drawer-option'
                >قطع غيار سيارات</a>
                </div> */}
                {/* <Divider 
            style={{height:"4px"}}
            />

                
                <div style={{display: 'block'}}>

                <a 
                id='drawer-option'
                href='music-instruments'
                style={{padding: "0px 16px 16px 32px", fontSize: "1.5rem", display: 'block'}}>الات موسيقية</a>
                </div> */}
             </div>
              </div> 
</div>
         </Box>
       </Drawer> 
    {/* </nav> */}
 </>
  );
}

export default CustomNavbar;