import {React, useState, useEffect, useRef} from "react"; 
import './App.css';
import CustomNavbar from "./components/CustomNavbar";
import ProductsScreen from "./screens/ProductsScreen";
import { BrowserRouter, Routes, Route, Link, Outlet, useParams, router, useNavigate, } from "react-router-dom";
import ElectronicsScreen from './screens/ElectronicsScreen';
import FurnitureScreen from "./screens/FurnitureScreen";
import CarPartsScreen from "./screens/CarPartsScreen";
import PostDetailsScreen from "./screens/PostDetailsScreen";
import AddPostScreen from "./screens/AddPostScreen";
import SignUpScreen from "./screens/SignUpScreen";
import SignInScreen from "./screens/SignInScreen";
import { getAuth, signInAnonymously } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import MyAccountScreen from "./screens/MyAccountScreen";
import MyPostsScreen from "./screens/MyPostsScreen";
import MyPostDetailsScreen from "./screens/MyPostDetailsScreen";
import UserScreen from './screens/UserScreen'
import MyBidsScreen from "./screens/MyBidsScreen";
import LandingScreen from "./screens/LandingScreen";
import SignInOrSignUpScreen from "./screens/SignInOrSignUpScreen";
import logo from './assets/logo2.png'
import payment from './assets/payment_methods.png'
import { Divider } from "@mui/material";
import { IoLogoWhatsapp } from "react-icons/io5";
import Footer from "./components/Footer";
import UserAgreement from "./screens/UserAgreement";
import AboutUsScreen from "./screens/AboutUs";
import ContactUs from "./screens/ContactUs";
import MoreScreen from "./screens/MoreScreen";
import Checkout from "./screens/Checkout";
import MyPurchases from "./screens/MyPurchases";
import ComingSoon from "./screens/ComingSoonScreen";
import TestScreen from "./screens/TestScreen";
import PaymentStatus from "./screens/PaymentStatus";
import LoadingScreen from "./screens/LoadingScreen";
import MySoldProductsScreen from './screens/MySoldProductsScreen'
import MyPurchasedProductsScreen from "./screens/MyPurchasedProductScreen";
import useIsSafari from "./hooks/useIsSafari";
import { IoChatbubbleSharp } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io"; 
import Lottie from 'lottie-react';
import noChatAnim from './assets/animations/NoChatAnim.json'
import { db, auth, storage, enableNetwork, disableNetwork } from './config/firebase'
import { getFirestore, collection, getDocs, onSnapshot, query, where, updateDoc, limit, doc, getDoc, serverTimestamp, arrayUnion, FieldValue, increment, addDoc, orderBy, Timestamp, startAfter, writeBatch } from 'firebase/firestore';
import Drawer from '@mui/material/Drawer';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { GoPaperclip } from "react-icons/go";
import { IoSend } from "react-icons/io5"; 
import { Oval } from 'react-loader-spinner'
import { GoChevronLeft } from "react-icons/go";
import ChatProvider, { useChat } from './hooks/ChatContext';
import MyExpiredBidsScreen from "./screens/MyExpiredBidsScreen";



const App = () => { 
  const isSafari = useIsSafari();
  useEffect(() => {
    if (isSafari) {
      document.body.classList.add('safari');
    } else {
      document.body.classList.remove('safari');
    }
  }, [isSafari]);
  
  // resize rerender
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    
    window.addEventListener('resize', handleResize);
    
    // Initial call to set the state with current window dimensions
    handleResize();
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const [appJsMyId, setAppJsMyId] = useState(null)

  const {setChatIsOpen, setMyId, setChats, setMessages, setUsersInfo, activeChatId, setActiveChatId, drawerOpen, setDrawerOpen, handleListItemClick, setChatLoading, message, totalUnreadMessages, appLoading } = useChat();


  const toggleChat = () => {
    setChatIsOpen(true)
  };







const [isBusiness, setIsBusiness] = useState(false)
const [myFullName, setMyFullName] = useState(false)
const [myCommission, setMyCommission] = useState(false)


useEffect(() => {
  const handleAuthStateChange = async (user) => {
    if (auth.currentUser && !auth.currentUser.isAnonymous) {
      const docRef = doc(db, 'Users', user.uid);
      
      const fetchUserData = async (retry = false) => {
        try {
          // Try to read from cache first
          const cachedDoc = await getDoc(docRef, { source: 'cache' }).catch(() => null);

          if (cachedDoc && cachedDoc.exists()) {
            const userData = cachedDoc.data();
            setUserData(userData);
            setMyId(userData.id);
            setMyFullName(userData.fullName);
            setIsBusiness(userData.isBusiness);
            setAppJsMyId(userData.id);
            setMyCommission(userData.commission)
          }

          // Listen for real-time updates
          const unsubscribeUser = onSnapshot(docRef, (docu) => {
            if (docu.exists()) {
              const userData = docu.data();
              setUserData(userData);
              setMyId(userData.id);
              setMyFullName(userData.fullName);
              setIsBusiness(userData.isBusiness);
              setAppJsMyId(userData.id);
            } else {
              console.error('User data not found');
            }
          });

          return unsubscribeUser; // Return the unsubscribe function

        } catch (error) {
          console.error('Error fetching user data:', error);

          if (error.message.includes('client is offline')) {
            // If the error is because the client is offline, retry after 10 seconds
            console.log('Retrying in 10 seconds...');
            if (!retry) {
              setTimeout(() => fetchUserData(true), 10000); // Retry once after 10 seconds
            }
          }
        }
      };

      // Call fetchUserData initially
      fetchUserData();

    } else {
      // Sign in anonymously if no user is currently signed in
      try {
        await signInAnonymously(auth);
        // console.log('Signed in anonymously');
      } catch (error) {
        console.error('Error signing in anonymously:', error);
      }
      setNotificationsLoading(false)
      setAppJsMyId(null);
      setChatLoading(false); // Ensure loading is false even if there's an error
    }
  };

  const setUserData = (userData) => {
    setMyId(userData.id);
  };

  const unsubscribeAuth = auth.onAuthStateChanged(handleAuthStateChange);

  return () => {
    if (unsubscribeAuth) unsubscribeAuth();
  };
}, []);


  const [notifications, setNotifications] = useState([]);
  const [notificationsLoading, setNotificationsLoading] = useState(true);



  useEffect(() => {
    if(auth.currentUser && !auth.currentUser.isAnonymous){

    // Reference to the Notifications collection
    const notificationsRef = collection(db, 'Notifications');
  
    // Query to get notifications for the current user that are not read, ordered by timestamp
    const q = query(
      notificationsRef,
      where('userId', '==', appJsMyId),
      where('isRead', '==', false),
      orderBy('timestamp', 'desc')
    );
  
    // Set up a snapshot listener for the notifications collection
    const unsubscribe = onSnapshot(q, (snapshot) => {
      // Check if the snapshot is empty
      if (snapshot.empty) {
        // console.log("No notifications found.");
        // Update notifications state with an empty array
        setNotifications([]);
      setNotificationsLoading(false);
      } else {
        // Map snapshot docs to notification objects
        const newNotifications = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Update notifications state
        setNotifications(newNotifications);
        setNotificationsLoading(false);

      }
  
      // Stop loading state after notifications have been set
      // setNotificationsLoading(false);
    }, (error) => {
      // Handle errors if needed
      console.error("Error fetching notifications: ", error);
  
      // Ensure loading state is stopped on error
      setNotificationsLoading(false);
    });
  
    // Cleanup the listener on unmount
    return () => unsubscribe();
  }
  }, [appJsMyId]);

// if(appLoading){
//   return <LoadingScreen/>
// }

	return ( 
    <BrowserRouter>
    {/* <BasicExample/> */}
    
      <Routes>
        <Route path="/" element={
          // <ComingSoon />
          <>


          {/* testing banner  */}
          {/* <div style={{position:"sticky", backgroundColor:"#00C8B8", zIndex:1009, padding:"4px", justifyContent:"center", alignItems:"center", top: 0, display:"flex"}}>
            <p style={{marginBottom:"0px", fontWeight:500, color:"white"}}>Testing</p>
          </div> */}



          <CustomNavbar numNotifications={notifications.length} notifications={notifications} myId={appJsMyId} setNotifications={setNotifications} notificationsLoading={notificationsLoading} setNotificationsLoading={setNotificationsLoading}/>
          <div
  onMouseEnter={(e) => {
    e.currentTarget.style.backgroundColor = 'rgb(15, 219, 202)';
    e.currentTarget.style.width = '70px';
    e.currentTarget.style.height = '70px';
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.backgroundColor = '#00C8B8';
    e.currentTarget.style.width = '60px';
    e.currentTarget.style.height = '60px';
  }}
  style={{
    backgroundColor: "#00C8B8",
    position: "fixed",
    right: window.innerWidth > window.innerHeight ? "32px" : "16px",
    bottom: window.innerWidth > window.innerHeight ? "32px" : "64px",
    width: "60px",
    height: "60px",
    borderRadius: "1000px",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    transition: "width 0.3s ease, height 0.2s ease, background-color 0.2s ease"
  }}
  onClick={() => {
    toggleChat();
  }}
>
  <IoChatbubbleSharp color="white" size={27} />
  {
    totalUnreadMessages > 0 && (
      <div style={{width:"17px", height:"17px", backgroundColor:"red", borderRadius:"100%", position:"absolute", top:0, right: 0, display:"flex", justifyContent:"center", alignItems:"center"}}>
      <p style={{color:"white", marginBottom:"0px", fontSize:"12px"}}>{totalUnreadMessages}</p>
      </div>
    )
  }

</div>

<div style={{position:"fixed", backgroundColor:"#00C8B8", top: 0, left: 0, zIndex:10000, padding:"8px 16px", borderBottomRightRadius:"12px"}}>
  <p style={{marginBottom:"4px", color:"white", fontWeight:500}}>اطلاق تجريبي</p>
  {/* <p style={{marginBottom:"0px", color:"white", fontWeight:500}}>1/10/2024</p> */}
</div>

          <div style={{paddingTop:"65px"}}>
          <Outlet/>

          </div>
          <Footer />
          </>
        }>
          <Route index  element={<LandingScreen myId={appJsMyId}/>}/>
          <Route path="/?search" element={<ProductsScreen />} />
          {/* <Route path="electronics" element={<ElectronicsScreen />} /> */}
          {/* <Route path="furniture" element={<FurnitureScreen />} /> */}
          {/* <Route path="car-parts" element={<CarPartsScreen />} /> */}
          <Route path='product/:id' element={ <PostDetailsScreen myId={appJsMyId} />} />
          <Route path='products' element={ <ProductsScreen />} />
          <Route path='user/:id' element={ < UserScreen myId={appJsMyId} myFullName={myFullName} />} />
          <Route path="add_post" element={<AddPostScreen myId ={appJsMyId} isBusiness={isBusiness} commission={myCommission}/> } />
          <Route path="my_bids" element={<MyBidsScreen />} />
          <Route path="my_account/*" element={<MyAccountScreen myId={appJsMyId}/>} />
          {/* <Route path="sign_up" element={<SignUpScreen />} /> */}
          <Route path="sign_in" element={<SignInOrSignUpScreen />} />
          {/* <Route path="my_posts" element={<MyPostsScreen />} /> */}
          <Route  path='my_products/:id'   element={<MyPostDetailsScreen myId ={appJsMyId} isBusiness={isBusiness} />} />
          <Route  path='my_orders/:id'   element={<MyPurchasedProductsScreen/>} />
          <Route  path='/my_expired_bids/:id'   element={<MyExpiredBidsScreen />} />
          <Route  path='my_sold_products/:id'   element={<MySoldProductsScreen />} />
          <Route  path='more' element={<MoreScreen />} />
          <Route  path='/user_policy' element={<UserAgreement />} />
          <Route  path='/about_us' element={<AboutUsScreen />} />
          <Route  path='/contact_us' element={<ContactUs myId={appJsMyId} />} />
          {/* <Route  path='more' element={<ContactUs />} /> */}
          <Route  path='/checkout/:id' element={<Checkout myId={appJsMyId} />} />
          <Route  path='/orders' element={<PaymentStatus myId={appJsMyId} />} />
          <Route  path='test-screen' element={<TestScreen />} />
          <Route path="*" element={<div style={{height: "100vh"}}><h1 style={{marginTop : "100px"}}>Page Not Found</h1></div>} />
        </Route>
      </Routes>

  </BrowserRouter>
	); 
}; 

export default App;
