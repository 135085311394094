import React, { useEffect, useState } from 'react'
import Product from '../components/Product';
import {auth, db} from '../config/firebase'
import { getFirestore, collection, getDocs ,onSnapshot, serverTimestamp ,query, where,getDoc, updateDoc, limit,getCountFromServer ,doc, Timestamp, FieldValue } from 'firebase/firestore';
import { Oval } from 'react-loader-spinner'
import LoadingScreen from './LoadingScreen';
import { GoHomeFill, GoReport } from "react-icons/go";
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import { getFunctions, httpsCallable } from "firebase/functions";
import { onAuthStateChanged } from "firebase/auth";



function MyBidsScreen() {

    const [myBids, setMyBids] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [userId, setUserId] = useState("")


    const [qu, setQu] = useState(query(collection(db, "Products"), where("isDeleted", "==", false), where("postType", "array-contains", "مزاد"), where("numBidders", ">", 0)));


    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial call to set the state with current window dimensions
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);



    const [signUpPhoneNumber, setSignUpPhoneNumber] = useState("");

    useEffect(() => {
      const handleAuthStateChange = async (user) => {
        if (auth.currentUser && !auth.currentUser.isAnonymous) {
          try {
            setLoading(true);
    
            const docRef = doc(db, 'Users', user.uid);
    
            // Try to read from cache first
            const cachedDoc = await getDoc(docRef, { source: 'cache' }).catch(() => null);
    
            if (cachedDoc && cachedDoc.exists()) {
              // Read from server if cache is not available
              const docSnapshot = await getDoc(docRef);
              if (!docSnapshot.exists()) {
                // Document doesn't exist, create it
                try {
                  const userDetails = {
                    phoneNumber: `+966${signUpPhoneNumber}`,
                    uid: auth.currentUser.uid,
                  };
    
                  const functions = getFunctions();
                  const createUserDoc = httpsCallable(functions, 'createUserDoc');
                  const response = await createUserDoc(userDetails);
    
                  console.log("createUserDoc function response:", response);
    
                  if (response.data.success) {
                    console.log("User document created with ID:", response.data.userId);
                  } else {
                    console.error("Error creating user document:", response.data.error);
                  }
                } catch (error) {
                  console.error("Error calling createUserDoc function:", error);
                }
              }
            }
    
            // Real-time updates
            const unsubscribeUser = onSnapshot(docRef, (docu) => {
              if (docu.exists()) {
                const userData = docu.data();
                setUserId(userData.id)
                // setUserData(userData);
              } else {
                console.error('User data not found');
              }
            });
    
            // Ensure loader is set to false
            setLoading(false);
    
            return () => {
              if (unsubscribeUser) unsubscribeUser();
            };
          } catch (error) {
            console.error('Error fetching user data:', error);
          } finally {
            // setUserInfoLoading(false);
            // setFetching(false); // Ensure fetching state is updated
          }
        } else {
          setLoading(false);
          // setUserInfoLoading(false);
          // setFetching(false);
        }
      };
    
      // Monitor auth state changes
      const unsubscribeAuth = onAuthStateChanged(auth, handleAuthStateChange);
    
      return () => {
        if (unsubscribeAuth) unsubscribeAuth();
      };
    }, [signUpPhoneNumber]); // Include any dependencies, e.g., `signUpPhoneNumber`
    




    useEffect(() => {
      if (!userId) return; // Ensure userId is valid before proceeding
    
      setLoading(true); // Set loading to true initially
    
      // Fetch and listen to product bids data
      const unsubscribeProducts = onSnapshot(
        query(collection(db, 'Products'), where("numBidders", ">", 0)),
        (querySnapshot) => {
          const numericUserId = parseFloat(userId); // Convert userId to a number for comparison
    
          const bids = querySnapshot.docs
            .filter(doc => {
              const bidders = doc.data().bidders || {}; // Get the bidders map from the document
              // Check if the numericUserId exists as a key in the bidders map
              return bidders.hasOwnProperty(numericUserId);
            })
            .map(doc => {
              const data = doc.data();
              // Optionally, if you need to include the document ID in the bid data:
              return { ...data, id: doc.id };
            })
            .sort((a, b) => a.expiryDate - b.expiryDate); // Sort by expiry date
        
          setMyBids(bids);
          setLoading(false); // Set loading to false after fetching bids
        },
        (err) => {
          console.error('Error fetching bids:', err);
          setLoading(false); // Set loading to false in case of an error
        }
      );
    
      // Cleanup function to unsubscribe from listeners on component unmount
      return () => {
        if (unsubscribeProducts) unsubscribeProducts();
      };
    }, [userId]); // Add userId as a dependency to trigger when it changes
    

    const [isVisible, setIsVisible] = useState(false); // Visibility state

    useEffect(() => {
      // Set the div to visible after a slight delay to ensure the transition happens
      if(!isLoading){
        setTimeout(() => setIsVisible(true), 100);
      }
    }, [isLoading]);

  const gridTemplateColumns =
    window.innerWidth > window.innerHeight ? `repeat(3, 1fr)` : `repeat(2, 1fr)`;


    if(isLoading){
        return <LoadingScreen/>
      }

      if(auth.currentUser && auth.currentUser.isAnonymous){
        return <SignInOrSignUpScreen setSignUpPhoneNumber={setSignUpPhoneNumber} redirectUrl={`/my_bids`} />
      }

    return (
      <div
      style={{
        direction: "rtl",
        padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px",
        minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)",
        width: window.innerWidth > window.innerHeight ? "80%" : "100%",
        margin: "auto",
        paddingBottom: "16px",
        opacity: isVisible ? 1 : 0, // Change opacity based on isVisible
        transform: isVisible ? "translateY(0)" : "translateY(20px)", // Reset translateY based on isVisible
        transition: "opacity 0.3s ease, transform 0.2s ease",
      }}
    >
                      {/* path */}
                      <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto",
        marginBottom:"16px"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
          <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8"}} />
          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px",
      fontWeight:"bold"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      fontSize:"14px"

          }}>مزايداتي</h6>
        </div>
      </div>
          
          <div style={{width:"100%"}}>
          {
  myBids.length > 0 ? (
    <div
      style={{
        display: "grid",
        width: "100%",
        height: "fit-content",
        direction: "rtl",
        rowGap: "16px",
        columnGap: "16px",
        gridTemplateColumns: 
  window.innerWidth > window.innerHeight 
    ? window.innerWidth > 1500 
      ? `repeat(auto-fill, minmax(9vw, 1fr))` 
      : window.innerWidth < 1100 
        ? `repeat(auto-fill, minmax(18vw, 1fr))` 
        : `repeat(auto-fill, minmax(14vw, 1fr))`
    : window.innerWidth > 600 
      ? `repeat(auto-fill, minmax(18vw, 1fr))` 
      : `repeat(auto-fill, minmax(42vw, 1fr))`
,
        justifyContent: "center",
        justifyItems: "stretch",
        alignItems: "stretch",
      }}
    >
      {myBids.map((product) => {
          const expiryDate = product.expiryDate.toDate();
  
          // Check if the expiryDate is before the current date
          const isExpired = expiryDate < new Date();
          return ( <div
          style={{
            height: "fit-content", // Match the height of the product
          }}
        >
          
          <Product
            key={product.id}
            imgs={product.imgs[0]}
            auctionStartDate={product.auctionStartDate.toDate()}
            productName={product.productName}
            numBidders={product.numBidders}
            expiryDate={product.expiryDate}
            carPlateKind={product.carPlate.kind}
            productSeller={product.sellerId}
            myId={parseFloat(userId)}
            highestBidder={product.highestBidder}
            acceptsOffers={product.postType.includes("عروض")}
            currentBid={product.currentBid}
            condition={product.condition}
            isAuction={true}
            category={product.category}
            productPrice={product.productPrice}
            views={product.views}
            link={isExpired ? `/my_expired_bids/${product.id}` : `/product/${product.id}`}
          />
        </div>
          )
})}
    </div>
  ) : (
    <div style={{ width: '100%', textAlign: 'center', justifyContent: "center", display: "flex", alignItems: "center" }}>
      <h6>أنت لم تقم بأي مزايدة بعد</h6>
    </div>
  )
}

          </div>
        </div>
      );
      
};

export default MyBidsScreen;