import React , {useState, useEffect, useRef, useContext} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { Oval } from 'react-loader-spinner'
import watch from '../assets/watches.png'
import sbhah from '../assets/sbhah.png'
import laptopBanner from '../assets/laptop-banner.jpg'
import perfumesBanner from '../assets/perfumes-banner.jpg'
import pen from '../assets/pen2.png'
import wallet from '../assets/wallets.png'
import emailjs from 'emailjs-com';
import axios from 'axios';
import ImageGallery from "react-image-gallery";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'; 
import carPlates from '../assets/carPlates.png'
import purses from '../assets/purses.png'
import Product from '../components/Product';
import FurnitureImg from '../assets/furniture.jpg'
import { FaArrowLeftLong } from "react-icons/fa6";
import { collection, query, where, getDocs, orderBy, limit, onSnapshot, doc } from 'firebase/firestore';
import { db, auth } from '../config/firebase';
import plateIcon from '../assets/carPlateW.png'
import phoneNumberIcon from '../assets/phoneNumbers.png'
import LoadingScreen from './LoadingScreen';
// import LandScapeDesktop from '../assets/landscape-desktop.png'
// import LandScapeMobile from '../assets/landscape-mobile.png'
import auctionBannerDesktop from '../assets/auctionBanner-desktop.png'
import auctionBannerMobile from '../assets/auctionBanner-mobile.png'
import commissionBannerDesktop from '../assets/commissionBanner-desktop.png'
import commissionBannerMobile from '../assets/commissionBanner-mobile.png'
import uiBannerDesktop from '../assets/uiBanner-desktop.png'
import uiBannerMobile from '../assets/uiBanner-mobile.png'
import safeShoppingBannerDesktop from '../assets/safeShoppingBanner-desktop.png'
import safeShoppingBannerMobile from '../assets/safeShoppingBanner-mobile.png'
import paymentMethodsBannerDesktop from '../assets/paymentMethodsBanner-desktop.png'
import paymentMethodsBannerMobile from '../assets/paymentMethodsBanner-mobile.png'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { BiArrowFromRight } from 'react-icons/bi';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import ScrollableDivWithArrows from '../components/ScrollableDivWithArrows';
import { IoMdCloseCircle } from "react-icons/io";
import businessAnim from '../assets/animations/business.json';
import businessAnim2 from '../assets/animations/business2.json';
import happy_star from '../assets/animations/happy-star.json';
import Lottie from 'lottie-react';


function LandingScreen({myId}) {
    const [isLoading, setIsLoading] = useState(false);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const navigate = useNavigate();


    const [viralBids, setViralBids] = useState([]);
    const [specialProducts, setSpecialProducts] = useState([]);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    
    const isItemSelected = (id) => selected.includes(id);

    const [selected, setSelected] = React.useState([]);

    const handleClick = (id) => {
      setSelected((prevSelected) =>
        isItemSelected(id)
          ? prevSelected.filter((itemId) => itemId !== id)
          : [...prevSelected, id]
      );
    };


    const scrollRef = useRef(null);

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 300,
      behavior: 'smooth',
    });
  };

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -300,
      behavior: 'smooth',
    });
  };




    const LeftArrow = () => {
      const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
      return (
        <FaArrowLeft
          disabled={isFirstItemVisible}
          onClick={scrollPrev}
          className="left"
        />
      );
    };
  
    const RightArrow = () => {
      const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
      return (
        <FaArrowRight
          disabled={isLastItemVisible}
          onClick={scrollNext}
          className="right"
        />
      );
    };


    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial call to set the state with current window dimensions
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);










    useEffect(() => {
      const mapProduct = doc => ({
        id: doc.id,
        imgs: doc.data().imgs,
        currentBid: doc.data().currentBid,
        condition: doc.data().condition,
        productName: doc.data().productName,
        expiryDate: doc.data().expiryDate,
        numBidders: doc.data().numBidders,
        ...doc.data()
      });
    
      const viralBidsQuery = query(
        collection(db, "Products"),
        where("postType", "array-contains", "مزاد"),
        where("numBidders", ">=", 3),
        where("isSold", "==", false),
        where("isDeleted", "==", false),
        orderBy("numBidders"),
        orderBy("expiryDate"),
        limit(4)
      );
    
      // const specialProductsQuery = query(
      //   collection(db, "Posts"),
      //   where("isSold", "==", false),
      //   where("isDeleted", "==", false),
      //   limit(4)
      // );
    
      const unsubscribeViralBids = onSnapshot(viralBidsQuery, querySnapshot => {
        let products = querySnapshot.docs
          .map(mapProduct)
          .filter(product => Date.now() / 1000 - product.expiryDate.seconds < 0)
          .sort((a, b) => b.numBidders - a.numBidders);
        setViralBids(products);
        setIsLoading(false);
      }, error => {
        console.error('Error fetching viral bids:', error);
        setIsLoading(false);
      });
    
      // const unsubscribeSpecialProducts = onSnapshot(specialProductsQuery, querySnapshot => {
      //   let products = querySnapshot.docs
      //     .map(mapProduct)
      //     .filter(product => product.acceptsOffers && Date.now() / 1000 - product.expiryDate.seconds < 0);
    
      //   setSpecialProducts(products);
      //   setIsLoading(false);
      // }, error => {
      //   console.error('Error fetching special products:', error);
      //   setIsLoading(false);
      // });
    
      return () => {
        unsubscribeViralBids();
        // unsubscribeSpecialProducts();
      };
    }, []);
    




  const [isVisible, setIsVisible] = useState(false);
  const [shouldRenderPopUp, setShouldRenderPopUp] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUpBackgrounud, setShowPopUpBackground] = useState(false);
  

  useEffect(() => {
    // Set the div to visible after a slight delay to ensure the transition happens
    setTimeout(() => setIsVisible(true), 100);
  }, []);


  // useEffect(()=>{
  //   if(isVisible){
  //     const backgroundTimeout = setTimeout(() => {
  //       setShouldRenderPopUp(true);
  //       setShowPopUpBackground(true);
  //     }, 500); // Delay for 5 seconds

  //     return () => clearTimeout(backgroundTimeout); // Cleanup on unmount
  //   }
  // }, [isVisible])

  useEffect(() => {
    if (isVisible) {
      // Check localStorage to see if the popup has been shown before
      const hasShownPopup = localStorage.getItem("hasShownPopup");
  
      // If it hasn't been shown, we show it now
      if (!hasShownPopup) {
        // Set the flag in localStorage
        localStorage.setItem("hasShownPopup", "true"); 
  
        const backgroundTimeout = setTimeout(() => {
          setShouldRenderPopUp(true);
          setShowPopUpBackground(true);
        }, 500); // Delay for 5 seconds
  
        return () => clearTimeout(backgroundTimeout); // Cleanup on unmount
      } else {
        // If it has been shown before, we can reset the flag based on your requirements
        // For example, when a new announcement comes, you could reset this
        // Uncomment the following line if you want to allow the popup to show again
        // localStorage.removeItem("hasShownPopup");
      }
    }
  }, [isVisible]);
  

  useEffect(() => {
    // Render the white div 1 second after the background is visible
    if (shouldRenderPopUp) {
      const popUpTimeout = setTimeout(() => {
        setShowPopUp(true);
      }, 200); // 1 second

      return () => clearTimeout(popUpTimeout); // Cleanup on unmount
    }
  }, [shouldRenderPopUp]);




  useEffect(() => {
    window.scrollTo({
top: 0,
left: 0,
behavior: 'instant'
});
}, []);



  if(isLoading){
    return <LoadingScreen/>
  }


    //   const cropperRef = useRef<ReactCropperElement>(null);
    //   const onCrop = () => {
    //     const cropper = cropperRef.current?.cropper;
    //     console.log(cropper.getCroppedCanvas().toDataURL());
    //   };



  

    const handleSendOtp = async () => {
      try {
        const response = await axios.post('https://cors-anywhere.herokuapp.com/https://us-central1-mazad-782fd.cloudfunctions.net/sendOTP', {
          to: "+966541236990",
        });
  
        console.log(response.data);
        // Handle success or error responses
      } catch (error) {
        console.error('Error sending OTP:', error);
      }
    };

      const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000, // Adjust the speed as needed
        rtl: true,
        arrows: false,
        prevArrow: <PrevArrow />, // Use custom PrevArrow component
        nextArrow: <NextArrow />,
      };


      const settings2 = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 0,
        autoplay: false,

        // autoplaySpeed: 4000, // Adjust the speed as needed
        rtl: true,
        arrows: true,
        prevArrow: <PrevArrow />, // Use custom PrevArrow component
        nextArrow: <NextArrow />,
      };


      // Custom Previous Arrow component
function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <IoIosArrowBack
    color='white'
    className={className}
    style={{ ...style, left: '2px', zIndex: 10,backgroundColor:"#00C0B8", borderRadius:"100px", padding:"2px", width:"20px", height:"20px", opacity: .5}} // Position the arrow inside the slider
    onClick={onClick}
    />
  );
}



// Custom Next Arrow component
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <IoIosArrowForward
    color='white'
    className={className}
    style={{ ...style, right: '2px', zIndex: 10,backgroundColor:"#00C0B8", borderRadius:"100px", padding:"2px", width:"20px", height:"20px", opacity:.5}} // Position the arrow inside the slider
    onClick={onClick}
    />
  );
}



    
  return ( 
    <div style={{ overflow:"hidden", zIndex:1000}}>
{
shouldRenderPopUp && (
  <div
    style={{
      backgroundColor: "#00242250",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      zIndex: 999,
      display: showPopUpBackgrounud ? "flex" : "none",
      justifyContent: "center",
      alignItems: "center",
      opacity: showPopUpBackgrounud ? 1 : 0,
      transition: "opacity 0.1s ease-out",
      top: 0,
      left: 0,
      right: 0,
    }}
  >
    <div
      style={{
        width: window.innerWidth > window.innerHeight ? "80vw" : "calc(100vw - 32px)", // Adjust as needed for responsiveness
        height: "80vh", // Adjust as needed for responsiveness
        backgroundColor: "white",
        zIndex: 1000,
        position: "relative",
        transform: showPopUp ? "translate(0, 0)" : "translate(0, 20px)", // Adjust slide down behavior
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        opacity: showPopUp ? 1 : 0,
        transition: "opacity 0.2s ease, transform 0.5s ease",
        borderRadius: "20px",
        padding: "16px",
        pointerEvents: showPopUp ? "auto" : "none", // Disable pointer events when not showing
        direction: "rtl", // Set text direction to RTL
      }}
    >
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <div>
          <IoMdCloseCircle
            style={{ color: "#ccc", cursor: "pointer" }}
            onClick={() => {setShowPopUp(false);

              setTimeout(() => {
                setShowPopUpBackground(false);
              }, 300); // 1 second delay
            }}
            size={27}
          />
        </div>





        <div
          style={{
            overflowY: "auto", // Enable vertical scrolling
            height: "calc(100% - 40px)", // Adjust height to account for close button and padding
            padding: "16px", // Optional padding inside the scrollable area
            boxSizing: "border-box", // Ensure padding is included in height calculation
          }}
        >
          <h3 style={{ fontWeight: "bolder", color: "#01635c" }}>
            📢 مرحبا بكم في منصة بيع - شريكك الأمثل لتحقيق مبيعاتك بنجاح وسهولة!
          </h3>
          <br /><br />


          <div style={{display:"flex", flexDirection: window.innerWidth > window.innerHeight ? "row" : "column"}}>
            <div>


          <h5 style={{ fontWeight: 500, color: "#01635c" }}>
            إذا كنت تاجراً أو فرداً تتطلع لبيع منتجاتك بدون أي رسوم شهرية أو سنوية، فإن منصة بيع هي الحل المثالي لك! نقدم لك تجربة بيع فريدة تدعمك في الوصول إلى أكبر عدد من المشترين بطريقة آمنة وموثوقة.
          </h5>
          <br /><br />

          <h4 style={{ color: "#01635c" }}>ما يميز منصة بيع عن غيرها؟</h4>
          {
            window.innerHeight > window.innerWidth && (
              <>
              
          <div
        style={{
          // height:"100%",
          zIndex:1000,
          opacity:1
        }}
        >
        <Lottie
          style={{ width: '100%', height: '100%' }}
          animationData={businessAnim}
          loop={true}
          />
        <p style={{ marginBottom: '0px', textAlign: 'center', fontWeight: '500' }}>
          {message}
        </p>
      </div>
              </>
            )
          }
          <br />
          <h5 style={{ color: "#01635c" }}>
            • خيارات بيع متعددة: اختر الطريقة الأنسب لك ولمنتجاتك – سواء عبر الشراء الفوري، أو نظام المزاد لزيادة التنافسية على المنتجات، أو استقبال المفاوضات لتصل إلى أفضل سعر.
          </h5>
          <br />
          <h5 style={{ color: "#01635c" }}>
            • بدون رسوم شهرية أو سنوية: في منصة بيع، نقدم لك جميع خدماتنا بدون أي رسوم اشتراك. ادفع فقط عندما يتم بيع منتجاتك.
          </h5>
          <br />
          <h5 style={{ color: "#01635c" }}>
            • التواصل الفوري مع المشترين: تفاعل بسهولة مع المشترين المحتملين وتلقَّ العروض والمفاوضات مباشرة من خلال المنصة، مما يساعدك في التفاوض وإتمام الصفقة بأفضل الشروط.
          </h5>
          <br />
          <h5 style={{ color: "#01635c" }}>
            • واجهة بسيطة وتجربة مستخدم سلسة: صممنا منصة بيع لتكون سهلة الاستخدام، سواء كنت مبتدئاً أو محترفاً. استمتع بتجربة بسيطة وخالية من التعقيد لعرض منتجاتك وإتمام عمليات البيع.
          </h5>
          <br />


          <div style={{display:"flex"}}>

          <div>

          <h4 style={{ color: "#01635c" }}>لماذا تختار منصة بيع؟</h4>
          {
            window.innerHeight > window.innerWidth && (
              <>
              
          <div
        style={{
          // height:"100%",
          zIndex:1000,
          opacity:1
        }}
        >
        <Lottie
          style={{ width: '100%', height: '100%' }}
          animationData={businessAnim2}
          loop={true}
          />
        <p style={{ marginBottom: '0px', textAlign: 'center', fontWeight: '500' }}>
          {message}
        </p>
      </div>
              </>
            )
          }
          <br />
          <h5 style={{ color: "#01635c" }}>
          • منصة بيع ليست فقط أداة للبيع، بل هي شبكة تواصل تجارية بينك وبين العملاء المحتملين. نقدم لك الحلول التي تساعدك على تعزيز مبيعاتك وتحقيق النجاح، سواء كنت بائعاً مبتدئاً أو محترفاً.
          </h5>
          <br />
          <h5 style={{ color: "#01635c" }}>
          • إدارة المنتجات بسهولة: تابع منتجاتك وتحديثاتها بكل سهولة عبر لوحة تحكم تفاعلية.
          </h5>

          <div style={{display:"flex", width:"100%", alignItems:"center", justifyContent:"center", marginTop:"1rem"}}>


            <h3 style={{color: "#01635c" , whiteSpace:"nowrap"}}>نتمنى لك بيع ممتع</h3>
            <div
        style={{
          // height:"100%",
          zIndex:1000,
          opacity:1,
          marginRight:"8px",
          marginBottom:"2rem"
        }}
        >
        <Lottie
          style={{ width: '50%', height: '50%' }}
          animationData={happy_star}
          loop={true}
          />
        <p style={{ marginBottom: '0px', textAlign: 'center', fontWeight: '500' }}>
          {message}
        </p>
      </div>
          </div>
          </div>





          </div>
            </div>

          <div style={{backgroundColor:"white", display:"flex", flexDirection:"column", justifyContent:"space-around"}}>

          {
            window.innerWidth  > window.innerHeight && (
              <>
              

          <div
        style={{
          // height:"100%",
          zIndex:1000,
          opacity:1
        }}
        >
        <Lottie
          style={{ width: '100%', height: '100%' }}
          animationData={businessAnim}
          loop={true}
          />
        <p style={{ marginBottom: '0px', textAlign: 'center', fontWeight: '500' }}>
          {message}
        </p>
      </div>


      <div
        style={{
          // height:"100%",
          zIndex:1000,
          opacity:1,
          
        }}
        >
        <Lottie
          style={{ width: '100%', height: '100%' }}
          animationData={businessAnim2}
          loop={true}
          />
        <p style={{ marginBottom: '0px', textAlign: 'center', fontWeight: '500' }}>
          {message}
        </p>
      </div>

          </>
        )
      }

      
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
}

    <div id="landing-screen-div"
    style={{borderTopRightRadius:"8px", borderTopLeftRadius:"8px", backgroundColor:"transparent"}}
    className={isVisible ? 'show' : ''}
      >



       {/* <Cropper
      src="https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg"
      style={{ height: 400, width: "100%" }}
      // Cropper.js options
      initialAspectRatio={16 / 9}
      guides={false}
      crop={onCrop}
      ref={cropperRef}
    /> */}


         {/* <div>

          <button onClick={handleSendOtp}>Send Email</button>
        </div> */}

        <Slider className='slider-class' {...settings}>
        <div style={{ overflow: "hidden", width:"100%"}}>
            <img src={window.innerWidth > window.innerHeight ? uiBannerDesktop : uiBannerMobile} alt="First Image" style={{ width: '100%', objectFit: window.innerWidth > window.innerHeight ? "fill" : "cover", borderRadius: window.innerWidth > window.innerHeight ? "8px" : "0px"}} />
        </div>
        <div style={{ overflow: "hidden", width:"100%"}}>
            <img src={window.innerWidth > window.innerHeight ? commissionBannerDesktop : commissionBannerMobile  } alt="First Image" style={{ width: '100%', objectFit: window.innerWidth > window.innerHeight ? "fill" : "cover", borderRadius: window.innerWidth > window.innerHeight ? "8px" : "0px"}} />
        </div>
        <div style={{ overflow: "hidden", width:"100%"}}>
            <img src={window.innerWidth > window.innerHeight ? safeShoppingBannerDesktop : safeShoppingBannerMobile} alt="First Image" style={{ width: '100%', objectFit: window.innerWidth > window.innerHeight ? "fill" : "cover", borderRadius: window.innerWidth > window.innerHeight ? "8px" : "0px"}} />
        </div>
        <div style={{ overflow: "hidden", width:"100%"}}>
            <img src={window.innerWidth > window.innerHeight ? auctionBannerDesktop : auctionBannerMobile} alt="First Image" style={{ width: '100%', objectFit: window.innerWidth > window.innerHeight ? "fill" : "cover", borderRadius: window.innerWidth > window.innerHeight ? "8px" : "0px"}} />
        </div>
      </Slider>


      <div id='explore-categories' style={{width: "100%"}}>
            <h3 style={{marginBottom: "32px", fontWeight: "bold", color:"#00A49B", marginTop:"62px", textAlign:"right", marginRight: window.innerWidth > window.innerHeight ? "0px" : "16px"}}>تسوّق من التصنيفات</h3>
        </div>


      <ScrollableDivWithArrows styles={{marginTop:"0px", marginRight: window.innerWidth > window.innerHeight ? "0px" : "16px", marginLeft: window.innerWidth > window.innerHeight ? "0px" : "16px"}}>
        <div style={{textAlign: "center", cursor: "pointer", width: "fit-content", 
              // marginRight: "auto",
              // marginLeft: "auto"    ,
              marginLeft:"24px",
              }}
                  onClick={
                      () => {
                              // Get all keys and delete each one
                        const keys = Array.from(searchParams.keys());
                        keys.forEach(key => searchParams.delete(key));
                        searchParams.set('category', 'ساعات');
                        navigate(`/products?${searchParams.toString()}`)
                  }}
              >
              <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", padding: "8px", backgroundColor: "#f7f7f7"}}
              src={watch}  />
                    <p
    style={{
      marginTop: "8px",
      fontSize: "16px",
      fontWeight: 500,
      textDecoration: "none", // Initial text decoration
      cursor: "pointer", // Show pointer cursor on hover
      padding:"8px",
      color:"#00A49B"
    }}
    onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
    onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
  >
    ساعات
  </p>

              </div>


              <div style={{textAlign: "center",cursor: "pointer", width: "fit-content",
              marginLeft:"24px",

          // marginRight: "auto",
          // marginLeft: "auto"    
        }}
                  onClick={
                      () => {
                          const keys = Array.from(searchParams.keys());
                          keys.forEach(key => searchParams.delete(key));
                          searchParams.set('category', 'سبح');
                          navigate(`/products?${searchParams.toString()}`)
                  }}
              >
                  <img id='landing-screen-img-size' style={{borderRadius: "1000px", backgroundColor:"#f7f7f7"}} src={sbhah} />
                  <p
    style={{
      marginTop: "8px",
      fontSize: "16px",
      fontWeight: 500,
      textDecoration: "none", // Initial text decoration
      cursor: "pointer", // Show pointer cursor on hover
      padding:"8px",
      color:"#00A49B"

    }}
    onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
    onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
  >
  سبح
  </p>
              </div>


              <div style={{textAlign: "center",cursor: "pointer", width: "fit-content", 
              marginLeft:"24px",

        }}
                  onClick={
                      () => {
                        const keys = Array.from(searchParams.keys());
                        keys.forEach(key => searchParams.delete(key));
                          searchParams.set('category', 'اقلام');
                          navigate(`/products?${searchParams.toString()}`)
                  }}
              >
                  <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", padding: "8px", backgroundColor:"#f7f7f7"}} src={pen} />
                  <p
    style={{
      marginTop: "8px",
      fontSize: "16px",
      fontWeight: 500,
      textDecoration: "none", // Initial text decoration
      cursor: "pointer", // Show pointer cursor on hover
      padding:"8px",
      color:"#00A49B"

    }}
    onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
    onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
  >
  أقلام
  </p>
              </div>







  <div style={{textAlign: "center", cursor: "pointer", width: "fit-content", 
              marginLeft:"24px",

        }}
                  onClick={
                      () => {
                        const keys = Array.from(searchParams.keys());
                        keys.forEach(key => searchParams.delete(key));
                          searchParams.set('category', 'ابواك');
                          navigate(`/products?${searchParams.toString()}`)
                  }}
              >
                  <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#f7f7f7"}} src={wallet} />
                  <p
    style={{
      marginTop: "8px",
      fontSize: "16px",
      fontWeight: 500,
      textDecoration: "none", // Initial text decoration
      cursor: "pointer", // Show pointer cursor on hover
      padding:"8px",
      color:"#00A49B"

    }}
    onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
    onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
  >
  أبواك
  </p>
              </div>


              <div style={{textAlign: "center", cursor: "pointer", width: "fit-content", 
              marginLeft:"24px",

                    }}
                            onClick={
                                () => {
                                  const keys = Array.from(searchParams.keys());
                                  keys.forEach(key => searchParams.delete(key));
                                    searchParams.set('category', 'حقائب نسائية');
                                    navigate(`/products?${searchParams.toString()}`)
                            }}
                        >
                            <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#f7f7f7"}} src={purses} />
                            <p
    style={{
      marginTop: "8px",
      fontSize: "16px",
      fontWeight: 500,
      textDecoration: "none", // Initial text decoration
      cursor: "pointer", // Show pointer cursor on hover
      padding:"8px",
      color:"#00A49B"

    }}
    onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
    onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
  >
  حقائب نسائية
  </p>
                        </div>



              <div style={{textAlign: "center", cursor: "pointer", width: "fit-content", 
              marginLeft:"24px",

                  }}
                            onClick={
                                () => {
                                  const keys = Array.from(searchParams.keys());
                                  keys.forEach(key => searchParams.delete(key));
                                    searchParams.set('category', 'لوح سيارات');
                                    navigate(`/products?${searchParams.toString()}`)
                            }}
                        >
                            <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#f7f7f7"}} src={plateIcon} />
                            <p
    style={{
      marginTop: "8px",
      fontSize: "16px",
      fontWeight: 500,
      textDecoration: "none", // Initial text decoration
      cursor: "pointer", // Show pointer cursor on hover
      padding:"8px",
      color:"#00A49B"

    }}
    onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
    onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
  >
  لوح سيارات
  </p>
                        </div>




  <div style={{textAlign: "center", cursor: "pointer", width: "fit-content", 
                    marginRight: "auto",
                    marginLeft: "auto"    }}
                            onClick={
                                () => {
                                  const keys = Array.from(searchParams.keys());
                                  keys.forEach(key => searchParams.delete(key));
                                    searchParams.set('category', 'ارقام جوالات');
                                    navigate(`/products?${searchParams.toString()}`)
                            }}
                        >
                            <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#f7f7f7"}} src={phoneNumberIcon} />
                            <p
    style={{
      marginTop: "8px",
      fontSize: "16px",
      fontWeight: 500,
      textDecoration: "none", // Initial text decoration
      cursor: "pointer", // Show pointer cursor on hover
      padding:"8px",
      color:"#00A49B"

    }}
    onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
    onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
  >
    أرقام جوالات
  </p>
                        </div>
    </ScrollableDivWithArrows>
      



{/* 
              <ImageGallery 
              showBullets={true}
              showNav={false}
              showPlayButton={false}
              showFullscreenButton={false}
              isRTL={false}
              infinite={true}
              autoPlay
              slideInterval={4000}
              showThumbnails={false}
              
              onClick={(e)=> {
                if(e.target.src.includes("perfumes-banner")){
                    console.log("navigate to perfumes")
                }
                else{
                    console.log("navigate to laptops")
                }
              }}
              items={[
                {
                original: perfumesBanner,
                thumbnail: perfumesBanner,
                originalClass: 'image-gallery-class',
                bulletClass: 'bulletClass',
              },
                {
                original: laptopBanner,
                thumbnail: laptopBanner,
                originalClass: 'image-gallery-class',
                bulletClass: 'bulletClass',
              },
              ]} /> */}





            {/* <div style={{textAlign: "center", marginRight: "8px"}}>
                <Link to='/electronics' style={{textDecoration: "none", color: "black"}}>
            <img height={80} width={80} src={ElectronicsImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>الكترونيات</p>
                </Link>
            </div> */}
            {/* <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={FurnitureImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>اثاث</p>
            </div> */}





                <div style={{display: "flex", marginTop: "64px"}}>
                {/* <div style={{textAlign: "center",cursor: "pointer", width: "fit-content", 
        marginRight: "auto",
        marginLeft: "auto"    }}
                onClick={
                    () => {
                        searchParams.set('category', 'اقلام');
                        navigate(`/products?${searchParams.toString()}`)
                }}
            >
                <img id='landing-screen-img-size' style={{borderRadius: "8px", objectFit: "scale-down", padding: "8px", backgroundColor:"#FAFAFA"}} src={pen} />
                <p
  style={{
    marginTop: "8px",
    fontSize: "16px",
    fontWeight: 500,
    textDecoration: "none", // Initial text decoration
    cursor: "pointer", // Show pointer cursor on hover
    padding:"8px"
  }}
  onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
  onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
>
أقلام
</p>
            </div> */}

            {/* <div style={{textAlign: "center", cursor: "pointer", width: "fit-content", 
        marginRight: "auto",
        marginLeft: "auto"    }}
                onClick={
                    () => {
                        searchParams.set('category', 'ابواك');
                        navigate(`/products?${searchParams.toString()}`)
                }}
            >
                <img id='landing-screen-img-size' style={{borderRadius: "8px", objectFit: "scale-down", backgroundColor:"#FAFAFA"}} src={wallet} />
                <p
  style={{
    marginTop: "8px",
    fontSize: "16px",
    fontWeight: 500,
    textDecoration: "none", // Initial text decoration
    cursor: "pointer", // Show pointer cursor on hover
    padding:"8px"
  }}
  onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
  onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
>
أبواك
</p>
            </div> */}



            
            {/* <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={carParts} style={{borderRadius: "1000px", objectFit: "cover"}} />
            <p>قطع غيار</p>
          </div> */}
        </div>
        <div style={{display:"flex", marginTop:"42px"}}>
                      {/* <div style={{textAlign: "center", cursor: "pointer", width: "fit-content", 
                  marginRight: "auto",
                  marginLeft: "auto"    }}
                          onClick={
                              () => {
                                  searchParams.set('category', 'لوح سيارات');
                                  navigate(`/products?${searchParams.toString()}`)
                          }}
                      >
                          <img id='landing-screen-img-size' style={{borderRadius: "8px", objectFit: "scale-down", backgroundColor:"#FAFAFA"}} src={plateIcon} />
                          <p
  style={{
    marginTop: "8px",
    fontSize: "16px",
    fontWeight: 500,
    textDecoration: "none", // Initial text decoration
    cursor: "pointer", // Show pointer cursor on hover
    padding:"8px"
  }}
  onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
  onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
>
لوح سيارات
</p>
                      </div> */}



                      {/* <div style={{textAlign: "center", cursor: "pointer", width: "fit-content", 
                  marginRight: "auto",
                  marginLeft: "auto"    }}
                          onClick={
                              () => {
                                  searchParams.set('category', 'حقائب نسائية');
                                  navigate(`/products?${searchParams.toString()}`)
                          }}
                      >
                          <img id='landing-screen-img-size' style={{borderRadius: "8px", objectFit: "scale-down", backgroundColor:"#FAFAFA"}} src={purses} />
                          <p
  style={{
    marginTop: "8px",
    fontSize: "16px",
    fontWeight: 500,
    textDecoration: "none", // Initial text decoration
    cursor: "pointer", // Show pointer cursor on hover
    padding:"8px"
  }}
  onMouseEnter={(e) => { e.target.style.textDecoration = "underline"; }} // Add underline on mouse enter
  onMouseLeave={(e) => { e.target.style.textDecoration = "none"; }} // Remove underline on mouse leave
>
حقائب نسائية
</p>
                      </div> */}
        </div>


        {viralBids.length > 0 && 
<>
        <div style={{
          width: "100%", 
          display: "flex", 
          justifyContent: "center", // Center the items horizontally
          padding: window.innerWidth < window.innerHeight ?  "0px 16px" :"0px", 
          alignItems: "center", // Align items vertically in the center
          position:"relative",
        }}>
    <div style={{position: "absolute", left: window.innerWidth > window.innerHeight ? "4px" : "16px", display:"flex" }}> {/* Position the icon absolutely within the parent div */}
<div
 onClick={()=>{
  searchParams.set('bidders', '3');
  navigate(`/products?${searchParams.toString()}`)
}}
style={{display:"flex", cursor:"pointer", justifyContent:"center", alignItems:"center"}}
>
  {
    window.innerWidth > window.innerHeight && (
      <p style={{display:"flex", marginLeft:"8px", fontWeight:500, marginBottom:"0px", color:"#00A49B"}}>عرض الكل</p>
    )
  }
<FaArrowLeftLong size={20} color='#00A49B'/>
</div>
        
    </div>
    <div id='explore-categories' style={{width: "100%"}}>
            <h4 style={{marginBottom: "32px", fontWeight: "bold", color:"#00A49B", marginTop:"62px", textAlign:"right"}}>مزادات رائجة</h4>
        </div>
    <div></div> {/* This div is kept to maintain the structure, but it's not necessary */}
</div>

<div style={{padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
<div style={{
          display: "grid",
          gap: "8px",
          width: "100%",
          height: "fit-content",
          direction:"rtl",
          gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth > 1500 ? `repeat(auto-fill, minmax(9vw, 1fr))` : window.innerWidth < 1100 ? `repeat(auto-fill, minmax(18vw, 1fr))` : `repeat(auto-fill, minmax(14vw, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(20vw, 1fr))` : `repeat(auto-fill, minmax(42vw, 1fr))`,
        }}>
        {viralBids.map(product => (
            <Product
            key={product.id}
            imgs={product.imgs[0]}
            productName={product.productName}
            numBidders={product.numBidders}
            expiryDate={product.expiryDate}
            currentBid={product.currentBid}
            condition={product.condition}
            isAuction={true}
            auctionStartDate={product.auctionStartDate.toDate()}
            acceptsOffers={product.postType.includes("عروض")}
            category={product.category}
            isBidder={Object.keys(product.bidders).map(key => parseInt(key)).includes(parseInt(myId))}
            highestBidder={product.highestBidder}
            myId={myId}
            carPlateKind={product.carPlate.kind}
            link={`/product/${product.id}`}
            views={product.views}
            />
          ))}
          </div>
</div>
          </>
          }
        <div style={{padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px", marginTop:"52px"}}>

<img src={window.innerWidth > window.innerHeight ? paymentMethodsBannerDesktop : paymentMethodsBannerMobile} style={{maxHeight: window.innerWidth > window.innerHeight ? "60vh" : "30vh", width:"100%", borderRadius:"8px", objectFit:"cover"}} />
</div>

        




{/* 
{specialProducts.length > 0 && 
<>
        <div style={{
          width: "100%", 
          marginBottom: "16px", 
          display: "flex", 
          justifyContent: "center", // Center the items horizontally
          padding: "0px 16px", 
          marginTop: "52px", 
          alignItems: "center", // Align items vertically in the center
          position:"relative"
        }}>
    <div style={{ position: "absolute", left: "16px" }}> 
        <FaArrowLeftLong size={20} color='#00C8B8'/>
    </div>
    <p style={{ 
        fontSize: "18px", 
        fontWeight: "bold", 
        marginBottom: "0px",
        textAlign: "center" // Ensure text is centered within the <p> tag itself
      }}>
        منتجات مميزة
    </p>
    <div></div>
</div>

        <div style={{display:"grid", justifyContent:"space-between",padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px", gridTemplateColumns: window.innerWidth > window.innerHeight ? "repeat(auto-fill, minmax(auto, 16vw))" : "repeat(auto-fill, minmax(auto, 44vw))", gap:8, justifyContent: "space-between"}}>
        {specialProducts.map(product => (
          <Product
          key={product.id}
          imgs={product.imgs[0]}
          productName={product.productName}
          numBidders={product.numBidders}
          expiryDate={product.expiryDate.seconds}
          currentBid={product.currentBid}
          condition={product.condition}
          acceptsOffers={true}
          link={`/product/${product.id}`}
          />
          ))}
          </div>
          </>
          } */}




        {/* products with > 10 bidders */}
        {/* <h2 style={{marginBottom: "16px", marginRight: "8px", marginTop: "16px"}} >صفقات لا تفوّت!</h2> */}
        {/* <div id='main-div'  style={{display: "flex", overflow: "scroll"}}>

            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={ElectronicsImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>الكترونيات</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={FurnitureImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>اثاث</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={watch} style={{borderRadius: "1000px", objectFit: "scale-down"}} />
                    <p>ساعات</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={sbhah} style={{borderRadius: "1000px", objectFit: "scale-down"}} />
                    <p>سبح</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={carParts} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>قطع غيار</p>
            </div>
        </div> */}

        {/* products with > 10 views */}
        {/* <h2 style={{marginBottom: "16px", marginRight: "8px", marginTop: "16px"}} >منتجات رائجة</h2> */}


        {/* visited categories with count */}
        {/* <h2 style={{marginBottom: "16px"}} >منتجات تهمك</h2>
        <div style={{display: "flex", overflow: "scroll"}}>

            <div style={{textAlign: "center"}}>
            <img height={80} width={80} src={ElectronicsImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>الكترونيات</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={FurnitureImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>اثاث</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={watch} style={{borderRadius: "1000px", objectFit: "scale-down"}} />
                    <p>ساعات</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={sbhah} style={{borderRadius: "1000px", objectFit: "scale-down"}} />
                    <p>سبح</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={carParts} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>قطع غيار</p>
            </div>
        </div> */}
    </div>
    </div>
   );
  }

export default LandingScreen;