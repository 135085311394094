import React, {useState, useRef, useEffect} from 'react';
import logo from '../assets/logo.png'
import { FaGoogle } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { FaSnapchatGhost } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa";

function Footer() {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div style={{paddingBottom: window.innerWidth > window.innerHeight ? "0px" : "55px"}}>
      <div style={{width:"100%", height:"fit-content", backgroundColor:"#F0F0F0", alignItems:"center", direction:"rtl"}}>
        <div style={{width: window.innerWidth > window.innerHeight ? "70%" : "100%", height:"100%", marginLeft:"auto", marginRight:"auto", display: window.innerWidth > window.innerHeight ? "flex" : "block", padding:"24px 0px", justifyContent:"space-between"}}>
        
        
          <div style={{alignItems:"center",  display: "flex", marginTop:"8px",
              flexDirection: "column",}}>
            <img src={logo} height={90} width={90} />
            <p style={{marginBottom : "8px", fontSize: "14px", marginTop:"0px", fontWeight:500 }}>فرص جديدة في كل لحظة</p>
            شركة حلول البيع لتقنية المعلومات
          </div>



          <div style={{alignItems:"center", display: "flex", marginTop:"32px", marginRight: window.innerWidth > window.innerHeight ? "32px" : "0px",
              flexDirection: "column",}}>

                    <h5 style={{color:"#52BCBE"}}>الحسابات الإجتماعية</h5>
            <div style={{display:"flex" , flexDirection:window.innerWidth > window.innerHeight ? "column" : "row"}}>

                    
                    {/* mail */}
                    {/* <a href="mailto:support@men-ela.com" target="_blank" rel="noopener noreferrer" style={{marginLeft:"auto"}} >
                      <div style={{display:"flex" , alignItems:"center",  marginLeft:"auto", marginBottom:"8px"}}>
                          <div style={{
                            backgroundColor: "rgba(0, 200, 184, 0.21)", // Use RGBA for opacity
                            alignItems: "center", 
                            padding: "4px", 
                            borderRadius: "30px",
                            marginLeft:"8px",
                            width:"fit-content",
                            height:"fit-content",
                          }}>
                              <div style={{
                                backgroundColor: "#00C8B8", 
                                width: "30px", 
                                height: "30px", 
                                justifyContent: "center", 
                                alignItems: "center", 
                                borderRadius: "30px",
                                display:"flex"
                              }}>
                                <FaGoogle color='white'/>
                              </div>
                            </div>
                          <p style={{marginBottom:"0px", color:"black", fontSize:"15px", fontWeight:700, display:window.innerWidth > window.innerHeight ? "block" : "none"}}>الإيميل</p>
                        </div>
                    </a> */}


                          {/* x */}
                          <a  href="https://x.com/SellAppSA" target="_blank" rel="noopener noreferrer" style={{marginLeft:"auto"}} >
                      <div style={{display:"flex", alignItems:"center",  marginLeft:"auto", marginBottom:"8px"}}>
                          <div style={{
                            backgroundColor: "rgba(0, 200, 184, 0.21)", // Use RGBA for opacity
                            alignItems: "center", 
                            padding: "4px", 
                            borderRadius: "30px",
                            marginLeft:"8px",
                            width:"fit-content",
                            height:"fit-content",
                          }}>
                              <div style={{
                                backgroundColor: "#00C8B8", 
                                width: "30px", 
                                height: "30px", 
                                justifyContent: "center", 
                                alignItems: "center", 
                                borderRadius: "30px",
                                display:"flex"
                              }}>
                              <RiTwitterXFill color='white'/>
                              </div>
                            </div>
                          <p style={{marginBottom:"0px", color:"black", fontSize:"15px", fontWeight:700, display:window.innerWidth > window.innerHeight ? "block" : "none"}}>إكس</p>
                        </div>
                    </a>



                          {/* instagram */}
                          <a  href="https://www.instagram.com/SellAppSA" target="_blank" rel="noopener noreferrer" style={{marginLeft:"auto"}} >
                      <div style={{display:"flex", alignItems:"center",  marginLeft:"auto", marginBottom:"8px"}}>
                          <div style={{
                            backgroundColor: "rgba(0, 200, 184, 0.21)", // Use RGBA for opacity
                            alignItems: "center", 
                            padding: "4px", 
                            borderRadius: "30px",
                            marginLeft:"8px",
                            width:"fit-content",
                            height:"fit-content",
                          }}>
                              <div style={{
                                backgroundColor: "#00C8B8", 
                                width: "30px", 
                                height: "30px", 
                                justifyContent: "center", 
                                alignItems: "center", 
                                borderRadius: "30px",
                                display:"flex"
                              }}>
                              <FaInstagram color='white' size={20}/>
                              </div>
                            </div>
                          <p style={{marginBottom:"0px", color:"black", fontSize:"15px", fontWeight:700, display:window.innerWidth > window.innerHeight ? "block" : "none"}}>إنستغرام</p>
                        </div>
                    </a>



          {/* snapchat */}
          <a  href="https://snapchat.com/t/ai7kUIuT" target="_blank" rel="noopener noreferrer" style={{marginLeft:"auto"}} >
                      <div style={{display:"flex", alignItems:"center",  marginLeft:"auto", marginBottom:"8px"}}>
                          <div style={{
                            backgroundColor: "rgba(0, 200, 184, 0.21)", // Use RGBA for opacity
                            alignItems: "center", 
                            padding: "4px", 
                            borderRadius: "30px",
                            marginLeft:"8px",
                            width:"fit-content",
                            height:"fit-content",
                          }}>
                              <div style={{
                                backgroundColor: "#00C8B8", 
                                width: "30px", 
                                height: "30px", 
                                justifyContent: "center", 
                                alignItems: "center", 
                                borderRadius: "30px",
                                display:"flex"
                              }}>
                              <FaSnapchatGhost color='white'/>
                              </div>
                            </div>
                          <p style={{marginBottom:"0px", color:"black", fontSize:"15px", fontWeight:700, display:window.innerWidth > window.innerHeight ? "block" : "none"}}>سناب شات</p>
                        </div>
                    </a>



          {/* tiktok */}
          <a href="https://www.tiktok.com/@SellAppSA" target="_blank" rel="noopener noreferrer" style={{marginLeft:"auto"}} >
                      <div style={{display:"flex", alignItems:"center",  marginLeft:"auto", marginBottom:"8px"}}>
                          <div style={{
                            backgroundColor: "rgba(0, 200, 184, 0.21)", // Use RGBA for opacity
                            alignItems: "center", 
                            padding: "4px", 
                            borderRadius: "30px",
                            marginLeft:"8px",
                            width:"fit-content",
                            height:"fit-content",
                          }}>
                              <div style={{
                                backgroundColor: "#00C8B8", 
                                width: "30px", 
                                height: "30px", 
                                justifyContent: "center", 
                                alignItems: "center", 
                                borderRadius: "30px",
                                display:"flex"
                              }}>
                              <FaTiktok color='white'/>
                              </div>
                            </div>
                          <p style={{marginBottom:"0px", color:"black", fontSize:"15px", fontWeight:700, display:window.innerWidth > window.innerHeight ? "block" : "none"}}>تيك توك</p>
                        </div>
                    </a>

                  </div>


          </div>

          <div style={{alignItems:"center", display: "flex", marginTop:"32px", marginRight: window.innerWidth > window.innerHeight ? "32px" : "0px",
              flexDirection: "column"}}>
                    <h5 style={{color:"#52BCBE"}}>الروابط</h5>
                    <a href='/user_policy' style={{marginBottom:"0px", color:"black", fontSize:"15px", fontWeight:700}}>سياسة الإستخدام</a>
                    <a href='/about_us' style={{marginBottom:"0px", color:"black", fontSize:"15px", fontWeight:700, marginTop:"8px"}}>من نحن</a>
                    <a href='/contact_us' style={{marginBottom:"0px", color:"black", fontSize:"15px", fontWeight:700, marginTop:"8px"}}>تواصل معنا</a>
          </div>
        </div>
      </div>
      <div style={{display:"flex", direction:"rtl", justifyContent:"center", padding:"8px", alignItems:"center"}}>
      {/* <FaRegCopyright color='#52BCBE' style={{marginLeft:"4px"}}/> */}
      <p style={{ marginBottom: "0px", color: "#52BCBE", fontSize: "14px" }}>
  جميع الحقوق محفوظة {new Date().getFullYear()}، بواسطة منصة بيع
</p>

      

      </div>
    </div>
  //  <div style={{backgroundColor: "white"}}>
  //    <div id="footer-div">
  //           <div style={{display: "flex", width: "100%", flexDirection: "column"}}>
  //           <div style={{width: "100%", display: "flex", flexDirection: "column", textAlign: "center", marginBottom: "16px"}}>
  //               <img src={logo} height={70} width={70} style={{marginRight: "auto", marginLeft: "auto"}} />
  //               <p style={{marginBottom : "8px", fontSize: "14px", marginTop:"8px" }}>فرص جديدة في كل لحظة</p>
  //             </div>
  //             <div style={{ width: "100%", textAlign: "center"}}>
  //               <p style={{marginBottom : "8px", fontSize: "18px", fontWeight: "bold"}}>روابط مهمة</p>
  //               <Link to="about_us" style={{textDecoration: "none"}}><p style={{marginBottom : "8px", fontSize: "14px", cursor:"pointer", color: "black" }}>من نحن</p></Link>
  //               <Link to="user_policy" style={{textDecoration: "none"}}><p style={{marginBottom : "8px", fontSize: "14px", cursor:"pointer", color: "black" }}>سياسة الاستخدام</p></Link>
  //               {/* <Link to="return_policy" style={{textDecoration: "none"}}><p style={{marginBottom : "8px", fontSize: "14px", cursor:"pointer", color: "black", marginBottom: "32px" }}>سياسة الاستبدال و الاسترجاع</p></Link> */}
  //             </div>

  //             <div style={{width: "100%", textAlign: "center", marginBottom: "0px", marginTop:"16px"}}>
  //               <p style={{fontWeight: "bold", fontSize: "18px"}}>سهلناها عليك ووفرنا طرق الدفع التالية</p>
  //               <img src={payment} height={50} style={{width: "100%", objectFit: "scale-down"}}/>
  //             </div>
  //           </div>
  //           <div style={{marginTop: "16px",direction: "rtl", alignItems: "center", marginBottom: "16px", justifyContent: "center",
  //             marginRight: "auto", marginLeft: "auto", textAlign: "center"
          
  //         }}>
  //           <p style={{marginBottom: "4px"}}>تواصل معنا</p>
  //             <Link to={whatsAppLink("966541236990")} style={{textDecoration: "none"}}>
  //             <div style={{display: "flex", cursor:"pointer"}}>
  //             <IoLogoWhatsapp style={{color:"black", marginTop: "2px"}} />
  //             <p style={{direction: "ltr", fontSize: "14px", marginRight: "4px", marginBottom: "0px", color:"black" }}>+966541236990</p>
  //             </div>
  //             </Link>
  //           </div>
  //         </div>
  //  </div>
  );
}

export default Footer;