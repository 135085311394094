import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, query, where, doc, getDoc, onSnapshot } from 'firebase/firestore'; // Assuming Firebase
import { db, auth, storage } from '../config/firebase';
import { MdInfo } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import LoadingScreen from './LoadingScreen';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import Button from '@mui/material/Button';
import { Oval } from 'react-loader-spinner'
import Countdown from 'react-countdown';
import { IoSend } from "react-icons/io5"; 
import SuccessAnim from '../components/SuccessAnim';
import { GoHomeFill, GoReport } from "react-icons/go";
import { FaStore } from "react-icons/fa";
import { getFunctions, httpsCallable } from "firebase/functions";
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import { onAuthStateChanged } from "firebase/auth";



function MyPurchasedProductsScreen() {
  const [orderData, setOrderData] = useState(null); // State for order data
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [myId, setMyId] = useState("")
  const [deliveryOption, setDeliveryOption] = useState("")
  const [productImg, setProductImg] = useState("")
  const [productName, setProductName] = useState("")
  const [productDescription, setProductDescription] = useState("")
  const [productCategory, setProductCategory] = useState("")
  const [orderAmount, setOrderAmount] = useState("")
  const [postType, setPostType] = useState([])
  const [buyerId, setBuyerId] = useState("")
  const [entryFee, setEntryFee] = useState("")
  const [bidders, setBidders] = useState([])
  const [city, setCity] = useState("")
  const [neighbourhood, setNeighbourhood] = useState("")
  const [street, setStreet] = useState("")
  const [notes, setNotes] = useState("")
  const [awbNo, setAWBNo] = useState("")
  const [orderStatus, setOrderStatus] = useState("")

  

    // In your Checkout component
    const location = useLocation();


  const navigate = useNavigate();

  const [isReceived, setIsReceived] = useState("")
  const [isRejected, setIsRejected] = useState("")
  
  const {id} = useParams();
  const [postId, setPostId] = useState(id);
  const [autoReceiveDate, setAutoReceiveDate] = useState();

  const [receiverAddress, setReceiverAddress] = useState({});


  const [lastShipmentUpdate, setLastShipmentUpdate] = useState("");
const messageRef = useRef();

  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const [sellerId, setSellerId] = useState("");
  const [sellerProfilePicture, setSellerProfilePicture] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [sellerIsVerified, setSellerIsVerified] = useState(false);
  const [sellerIsBusiness, setSellerIsBusiness] = useState(false);
  const [sellerRating, setSellerRating] = useState("");
  const [sellerNumRaters, setSellerNumRaters] = useState("");
  const [sellerCity, setSellerCity] = useState("");
  const [rejectionReasonMessage, setRejectionReasonMessage] = useState("");
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    // Extract day, month, year, hours, and minutes
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };
  
  const [productReceiveLoading, setProductReceiveLoading] = useState(false);
  const [productRejectLoading, setProductRejectLoading] = useState(false);
  const [messageHasError, setMessageHasError] = useState("");
  const [showSuccessAnim, setShowSuccessAnim] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const[sellerData, setSellerData] = useState(null);
  const[productData, setProductData] = useState(null);

  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
  const [receiverName, setReceiverName] = useState("");

  

  const [signUpPhoneNumber, setSignUpPhoneNumber] = useState("");

  useEffect(() => {
    const handleAuthStateChange = async (user) => {
      if (auth.currentUser && !auth.currentUser.isAnonymous) {
        try {
          setIsLoading(true);
  
          const docRef = doc(db, 'Users', user.uid);
  
          // Try to read from cache first
          const cachedDoc = await getDoc(docRef, { source: 'cache' }).catch(() => null);
  
          if (cachedDoc && cachedDoc.exists()) {
            // Read from server if cache is not available
            const docSnapshot = await getDoc(docRef);
            if (!docSnapshot.exists()) {
              // Document doesn't exist, create it
              try {
                const userDetails = {
                  phoneNumber: `+966${signUpPhoneNumber}`,
                  uid: auth.currentUser.uid,
                };
  
                const functions = getFunctions();
                const createUserDoc = httpsCallable(functions, 'createUserDoc');
                const response = await createUserDoc(userDetails);
  
                console.log("createUserDoc function response:", response);
  
                if (response.data.success) {
                  console.log("User document created with ID:", response.data.userId);
                } else {
                  console.error("Error creating user document:", response.data.error);
                }
              } catch (error) {
                console.error("Error calling createUserDoc function:", error);
              }
            } else{
              setMyId(docSnapshot.data().id)
            }
          }
  
          // Real-time updates
          const unsubscribeUser = onSnapshot(docRef, (docu) => {
            if (docu.exists()) {
              const userData = docu.data();
              // setUserData(userData);
            } else {
              console.error('User data not found');
            }
          });
  
          // Ensure loader is set to false
          setIsLoading(false);
  
          return () => {
            if (unsubscribeUser) unsubscribeUser();
          };
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          // setUserInfoLoading(false);
          // setFetching(false); // Ensure fetching state is updated
        }
      } else {
        setIsLoading(false);
        // setUserInfoLoading(false);
        // setFetching(false);
      }
    };
  
    // Monitor auth state changes
    const unsubscribeAuth = onAuthStateChanged(auth, handleAuthStateChange);
  
    return () => {
      if (unsubscribeAuth) unsubscribeAuth();
    };
  }, [signUpPhoneNumber]); // Include any dependencies, e.g., `signUpPhoneNumber`
  
  


  useEffect(() => {
    if (myId && id) {
      setIsLoading(true); // Start loading before fetching data
  
      const unsubscribeFromOrder = onSnapshot(
        query(
          collection(db, "Orders"),
          where("buyerId", "==", myId),
          where("id", "==", parseFloat(id)),
          where("type", "==", "Purchase"),
          where("payment_status", "==", "success")
        ),
        async (orderSnapshot) => {
          if (orderSnapshot.docs.length > 0) {
            const order = orderSnapshot.docs[0].data();
  
            // Set order-related state values
            setOrderData(order);
            setDeliveryOption(order.deliveryOption);
            setOrderAmount(order.amount);
            setBuyerId(order.buyerId);
            setCity(order.city);
            setAWBNo(order.awbNo);
            setReceiverAddress(order.receiverAddress);
            setNeighbourhood(order.neighbourhood);
            setStreet(order.street);
            setOrderStatus(order.order_status);
            setReceiverName(order.receiverName);
            setReceiverPhoneNumber(order.receiverPhoneNumber);
            setNotes(order.notes);
            setIsReceived(order.isReceived);
            setIsRejected(order.isRejected);
            setSellerId(order.sellerId);
            setProductData(order.productData);
            setAutoReceiveDate(order.autoReceiveDate);
  
            // Fetch the associated seller data using where query
            const sellerQuery = query(
              collection(db, "Users"),
              where("id", "==", order.sellerId)
            );
  
            const sellerQuerySnapshot = await getDocs(sellerQuery);
  
            if (!sellerQuerySnapshot.empty) {
              const sellerData = sellerQuerySnapshot.docs[0].data();
              setSellerProfilePicture(sellerData.profileImgUrl);
              setSellerName(sellerData.fullName);
              setSellerRating(sellerData.rating);
              setSellerNumRaters(sellerData.numRaters);
              setSellerIsVerified(sellerData.isVerified);
              setSellerIsBusiness(sellerData.isBusiness);
              setSellerCity(sellerData.city);
              setSellerData(sellerData);
            } else {
              console.error("No seller document found!");
              setSellerData(null);
            }
          } else {
            setOrderData(null);
            setProductData(null);
            setSellerData(null);
          }
  
          // Set loading state to false after data is fetched
          setIsLoading(false);
        },
        (err) => {
          console.error("Error fetching order data:", err);
          setError(err);
          setIsLoading(false); // Set loading state to false in case of error
        }
      );
  
      // Cleanup function to unsubscribe from order listener on myId or id change
      return () => unsubscribeFromOrder();
    } else {
      setIsLoading(false);
    }
  }, [id, myId]);
  

  const [trackingStatus, setTrackingStatus] = useState([]);

  const getSmsaTrackingDetails = async () => {
    const functions = getFunctions();
    const getSmsaTrackingDetails = httpsCallable(functions, 'getSmsaTrackingDetails');
  
    try {
      // console.log(`Requesting tracking details for AWB No: ${awbNo}`);
  
      const result = await getSmsaTrackingDetails({ awbNo });
  
      if (result.data.success) {
        // console.log('Tracking details fetched successfully:', result.data.trackingDetails);
  
        // console.log(result)
        setLastShipmentUpdate(result.data.trackingDetails.Tracking[0].Activity)
        // Extract and set the Tracking array
        const trackingDetails = result.data.trackingDetails.Tracking || [];
        setTrackingStatus(trackingDetails);
      } else {
        console.error('Failed to fetch tracking details:', result.data.message);
      }
    } catch (error) {
      console.error('Error fetching tracking details:', error);
    }
  };

  useEffect(()=>{
    if(awbNo){
      // console.log("Getting the tracking details for awbNo: ", awbNo)
      getSmsaTrackingDetails()
    }
  }, [awbNo])

  const [isVisible, setIsVisible] = useState(false); // Visibility state

  useEffect(() => {
    // Set the div to visible after a slight delay to ensure the transition happens
    if(!isLoading){
      setTimeout(() => setIsVisible(true), 100);
    }
  }, [isLoading]);
  
  if(isLoading){
    return <LoadingScreen/>
  }

  if (auth.currentUser && auth.currentUser.isAnonymous && !isLoading) {
    return <SignInOrSignUpScreen setSignUpPhoneNumber={setSignUpPhoneNumber} />;
  }

  if (error) {
    return (
      <div>
        <p>Error fetching data: {error.message}</p>
      </div>
    );
  }

  const confirmReceiveOrder = async (orderId) => {
    const functions = getFunctions();
    const receiveOrderFn = httpsCallable(functions, 'receiveOrder');
  
    try {
      const result = await receiveOrderFn({ orderId: id });
  
      console.log(result.data.message);
      setIsReceived(true);
      setProductReceiveLoading(false);
      setSuccessMessage("!تم تأكيد استلام المنتج");
      setShowSuccessAnim(true);
    } catch (error) {
      console.error("Error confirming receipt of order:", error);
      setProductReceiveLoading(false);
    }
  };

const rejectReceiveOrder = async (orderId, rejectionReason) => {
  const functions = getFunctions();
  const rejectOrderFn = httpsCallable(functions, 'rejectOrder');

  try {
    const result = await rejectOrderFn({ orderId: id, rejectionReason });

    // console.log(result.data.message);
    setIsRejected(true);
    setProductRejectLoading(false);
    setSuccessMessage("!تم رفض استلام المنتج");
    setShowSuccessAnim(true);
  } catch (error) {
    console.error("Error rejecting receipt of order:", error);
    setProductRejectLoading(false);
  }
};


  function formatAccountingArabic(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number)) {
      return 'Invalid input';
    }
  
    // Use toLocaleString to format the number with commas and two decimal places
    const formattedNumber = number.toLocaleString('en', {
      minimumFractionDigits:2,
      maximumFractionDigits: 2
    });
  
    // Add the Arabic currency symbol manually
    const arabicFormattedNumber = `${formattedNumber} ر.س`;
  
    return arabicFormattedNumber;
}

const handleAnimationComplete = () => {
  setShowSuccessAnim(false); // Reset button state after animation completes
};

let arabicState = "";

  return (
    <div
    style={{
      minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)",
      opacity: isVisible ? 1 : 0, // Change opacity based on isVisible
      transform: isVisible ? "translateY(0)" : "translateY(20px)", // Reset translateY based on isVisible
      transition: "opacity 0.3s ease, transform 0.2s ease", // Transition effects
    }}
  >
{showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}


<div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", margin:"auto",  padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
            {/* path */}
            <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
                    <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8", minHeight:"18px", minWidth:"18px"}} />

          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px",
      fontWeight:"bold"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px",
      fontWeight:"bold"
    }}
    onClick={()=>{navigate(-2)}}
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >حسابي</h6>

          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px",
      fontWeight:"bold"
    }}
    onClick={()=>{navigate(-1)}}
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >مشترياتي</h6>

<h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 
                    style={{marginBottom:"0px", 
                    maxWidth: "100%",  /* Ensure the element does not exceed the container's width */
                    overflow: "hidden",  /* Hide any overflow content */
                    whiteSpace: "nowrap",  /* Prevent text from wrapping to a new line */
                    textOverflow: "ellipsis",  /* Display ellipsis if the text overflows */
                  fontSize:"14px"}}
          >طلب رقم # {id}</h6>
        </div>
      </div>
</div>


      {orderData && ( // Only render if orderData exists
       <div style={{display: "flex", flexDirection:  window.innerWidth > window.innerHeight ? "row" : "column",justifyContent: window.innerWidth > window.innerHeight ? "space-around" : "start",direction: "rtl", width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginLeft:"auto", marginRight:"auto", height: "fit-content", position:"relative", paddingBottom:"32px", minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)"}}>
     
                               <div style={{width: window.innerWidth > window.innerHeight ? "55%" : "100%", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
                                       <div>
                               <h5 style={{marginTop:"16px"}}>ملخص الطلب</h5>
                               <div style={{ height: "fit-content", border : "1.5px solid #00C8B8", borderRadius: "12px", padding: "8px"}}>
                                       <h6 style={{marginRight: "8px"}}>{`طلب رقم#   ${id}`}</h6>
     
                                       <div style={{width: "95%", display: "flex"}}>
         <img src={productData.imgs[0]} alt="" style={{width: "30%", borderRadius: "12px", objectFit: productData.productCategory !== "لوح سيارات" && productData.productCategory !== "ارقام جوالات" ? "cover" : "scale-down", height: window.innerWidth > window.innerHeight ? "150px" : "100px"}} />
         <div style={{marginRight: "8px", width: "70%"}}>
           <p style={{
             marginBottom: "4px",
             fontWeight: "500",
             display: "-webkit-box",
             WebkitBoxOrient: "vertical",
             WebkitLineClamp: 8,
             overflow: "hidden"
           }}>
             {productData.productName}
           </p>
           <p style={{
             marginBottom: "0px",
             fontSize: "14px",
             color: "gray",
             display: "-webkit-box",
             WebkitBoxOrient: "vertical",
             WebkitLineClamp: 10,
             overflow: "hidden"
           }}>
             {productData.productDescription}
           </p>
           <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
             <p style={{marginBottom: "0px"}}>{formatAccountingArabic(orderAmount)}</p>
           </div>
         </div>
         
       </div>
     
                               </div>


                               {
      productCategory !== "ارقام جوالات" && productCategory !== "لوح سيارات" && (
        <>
  <h5 style={{marginBottom:"8px", marginTop:"16px"}}>طرق الشحن و التوصيل</h5>

<div style={{height:"fit-content", width:"100%", marginBottom:"24px", borderRadius:"8px", direction:"rtl", margin:"16px auto", marginTop:"16px"}}>

  <div style={{ backgroundColor: "#f7f7f7", padding: "24px 16px", borderRadius: "8px", alignItems: "center", width:"100%"}}>
  
  {
    deliveryOption === "شحن" && (
      <div style={{ display: "flex", width:window.innerWidth > window.innerHeight ? "50%" :"100%", alignItems:"center"}}>
      <FaShippingFast color='#00C8B8' size={32} style={{ marginBottom: "0px", marginLeft: "8px" }} />
      <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>الشحن السريع</p>
    </div>
    )
  }

{
  deliveryOption === "تسليم" && (
  <div style={{ display: "flex", width:window.innerWidth > window.innerHeight ? "50%" :"100%", alignItems:"center", marginTop:"0px"}}>
    <FaRegHandshake color='#00C8B8' size={32} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>التسليم وجهاً لوجه</p>
  </div>
  )
}

  </div>
  
  {
                                 deliveryOption === "تسليم" && (
                                   <div style={{display: "flex", backgroundColor: "#fff0f2" , width: "100%", marginBottom: "16px", marginTop: "8px", borderRadius: "6px", padding: "8px 10px"}}>
                                   <MdInfo size={17} color='#ff7385' style={{minHeight:"17px", minWidth:"17px"}} />
                                   <div>
                               
                                   <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>نوصي بالتقاء المشتري في مكان عام لتسليم المنتج و توخي الحذر لأي ظرف كان, و التأكد من ان المشتري فحص المنتج في حينه, و تجنب تلقي اي وسيلة دفع خارج المنصة.</p>
                                   <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>منصة (بيع) تخلي مسؤوليتها في حال عدم اتباع اياً من المذكور أعلاه.</p>
                                   </div>
                                 </div> 
                                 )
                               }
</div>
          
                                 </>
                               )}

                              {
                                deliveryOption === "شحن" && (
                                  <>
                                  <div style={{display:"flex", marginTop: "24px"}}>
     
                                  <h5 style={{fontWeight: "bold", color: "#444" }}>العنوان</h5>
                                  </div>

                                  

                                  <div style={{width: "100%", height: "fit-content", backgroundColor: "#DBEDED", border: "1.5px solid #00C8B8", borderRadius: "12px", display: "flex", alignItems: "center", padding: "12px", position: "relative"}}>
      <IoLocation size={32} color='#00C8B8' />
      <div style={{marginRight: "8px"}}>
      <p style={{fontSize: "16px", fontWeight: "500", marginBottom: "0px"}}>{receiverName}</p>    
      <p style={{fontSize: "16px", fontWeight: "500", marginBottom: "0px"}}>{receiverPhoneNumber}</p>    

        <p style={{fontSize: "16px", fontWeight: "bold", marginBottom: "0px"}}>{receiverAddress.city}</p>    
        <p style={{ marginBottom: "8px", color: "gray" }}>
        {`حي ${receiverAddress.neighborhood}${receiverAddress.street ? `, شارع ${receiverAddress.street}` : ''}`}
        </p>
        <p style={{marginBottom:"0px", color:"gray"}}>{receiverAddress.notes}</p>
      </div>
    </div>

    <div
              style={{
                textAlign: 'center',
                position: "relative",
                borderRadius: "16px",
                width: "100%",
                padding: "16px 8px",
                backgroundColor: "#F7F7F7",
                textAlign: 'right',
                display:"flex",
                marginTop:"8px"
              }}
            >
                  <MdInfo size={20} color='#a0a0a0' style={{minHeight:"20px", minWidth:"20px"}} />
                  <div style={{marginRight:"4px"}}>
                    
              <p>
                في حال تم إصدار بوليصة الشحن لن تتمكن من تعديل عنوانك الا في حالة التواصل مع خدمة عملاء شركة الشحن.
                <br />
                <br />
                <span>رقم التواصل: <span style={{fontWeight:500, direction:"ltr"}}>966112892999+</span></span>
                <br />
                <br />
                في حال رغبتك في تحديث العنوان  قبل اصدار بوليصة الشحن يرجى التواصل مع البائع.
                </p>

                  </div>
            </div>


    <div style={{display:"flex", marginTop: "24px"}}>
     
     <h5 style={{fontWeight: "bold", color: "#444" }}>تتبع الشحنة {awbNo && `# ${awbNo}`}</h5>
     </div>

     <div>
  {trackingStatus.length > 0 ? (
    trackingStatus.map((update, index) => {
      const activity = update.Activity.toLowerCase();

      switch (activity) {
        case 'data received':
          arabicState = 'تم استلام بيانات الشحنة';
          break;
        case 'picked up':
          arabicState = 'تم استلام الشحنة من المرسل';
          break;
        case 'departed form origin':
          arabicState = 'الشحنة غادرت الفرع';
          break;
        case 'arrived hub facility':
          arabicState = 'الشحنة وصلت لمحطة التوزيع';
          break;
        case 'departed hub facility':
          arabicState = 'الشحنة غادرت محطة التوزيع';
          break;
        case 'out for delivery':
          arabicState = 'جاري توصيل الشحنة للعميل';
          break;
        case 'consignee no response':
          arabicState = 'فشل التواصل مع العميل';
          break;
        case 'contact no. not belongs to consignee':
          arabicState = 'الرقم المسجل بالشحنة مختلف عن رقم العميل';
          break;
        case 'incorrect delivery address':
          arabicState = 'عنوان العميل خاطئ'
          break;
        case 'at smsa facility':
          arabicState = 'الشحنة وصلت الفرع'
          break;
        case 'at smsa retail center':
          arabicState = 'الشحنة وصلت الفرع'
          break;
        case 'awaiting consignee for collection':
          arabicState = 'بانتظار استلام العميل من الفرع'
          break;
        case 'proof of delivery captured':
          arabicState = 'تم التوصيل';
          break;
        case 'consignee mobile off':
          arabicState = 'جوال العميل مغلق';
          break;
        default:
          arabicState = update.Activity; // Fallback to the original activity if no match
          break;
      }

      return (
        <div key={index} style={{ direction: "rtl" }}>
          <p><strong>حالة الشحنة:</strong> {arabicState}</p>
          <p><strong>الوقت:</strong> {formatDate(update.Date)}</p>
          <hr />
        </div>
      );
    })
  ) : (
    <p>لم يتم إصدار بوليصة الشحن بعد.</p>
  )}
</div>
                                  </>
                                )
                              }
                               
                               </div>
                               
                               </div>
                               <div style={{width: window.innerWidth > window.innerHeight ? "40%" : "100%", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px", marginRight: window.innerWidth > window.innerHeight ? "auto" : "0px"}}>

                                   <h5 style={{marginTop:"16px"}}>البائع</h5>
                               <div 
                  style={{
                      display: "flex",
                      marginTop: "20px",
                    }}
                    >
                 <Link to={`/user/${sellerId}`} style={{display: "contents", color: "black", textDecoration: "none"}}>
            <div style={{display: "flex", width: "100%", padding:"16px" , marginBottom:"16px", backgroundColor:"#f7f7f7", borderRadius:"8px", alignItems:"center"}}>
                <div style={{ position:"relative"}}>
                <img src={sellerProfilePicture} width={65} height={65} style={{borderRadius: "1000px", objectFit: "cover"}}/>
                        </div>
                <div style={{height:"fit-content", marginBottom:"auto",justifyContent:"flex-end", display:"flex", flexDirection:"column", width: "fit-content"}}>
                  <div style={{display:"flex"}}>
                  <p style={{marginRight: "8px", marginBottom: "0px"}}>{sellerName}</p>
                    <div style={{display: "flex",alignItems: "center"}}>
                      {!sellerIsBusiness && sellerIsVerified && (
                        <RiVerifiedBadgeFill size={22} color='#00c8b8' style={{marginRight:"4px"}}/>
                        )}
                      {sellerIsBusiness && sellerIsVerified && (
                        <FaStore size={22} color='#F00057' style={{marginRight:"4px"}}/>
                        )}
                    <AiFillStar size={14} color='gold' style={{ marginRight:"4px"}}/>
                         <p style={{marginBottom:"0px", marginRight:"2px", fontSize: "14px"}}>{parseFloat(sellerRating).toFixed(1)}</p>
                         <p style={{marginBottom:"0px"   , marginRight:"2px", fontSize: "14px"}}>({sellerNumRaters})</p>
                    </div>
                  </div>
                    {
                      sellerCity != "" &&
                                        <div style={{direction: "rtl", display: "flex"}}>
                                        <FaLocationDot size={14} color='red' style={{marginRight: "4px", marginTop: "4px"}} />
                                        <p style={{marginRight: "2px", marginBottom:"0px"}}>{sellerCity}</p>
                                        </div>
                    }

                    
                </div>
            </div>
            </Link>
                </div>

                <h5  style={{fontWeight: "bold", color: "black", marginTop: window.innerWidth > window.innerHeight ? "16px" : "42px", }}>ملخص المبلغ</h5>
     
     <div style={{ height: "fit-content",borderRadius: "12px" , width: "100%" ,backgroundColor: "#F4F4F4", padding: "16px 0px"}}>
<div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
<h6>المبلغ</h6>
<h6>{formatAccountingArabic(orderAmount)}</h6>  
</div>
     </div>

     <h5 style={{marginTop:"16px"}}>حالة الطلب</h5>
     <div style={{ height: "fit-content",borderRadius: "12px" , width: "100%" ,backgroundColor: "#F4F4F4", padding: "16px 0px"}}>
<div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
<h6>{orderData.order_status}</h6>  
</div>
     </div>

     {
}

{!isReceived && !isRejected &&  autoReceiveDate !== "" && autoReceiveDate?.seconds * 1000 > Date.now() && (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', textAlign:"right" }}>
    <h5 style={{ marginTop: window.innerWidth > window.innerHeight ? "16px" : "42px", marginLeft:"auto" }}>
      تأكيد استلام المنتج
    </h5>


    <div style={{ marginTop: "8px", display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      
      {/* Confirm Receive Button */}
      <Button
        id="my-button"
        disabled={productReceiveLoading}
        style={{ width: "100%" }}
        onClick={async () => {
          // Display confirmation dialog
          const isConfirmed = window.confirm("تأكيد استلام المنتج؟");
        
          if (isConfirmed) {
            try {
              setProductReceiveLoading(true);
              await confirmReceiveOrder(orderData.orderId);
            } catch (error) {
              // console.error('Error updating post document:', error);
            } finally {
              setProductReceiveLoading(false);
            }
          } else {
            // console.log('Order receive action was canceled.');
          }
        }}
        
      >
        {productReceiveLoading ? (
          <Oval
            height={"20px"}
            width={"20px"}
            color={"white"}
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#ccc"
            strokeWidth={1}
            strokeWidthSecondary={1}
          />
        ) : (
          <p style={{ marginBottom: "0px", whiteSpace: "nowrap" }}>تأكيد الاستلام</p>
        )}
      </Button>

      {
  !productData.postType.includes("مزاد") && (
    <>
      <p style={{ marginTop: "16px", fontSize: 16, fontWeight: 500, textAlign: 'right', width: '100%' }}>
        واجهتك مشكلة في المنتج؟
      </p>
      <Button
        id="my-button"
        style={{ width: "100%", backgroundColor: "#F00057" }}
        onClick={() => setShowRejectionReason(true)}
      >
        <p style={{ marginBottom: "0px", whiteSpace: "nowrap" }}>رفض الاستلام</p>
      </Button>
    </>
  )
}


      {/* Show Rejection Reason Form */}
      {showRejectionReason && (
        <div style={{ direction: "rtl", display: "flex", flexDirection: "column", marginTop: "16px", width: "100%" }}>
          <div style={{ display: "flex" }}>
            <p style={{ color: "red" }}>*</p>
            <select
              id="input-field"
              onChange={(event) => setRejectionReason(event.target.value)}
              style={{ color: "black", borderRadius: "4px", marginBottom: "8px" }}
            >
              <option value="">سبب الرفض</option>
              <option value="خلل في المنتج">خلل في المنتج</option>
              <option value="المنتج غير مطابق للوصف">المنتج غير مطابق للوصف</option>
              {deliveryOption === "شحن" && <option value="لم يصلني المنتج">لم يصلني المنتج</option>}
              <option value="آخر">آخر (حدد)</option>
            </select>
          </div>

          {/* If reason is not "Other" */}
          {rejectionReason !== "آخر" && (
            <Button
              id="my-button"
              disabled={productRejectLoading}
              onClick={async () => {
                if (!rejectionReason) {
                  alert("يرجى اختبار سبب الرفض");
                  return;
                }
                try {
                  setProductRejectLoading(true);
                  await rejectReceiveOrder(orderData.orderId, rejectionReason);
                  setProductRejectLoading(false);
                } catch (error) {
                  setProductRejectLoading(false);
                  console.error('Error updating post document:', error);
                }
              }}
              style={{
                height: "35px",
                width: window.innerWidth > window.innerHeight ? "150px" : "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#F00057",
                marginTop: "8px"
              }}
            >
              {productRejectLoading ? (
                <Oval
                  height={"20px"}
                  width={"20px"}
                  color={"white"}
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#ccc"
                  strokeWidth={1}
                  strokeWidthSecondary={1}
                />
              ) : (
                <p style={{ marginBottom: "0px" }}>ارسال</p>
              )}
            </Button>
          )}

          {/* If reason is "Other" */}
          {rejectionReason === "آخر" && (
            <>
              <textarea
                ref={messageRef}
                onChange={(e) => {
                  setMessageHasError(false);
                  setRejectionReasonMessage(e.target.value);
                }}
                placeholder="سبب الرفض"
                style={{
                  backgroundColor: "#F0F0F0",
                  border: messageHasError ? "1px solid #F00057" : "none",
                  width: "100%",
                  height: "180px",
                  borderRadius: "8px",
                  outline: "none",
                  padding: "16px 16px 32px 16px",
                  marginTop: "8px"
                }}
              />
              <Button
                id="my-button"
                disabled={productRejectLoading}
                onClick={async () => {
                  if (!rejectionReasonMessage) {
                    alert("يرجى كتابة سبب الرفض");
                    return;
                  }
                  try {
                    setProductRejectLoading(true);
                    await rejectReceiveOrder(orderData.orderId, rejectionReasonMessage);
                    setProductRejectLoading(false);
                    setRejectionReason("");
                  } catch (error) {
                    setProductRejectLoading(false);
                    console.error('Error updating post document:', error);
                  }
                }}
                style={{
                  height: "35px",
                  width: window.innerWidth > window.innerHeight ? "150px" : "100px",
                  backgroundColor: "#F00057",
                  marginTop: "8px"
                }}
              >
                {productRejectLoading ? (
                  <Oval
                    height={"20px"}
                    width={"20px"}
                    color={"white"}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#ccc"
                    strokeWidth={1}
                    strokeWidthSecondary={1}
                  />
                ) : (
                  <p style={{ marginBottom: "0px" }}>ارسال</p>
                )}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  </div>
)}



          {
  autoReceiveDate && autoReceiveDate?.seconds * 1000 > Date.now() && (
    
    <div style={{display:"flex",alignItems:"center", marginTop:"16px", justifyContent:"start" }}>
  <p style={{fontSize:16, fontWeight:500, marginBottom:"0px", marginLeft:"auto", whiteSpace:"nowrap"}}>
سيتم تأكيد استلام المنتج تلقائياً خلال:
  </p>
  <div style={{ marginLeft:"auto", display:"flex", width:"100%", marginRight:"4px"}} >
  <p style={{marginBottom:"0px", marginLeft:"auto" }}>
<Countdown
                    className='countdown-class'
                    date={autoReceiveDate.seconds * 1000}
                    >
                    </Countdown>
</p>
    </div>
                      </div>
                      )
                    }
                    {
                      isRejected && (
                        <h6 style={{marginTop:"8px"}}>تم رفض استلام المنتج, سيقوم فريقنا بالتواصل معك للتحقق.</h6>
                      )
                    }

                               </div>

                       </div>
                       
      )}
    </div>
  );
}

export default MyPurchasedProductsScreen;
