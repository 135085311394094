import React , {useState, useEffect, useRef} from 'react'
import Category from '../components/Category';
import ElectronicsImg from '../assets/electronics.avif';
import FurnitureImg from '../assets/furniture.jpg';
import autoParts from '../assets/auto-parts.jpg';
import musicInstruments from '../assets/music-instruments.jpeg';
import CustomNavbar from '../components/CustomNavbar';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, onSnapshot, query, where, updateDoc, limit, doc, getDoc, serverTimestamp, arrayUnion , addDoc, orderBy, setDoc} from 'firebase/firestore';
import Product from '../components/Product';
import Button from '@mui/material/Button';
import { Select, MenuItem } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'react-bootstrap';
import { Divider } from '@mui/material';
import { PiGavelFill } from "react-icons/pi";
import { MdOutlineWatchLater } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import Countdown from 'react-countdown';
import ImageGallery from "react-image-gallery";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { AiFillStar } from "react-icons/ai";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import f from '../assets/furniture.jpg';
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import Comment from '../components/Comment';
import { MdVerifiedUser } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Oval } from 'react-loader-spinner'
import { db, auth, storage } from '../config/firebase'
import LoadingScreen from './LoadingScreen';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { GoHomeFill, GoReport } from "react-icons/go";
import { IoIosCloseCircle } from "react-icons/io"; 
// import Rating from '@mui/material/Rating';
import { Rating } from 'react-simple-star-rating'

import SuccessAnim from "../components/SuccessAnim"
import { BsThreeDotsVertical } from 'react-icons/bs';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { MdMailOutline } from "react-icons/md";
import { useChat } from '../hooks/ChatContext'
import { v4 as uuidv4 } from 'uuid';
import { FaStore } from "react-icons/fa";
import { getFunctions, httpsCallable } from "firebase/functions";
import StarRatings from 'react-star-ratings';


function UserScreen({myId, myFullName}) {
  const [Listing, setListing] = useState([]);
  const [gal, setgal] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [similarCategory, setSimilarCategory] = useState([]);
  const [similarSubCategory, setSimilarSubCategory] = useState([]);
  const [productSubCategory, setProductSubCategory] = useState("")
  const [productPrice, setProductPrice] = useState(0)
  const [productStorageSize, setProductStorageSize] = useState("")
  const [productScreenSize, setProductScreenSize] = useState("")
  const [productMemorySize, setProductMemorySize] = useState("")
  const [productInkKind, setProductInkKind] = useState("")
  const [filteredListing, setFilteredListing] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState("");
  const [qu, setQu] = useState(query(collection(db, "Products")));
  const [isLoading, setIsLoading] = useState(true);
  const [hasExpired, setExpired] = useState(false);
  const [isAuction, setIsAuction] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [currentBid, setCurrentBid] = useState(0);
  const [myBid, setMyBid] = useState(0);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [postComments, setPostComments] = useState([]);
  const [userId, setUserId] = useState("");
  const [addCommentLoading, setAddCommentLoading] = useState(false);

  const [city, setCity] = useState("")
  const [showReview, setShowReview] = useState(false)

  const [isVerified, setIsVerified] = useState(false)
  const [isBusiness, setIsBusiness] = useState(false)
  const [userExist, setUserExist] = useState(false)
  const [showReviewError, setShowReviewError] = useState(false)

  const [sellerId, setSellerId] = useState("");
  const [commentsNum, setCommentsNum] = useState(0);

  const [orderId, setOrderId] = useState("");






  const { setChatIsOpen, setNewChatUser, setDrawerOpen, setActiveChatId, usersInfo, chats } = useChat();

  const toggleSendMessage = () => {
    setChatIsOpen(true);
    setDrawerOpen(true);
  
    // Find existing chat with the specified user ID
    const existingChat = Object.values(chats).find(chat => {
      const isUserInGroup = chat.group.includes(parseFloat(id)); // Ensure ID is compared as a string
      return isUserInGroup;
    });
  
    if (existingChat) {
      // Set the existing chat as active
      setActiveChatId(existingChat.id);
    } else {
      // Create a new chat if none exists
      const newChatId = uuidv4();
      setActiveChatId(newChatId);
    }
  
    // Set new chat user information
    setNewChatUser({
      profileImgUrl: profileImgUrl,
      isVerified: isVerified,
      isBusiness: isBusiness,
      rating: parseFloat(userRating),
      numRaters: parseFloat(numRaters),
      city: city,
      id: userId,
      fullName: userName
    });
  };
  
  
  
  






  const [isOpen, setIsOpen] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
    }
  }, [isOpen]);


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };






  const [isReportUserOpen, setIsReportUserOpen] = useState(false);
  const [reportUserShouldRender, setReportUserShouldRender] = useState(false);
  const [reviewNotes, setReviewNotes] = useState("");

  

  const location = useLocation();
  const { productName, productCategory } = location.state || {};



  useEffect(() => {
    if (isReportUserOpen) {
      setReportUserShouldRender(true);
    }
  }, [isReportUserOpen]);


  
  useEffect(() => {
    if (!isReportUserOpen) {
      setTimeout(() => setReportUserShouldRender(false), 100); // Duration matches the animation time
    }
  }, [isReportUserOpen]);

  const handleCloseReportUser = () => {
    setIsReviewOptionsOpen(false);
    setTimeout(() => setReportUserShouldRender(false), 100); // Duration matches the animation time
  };
  
  const toggleReport = () => {
    setIsReportUserOpen(!isReportUserOpen);
  };


  const [value, setValue] = useState(0)

  const [userName, setUserName] = useState("");
  const [profileImgUrl, setProfileImgUrl] = useState("");
  const [userRating, setUserRating] = useState(0);
  const [numRaters, setNumRaters] = useState(0);

  const [isReviewOptionsOpen, setIsReviewOptionsOpen] = useState(false);
  const [reviewShouldRender, setReviewShouldRender] = useState(false);

  const [reviewUpdating, setReviewUpdating] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);

  const navigate = useNavigate();

const currentUrl = window.location.pathname;


  const [ratingNotes, setRatingNotes] = useState("");
  const [reportReason, setReportReason] = useState("");
  const [reportNotes, setReportNotes] = useState("");
  const [productNameFromOrder, setProductName] = useState("");
  

  const commentRef = useRef();

  async function updateRating() {
    if (value === null) {
      setShowReviewError(true);
      return;
    }
  
    try {
      setReviewUpdating(true);
      
      const functions = getFunctions();
      const updateUserRating = httpsCallable(functions, 'updateUserRating');
      
      const data = {
        userId: id,
        orderId: orderId,
        myUserId: myId,
        ratingValue: value,
        ratingNotes: ratingNotes,
        myFullName
      };
      
      const response = await updateUserRating(data);
      
      if (response.data.success) {
        setIsReviewOptionsOpen(false);
        setReviewUpdating(false);
        setValue(0);
        setRatingNotes("");
        setSuccessMessage("!تم تقييم البائع بنجاح");
        setShowSuccessAnim(true);
        setShowReviewError(false);
        setOrderId("");
      } else {
        console.error("Error updating document: ", response.data.error);
        setIsReviewOptionsOpen(false);
        setReviewUpdating(false);
        setShowReviewError(false);
      }
    } catch (error) {
      console.error("Error updating document: ", error);
      setIsReviewOptionsOpen(false);
      setReviewUpdating(false);
      setShowReviewError(false);
    }
  }
  

  async function reportUser() {
    // Report reason validation
    if (reportReason === "") {
      alert("يرجى اختيار نوع البلاغ");
      return;
    }

    if (reportReason === "آخر" && reportNotes === "") {
      alert("يرجى ادخال وصف البلاغ");
      return;
    }
  
    try {
      setReportLoading(true);
  
      const functions = getFunctions();
      const reportUser = httpsCallable(functions, 'reportUser');
  
      const data = {
        reportReason: reportReason,
        reportNotes: reportNotes,
        reporterId: myId,
        targetId: id,
      };
  
      const response = await reportUser(data);
  
      if (response.data.success) {
        setIsReportUserOpen(false);
        setReportLoading(false);
        setReportNotes("");
        setSuccessMessage("!تم الإبلاغ عن البائع بنجاح");
        setShowSuccessAnim(true);
      } else {
        console.error("Error reporting user: ", response.data.error);
        setIsReportUserOpen(false);
        setReportLoading(false);
      }
    } catch (error) {
      console.error("Error reporting user: ", error);
      setIsReportUserOpen(false);
      setReportLoading(false);
    }
  }
  

  useEffect(() => {
    if (isReviewOptionsOpen) {
      setReviewShouldRender(true);
    }
  }, [isReviewOptionsOpen]);

  useEffect(() => {
    if (!isReviewOptionsOpen) {
      setTimeout(() => setReviewShouldRender(false), 100); // Duration matches the animation time
    }
  }, [isReviewOptionsOpen]);
  


  const addComment = async (commentText) => {
    const auth = getAuth();
    const functions = getFunctions();
  
    if (!auth.currentUser) {
      console.error('User not authenticated');
      return;
    }
  
    try {
      // Fetch the user's ID and user data
      const userDocRef = doc(db, 'Users', auth.currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (!userDocSnapshot.exists()) {
        console.error('User document not found');
        return;
      }
  
      const userData = userDocSnapshot.data();
      let commentId = uuidv4();

      // Update the local state with the new comment
      setComments(prevComments => [
        ...prevComments,
        {
          commentId: commentId,
          userId: userData.id,
          comment: commentText,
          timestamp: new Date(), // Use local timestamp for the new comment
          isDeleted: false,
          isUpdated: false,
          targetId: parseFloat(id),
          user: {
            fullName: userData.fullName,
            profileImgUrl: userData.profileImgUrl,
            isVerified: userData.isVerified,
            isBusiness: userData.isBusiness,
            rating: userData.rating,
            numRaters: userData.numRaters,
          },
          commentType: "User"
        }
      ]);
  
      if(commentRef.current) commentRef.current.value = "";
      setComment("")
      setAddCommentLoading(false);

      // Call the Cloud Function
      const addCommentFunction = httpsCallable(functions, 'addComment');
      const result = await addCommentFunction({ commentText, targetId: id, type: "User", sellerId: id, commentId });

      if (result.data.success) {
        if(commentRef.current) commentRef.current.value = "";
        setComment("")
        // Clear the comment input field after adding the comment
        if (commentRef.current) {
          commentRef.current.value = '';
        }
      } else {
        console.error('Failed to add comment.');
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const [sellerNumProducts, setSellerNumProducts] = useState(0)

  const [isDeleted, setisDeleted] = useState(false);
  const [isSold, setisSold] = useState(false);

  const [bidders, setBidders] = useState([]);

  const [isCustomer, setIsCustomer] = useState(false);
  
  const {id} = useParams();
  
  const q = query(collection(db, "Users"), where("id", "==", parseInt(id)));
  
  const fetchComments = async () => {
    try {
      // Build the query to fetch comments from the Comments collection
      const commentsRef = collection(db, 'Comments');
      const commentsQuery = query(
        commentsRef,
        where('targetId', '==', parseFloat(id)), // Ensure postId is a string
        where('commentType', '==', 'User'),
        where('isDeleted', '==', false),
        orderBy('timestamp', 'asc')
      );
  
      const commentsSnapshot = await getDocs(commentsQuery);
      const comments = [];
  
      setCommentsNum(commentsSnapshot.size)
      for (const commentDoc of commentsSnapshot.docs) {
        const commentData = commentDoc.data();
  
        // Get the user document using the id field with parseFloat
        const userQuerySnapshot = await getDocs(query(collection(db, 'Users'), where('id', '==', parseFloat(commentData.user.userId))));
  
        if (!userQuerySnapshot.empty) {
          const userData = userQuerySnapshot.docs[0].data();
          comments.push({
            commentId: commentDoc.id,
            userId: commentData.user.userId,
            comment: commentData.comment,
            timestamp: commentData.timestamp.toDate(),
            isDeleted: commentData.isDeleted,
            isUpdated: commentData.isUpdated,
            targetId: commentData.targetId,
            user: {
              fullName: userData.fullName,
              profileImgUrl: userData.profileImgUrl,
              isVerified: userData.isVerified,
              isBusiness: userData.isBusiness,
            },
            commentType: commentData.commentType
          });
        } else {
          console.error(`User document not found for id: ${commentData.user.userId}`);
        }
      }
  
      setComments(comments);
    } catch (error) {
      console.error('Error fetching comments:', error);
    } finally {
      setCommentsLoading(false);
    }
  };
  

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [showSuccessAnim, setShowSuccessAnim] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');


  const handleAnimationComplete = () => {
    setShowSuccessAnim(false); // Reset button state after animation completes
  };


    useEffect(() => {
    
      const fetchData = async () => {
        try {
          const userQuery = query(collection(db, 'Users'), where('id', '==', parseInt(id)));
          
          const userListener = onSnapshot(userQuery, (querySnapshot) => {
            if (!querySnapshot.empty) {
              setUserExist(true);
              querySnapshot.forEach((doc) => {
                const data = doc.data();
                setProfileImgUrl(data.profileImgUrl);
                setUserName(data.fullName);
                setUserRating(data.rating);
                setNumRaters(data.numRaters);
                setIsVerified(data.isVerified);
                setIsBusiness(data.isBusiness);
                setCity(data.city);
                setUserId(data.id);
              });
    
              const postsQuery = query(collection(db, 'Products'), where('sellerId', '==', parseInt(id)));
              
              const postsListener = onSnapshot(postsQuery, (querySnapshot) => {
                if (!querySnapshot.empty) {
                  const posts = [];
                  setSellerNumProducts(querySnapshot.size);
                  querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (!data.isDeleted) {
                      if (data.expiryDate) {
                        const expiryDate = data.expiryDate.toDate(); // Convert to Date
                        if (Date.now() < expiryDate.getTime()) {
                          posts.push(data);
                        }
                      } else {
                        posts.push(data);
                      }
                    }
                  });
    
                  setListing(posts);
                } else {
                  setListing([]);
                }
              }, (err) => {
                console.error('Error fetching posts:', err);
              });
    
            } else {
              setUserExist(false);
            }
          }, (err) => {
            console.error('Error fetching user data:', err);
          });
    
        } catch (err) {
          console.error('Error setting up listeners:', err);
        }
      };
    
      const fetchOrders = async () => {
        if (!myId) {
          return;
        }
    
        try {
          const orderSnapshot = await getDocs(
            query(
              collection(db, 'Orders'),
              where('buyerId', '==', myId),
              where('sellerId', '==', parseInt(id)),
              where('type', '==', 'Purchase'),
              where('payment_status', '==', 'success'),
              where('isReviewed', '==', false)
            )
          );
    
          if (!orderSnapshot.empty) {
            setOrderId(orderSnapshot.docs[0].data().id);
            setProductName(orderSnapshot.docs[0].data().productData.productName)
            setIsCustomer(true);
          } else {
            setIsCustomer(false);
          }
    
        } catch (err) {
          console.error('Error fetching orders:', err);
        }
      };
    
      const initialize = async (user) => {
        try {
          await fetchData();
          await fetchComments();
          if (auth.currentUser && !auth.currentUser.isAnonymous) {
            await fetchOrders();
          }
        } catch (error) {
          console.error('Error during initialization:', error);
        } finally {
          setIsLoading(false); // Ensure loading is set to false after all operations are completed
        }
      };
    
      const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
        await initialize(user);
      });
    
      return () => {
        if (unsubscribeAuth) unsubscribeAuth();
      };
    }, [id, myId, orderId]);
    
  
  
    // id, isDeleted, currentBid, similarProducts, similarCategory, Object.keys(bidders).length

    // const addCommentToUser = async () => {
    //   try {
    //     if (auth.currentUser !== null) {
    //       const myRef = doc(db, `Users/${auth.currentUser.uid}`)
    //       const docSnap = await getDoc(myRef)

    //       const userQuery = query(collection(db, 'Users'), where('id', '==', parseInt(id)));
    //       const userSnapshot = await getDocs(userQuery);
    
    //       if (!userSnapshot.empty) {
    //         const userDoc = userSnapshot.docs[0]; // Assuming there is only one matching document
    
    //         // Add the comment document directly to the user's comment collection
    //         await addDoc(collection(userDoc.ref, 'commentsOnTheUser'), {
    //           userId: docSnap.data()['id'],
    //           comment: commentRef.current.value,
    //           timestamp: serverTimestamp(),
    //           isDeleted: false,
    //           isUpdated: false,
    //         });


    //         // Add the comment document directly to the user's comment collection
    //         await addDoc(collection(myRef, 'commentsOnUsers'), {
    //           userId: docSnap.data()['id'],
    //           comment: commentRef.current.value,
    //           timestamp: serverTimestamp(),
    //           isDeleted: false,
    //           isUpdated: false,
    //         });


    
    //         // Refetch comments after adding a new comment
    //         // fetchComments(`user${id}`);
    //         // fetchCommentsNew();
    //       } else {
    //         console.error('User document not found');
    //       }
    //     } else {
    //       alert('You need to sign in to add a comment.');
    //     }
    //   } catch (error) {
    //     console.error('Error adding comment:', error);
    //   }
    
    //   // Clear the comment input field
    //   commentRef.current.value = '';
    // };



    // const getComments = async () => {
    //   const commentElements = await Promise.all(
    //     Object.entries(postComments).map(async (c) => {
    //       const docRef = doc(db, "Users", c[0]);
    //       const docu = await getDoc(docRef);
    //       // Your code logic here using docu
          
    //       // Return the JSX element you want to render
    //       return <>
    //       <Comment 
    //       comment={c[1]}
    //       userImg={docu.data()['profileImgUrl']}
    //       userRating={docu.data()['rating']}
    //       numRaters={docu.data()['numRaters']}
    //       userName={docu.data()['fullName']}
    //       />
    //       </>
    //     })
    //     );
    //     setComments(commentElements); // Set the comments in the state
    //     setIsLoading(false)
    //   };
      
    //   getComments();


    useEffect(() => {
      window.scrollTo({
  top: 0,
  left: 0,
  behavior: 'instant'
});
}, []);

const [isVisible, setIsVisible] = useState(false); // Visibility state

useEffect(() => {
  // Set the div to visible after a slight delay to ensure the transition happens
  if(!isLoading){
    setTimeout(() => setIsVisible(true), 100);
  }
}, [isLoading]);


if(isLoading){
  return <LoadingScreen/>
}


    if(!userExist && !isLoading){

      return <div style={{marginTop: "60px", height: "80vh"}}>
<h2>user doesnt exist</h2>
      </div>
    }
  return ( 
    
    <div style={{paddingBottom:"32px"}}>
 {reviewShouldRender && (
        <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          width: "100vw",
          height: "100vh",
          position: "fixed",
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: isReviewOptionsOpen ? 1 : 0,
          transition: "opacity 0.1s ease-out",
          top: 0, left:0
        }}
        
        >
          <div style={{
            width: window.innerWidth > window.innerHeight ? "35%" : "90%",
            height: "fit-content",
            backgroundColor: "white",
            position: "relative",
            margin: "auto",
            opacity: 1,
            zIndex: 2,
            borderRadius: "16px",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            transform: "translateY(-15%)",
            
          }}
          className={isReviewOptionsOpen ? "pop-up" : "pop-down"}
          >
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "8px"
            }}>
              <IoIosCloseCircle size={27} style={{ cursor: "pointer" }} color='#ccc' onClick={() => {
                if(reviewUpdating) return;
                setRatingNotes("")
                setShowReviewError(false)
                setIsReviewOptionsOpen(false);
              }} />
              <div style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center"
              }}>
                <h5 style={{ marginTop: "16px" }}>قيّم {userName}</h5>
              </div>
              <div style={{ width: 32, height: 32 }}></div> {/* Empty div for balance */}
            </div>

              <p>طلب رقم # {orderId}</p>
              <p>للمنتج <span style={{fontWeight:500}}>{productNameFromOrder}</span></p>

              <div style={{display:"flex", alignItems:"center"}}>

                <h5 style={{fontSize:"18px", marginBottom:"0px", marginRight:"4px"}}>({value})</h5>

                <Rating
                disableFillHover={true}
                allowHover={true}
                  onClick={(rate) => {
                    setValue(rate)
                  }}
                  allowFraction
                  size={27}
                />
              {/* <Rating
            value={value}
            precision={0.5}
            style={{direction:"ltr"}}
            draggable
            onChange={(event, newValue) => {
              // console.log(newValue)
              setValue(newValue);
            }}
          /> */}



          {
            parseFloat(value) === 0 && (
              <p style={{color:"red", fontSize:"18px"}}>*</p>
            )
          }
              </div>

          {
            showReviewError && (
              <p style={{color:"red", marginBottom:"0px"}}>يجب ادخال تقييم</p>
            )
          }

<textarea 
placeholder='ملاحظات (اختياري)'
            style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "180px", borderRadius:"8px", outline:"none", padding:"16px 16px 32px 16px", marginTop:"16px", direction:"rtl"}}
            onChange={(e) => {setRatingNotes(e.target.value) }}>
            </textarea>
            <div style={{marginTop:"14px", display:"flex", justifyContent:"space-between", direction:"rtl"}}>
    <button id='my-button' disabled={reviewUpdating} style={{flex:2, height:"35px", padding:"4px 16px", marginLeft:"8px"}} onClick={()=>{updateRating()}} >
    {   
     reviewUpdating ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    display:"flex"

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}>تقييم</p>
              }
    </button>
    <button id='my-button-red' style={{flex:1, padding:"4px 16px"}} onClick={()=>{
                if(reviewUpdating) return;
                setRatingNotes("")
                setShowReviewError(false)
                setIsReviewOptionsOpen(false);

    }} >إلغاء</button>
    </div>
          </div>
        </div>
      )}


 {reportUserShouldRender && (
   <div
   style={{
     backgroundColor: "rgba(20, 0, 0, 0.2)",
     width: "100vw",
     height: "100vh",
     position: "fixed",
     zIndex: 1000,
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     opacity: isReportUserOpen ? 1 : 0,
     transition: "opacity 0.1s ease-out",
     top: 0, left:0
    }}
    
    >
          <div style={{
            width: window.innerWidth > window.innerHeight ? "35%" : "90%",
            height: "fit-content",
            backgroundColor: "white",
            position: "relative",
            margin: "auto",
            opacity: 1,
            zIndex: 2,
            borderRadius: "16px",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            transform: "translateY(-15%)",
            
          }}
          className={isReportUserOpen ? "pop-up" : "pop-down"}
          >
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "8px"
            }}>
              <IoIosCloseCircle size={27} style={{ cursor: "pointer" }} color='#ccc' onClick={() => {
                if(reportLoading) return;
                setReportNotes("")
                setReportReason("")
                setIsReportUserOpen(false);
              }} />
              <div style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center"
              }}>
                <h5 style={{ marginTop: "16px" }}>الإبلاغ عن {userName}</h5>
              </div>
              <div style={{ width: 32, height: 32 }}></div> {/* Empty div for balance */}
            </div>
            <div style={{display:"flex", direction:"rtl"}}>


            <p style={{color:"red"}}>*</p>
            <select
              id="input-field"
              onChange={(event) => {
                const selectedValue = event.target.value;
                setReportReason(selectedValue);
              }}
              style={{ color: "black", borderRadius: "4px", marginBottom:"8px", cursor:"pointer" }}
              >
              <option value="">نوع البلاغ</option>  {/* Default option with descriptive text */}
              <option value="الاحتيال والغش" style={{ backgroundColor: "white", textAlign: "right" }}>
              الاحتيال والغش: عرض منتجات غير أصلية أو مزيفة على أنها أصلية أو تقديم معلومات مضللة عن المنتج.
              </option>
              <option value="المنتجات التالفة أو غير المطابقة للوصف" style={{ backgroundColor: "white", textAlign: "right" }}>
              المنتجات التالفة أو غير المطابقة للوصف: تسليم منتجات تالفة أو مغايرة للوصف المعلن أو الصور المعروضة على المنصة.
              </option>
              <option value="سوء خدمة العملاء" style={{ backgroundColor: "white", textAlign: "right" }}>
              سوء خدمة العملاء: تعامل غير لائق مع العملاء، عدم الرد على الاستفسارات أو الشكاوى، أو رفض رد الأموال أو استبدال المنتجات.
              </option>
              <option value="التلاعب في الأسعار" style={{ backgroundColor: "white", textAlign: "right" }}>
              التلاعب في الأسعار: رفع الأسعار بشكل غير مبرر بعد الاتفاق على سعر معين، أو تطبيق رسوم إضافية غير مبررة.
              </option>
              <option value="مخالفة سياسات المنصة" style={{ backgroundColor: "white", textAlign: "right" }}>
              مخالفة سياسات المنصة: بيع منتجات محظورة أو مخالفة لشروط وأحكام المنصة، مثل المنتجات غير القانونية أو التي تنتهك حقوق الملكية الفكرية.
              </option>
              <option value="آخر" style={{ backgroundColor: "white", textAlign: "right" }}>
              آخر (حدد)
              </option>
            </select>
              </div>

                  <textarea
placeholder= {reportReason === "آخر" ? "وصف البلاغ" : "ملاحظات (اختياري)"}
            style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "180px", borderRadius:"8px", outline:"none", padding:"16px 16px 32px 16px", marginTop:"16px", direction:"rtl"}}
            onChange={(e) => {setReportNotes(e.target.value) }}>
            </textarea>
            <div style={{marginTop:"14px", display:"flex", justifyContent:"space-between", direction:"rtl"}}>
    <button 
    id='my-button' 
    disabled={reportLoading} 
    style={{height:"35px", padding:"4px 16px", width:"100%", marginLeft:"8px"}} onClick={()=>{reportUser()}} >
    {   
     reportLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{marginBottom:"0px", marginRight:"0px"}}>إبلاغ</p>
              }
    </button>
    <button id='my-button-red' style={{padding:"4px 8px",  width:"100%"}} onClick={()=>{
                if(reportLoading) return;
                setReportNotes("")
                setReportReason("")
                setIsReportUserOpen(false);

    }} >إلغاء</button>
    </div>
          </div>
        </div>
      )}

      


{showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}

<div
      style={{
        paddingTop: "16px",
        width: window.innerWidth > window.innerHeight ? "80%" : "100%",
        height: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
        direction: "rtl",
        paddingBottom: "32px",
        padding: window.innerWidth > window.innerHeight ? "0px" : "16px",
        minHeight: "100vh",
        opacity: isVisible ? 1 : 0, // Fade in effect based on isVisible
        transform: isVisible ? "translateY(0)" : "translateY(20px)", // Move up effect based on isVisible
        transition: "opacity 0.3s ease, transform 0.2s ease", // Transition effects
      }}
    > 

                    {/* path */}
                    <div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
                    <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8", minHeight:"18px", minWidth:"18px"}} />

          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px",
      fontWeight:"bold"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>

          {
            productName && productCategory && (
              <>

<h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>

<h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    onClick={()=>{navigate(-2)}}
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >{productCategory}</h6>


<h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>


<h6 style={{
  marginBottom:"0px", 
        maxWidth: "100%",  /* Ensure the element does not exceed the container's width */
        overflow: "hidden",  /* Hide any overflow content */
        whiteSpace: "nowrap",  /* Prevent text from wrapping to a new line */
        textOverflow: "ellipsis",  /* Display ellipsis if the text overflows */
      fontSize:"14px",
    }}
    onClick={()=>{navigate(-1)}}
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >{productName}</h6>


              </>
            ) 
          }

<h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>

          <h6 style={{marginBottom:"0px", 
      color: "black",
      cursor:"pointer",
      fontSize:"14px",
      whiteSpace:"nowrap"
    }}
          >صفحة البائع</h6>


        </div>
      </div>

<div style={{ display: "flex", direction: "rtl", width: "fit-content", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
  <div style={{marginTop:"16px", position:"relative", padding: "0px 16px"}}>

{
  myId && myId !== parseFloat(id) && (
<>
<div 
    onClick={(e) => {
      e.stopPropagation(); // Stop event propagation to prevent it from reaching the parent div
      toggleDropdown();
    }}
    style={{position:"absolute", left:0, top:0, padding:"2px 0px", cursor:"pointer"}}>
<BsThreeDotsVertical />

    </div>
</>
  )
}
    
{shouldRender && (
        <div
          className={isOpen ? 'pop-up' : 'pop-down'}
          style={{
            width: 'fit-content',
            // width: '500px',
            // padding: '8px 16px',
            position: 'absolute',
            backgroundColor: 'white',
            left: 25,
            top: 0,
            direction: 'rtl',
            borderRadius: '8px',
            boxShadow: '0 1px 2px rgba(0,0,0,0.2)',

            // border: "1px solid #E8E9E9"
          }}
        >


<div
  style={{
    transition: 'background-color 0.3s',
    padding: "8px",
    borderRadius: "8px",
    cursor: 'pointer',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft:"42px",
    borderBottomLeftRadius:"0px",
    borderBottomRightRadius:"0px"
  }}
  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#F7F7F7')}
  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
  onClick={(e) => {
    if (myId) {
      e.stopPropagation(); // Stop event propagation to prevent it from reaching the parent div
      toggleSendMessage();
      toggleDropdown();
    } else {
      alert("يجب عليك تسجيل الدخول لخدمتك بشكل أفضل");
      toggleDropdown();
    }
  }}
>
  <p
    style={{
      color: '#00C8B8',
      margin: 0,
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      marginLeft:"auto",
      fontSize:"15px"
    }}
  >
    <IoChatboxEllipsesOutline size={17} style={{ marginLeft: '4px' }} />
    مراسلة البائع
  </p>
</div>

<Divider sx={{ width: '100%' }} />


<div
  style={{
    transition: 'background-color 0.3s',
    padding: "8px",
    borderRadius: "8px",
    cursor: 'pointer',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft:"42px",
    borderTopLeftRadius:"0px",
    borderTopRightRadius:"0px"
  }}
  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#F7F7F7')}
  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
  onClick={(e) => {
    if (myId) {
      e.stopPropagation(); // Stop event propagation to prevent it from reaching the parent div
      toggleReport();
      toggleDropdown();
    } else {
      alert("يجب عليك تسجيل الدخول لخدمتك بشكل أفضل");
      toggleDropdown();
    }
  }}
>
  <p
    style={{
      color: '#F00057',
      margin: 0,
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      marginLeft:"auto",
      fontSize:"15px"
    }}
  >
    <HiOutlineExclamationCircle size={17} style={{ marginLeft: '4px' }} />
    الإبلاغ عن البائع
  </p>
</div>



        </div>
      )}

    <img width={160} height={160} className='skeleton-profileImg' style={{ borderRadius: "1000px", objectFit: "cover", marginLeft: "auto", marginRight: "auto", opacity:1, display:"block", border: "1px solid #E8E9E9" }} src={profileImgUrl} />
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "8px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ margin: "0", marginLeft: "4px", fontStyle: "20px", fontWeight:"500" }}>{userName}</p>
        {!isBusiness && isVerified && <RiVerifiedBadgeFill size={22}  style={{marginLeft: "4px"}} color='#00C8B8'/>}
        {isBusiness && isVerified && <FaStore size={22}  style={{marginLeft: "4px"}} color='#F00057'/>}
      <div style={{display: "flex"}}>
        <AiFillStar size={17} style={{marginTop:"2px"}} color='gold' />
<p style={{marginBottom: "0px", fontSize:"13px"}}>{parseFloat(userRating.toFixed(1))}</p>
<p style={{marginBottom: "0px", marginRight: "4px", fontSize:"13px"}}>({numRaters})</p>
      </div>
      </div>
      
      {
        city != "" &&
        <div style={{direction: "rtl", display: "flex"}}>
<FaLocationDot color='red'/>
<p style={{marginRight: "2px", marginBottom: "0px", color:"black"}}>{city}</p>
      </div>
      }

{isCustomer && auth.currentUser && !auth.currentUser.isAnonymous && orderId
&& (
  <button
id='my-button'
style={{
  marginTop: "8px",
  width: "60%",
   padding: "4px 8px"
}}
onClick={()=>{setIsReviewOptionsOpen(true);}}
  >
    تقييم البائع
  </button>
)}


    </div>          
  </div>
</div>

        
<div
style={{marginTop: "64px"}}
>

{
  comments.length > 0 ? (
    <>
      <h5 style={{fontWeight: "500", color: "#00A49B", marginBottom:"16px" }}>التعليقات ({comments.length})</h5>

      {comments.map((comment) => (
        <div
        key={comment.commentId}
        style={{
          width: window.innerWidth > window.innerHeight ? '50%' : "100%"
        }}
        >
        <Comment 
          key={comment.timestamp}  // Make sure to add a unique key
          comment={comment.comment} 
          userRating={comment.user.rating}
          numRaters={comment.user.numRaters}
          userImg={comment.user.profileImgUrl}
          userName={comment.user.fullName}
          commentRef="User"
          link={`/user/${comment.userId}`}
          timestamp={comment.timestamp}
        />
        </div>
      ))}
    </>
  ) : (
    <h5 style={{fontWeight: "500", color: "#00A49B", marginBottom:"16px", marginTop:"32px" }}>لا توجد تعليقات حتى الآن</h5>

  )
}


{ auth.currentUser != null && (
  <div style={{direction:"rtl", display:"flex", flexDirection:"column", position:"relative", height:"fit-content", width: window.innerWidth > window.innerHeight ? "50%" : "100%"}}>

    <textarea
      required={false}
      ref={commentRef}
      style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "180px", borderRadius:"8px", outline:"none", padding:"16px 16px 32px 16px", marginLeft:"auto"}}
      minLength={10}
      onChange={(event) => {
        setComment(event.target.value);
      }}
      placeholder="أضف تعليقًا أو سؤالًا"
    />

    <button
      id= {addCommentLoading ? 'add-comment-button-disabled' : 'add-comment-button'}
      disabled={addCommentLoading}
      style={{
        height: "35px", 
        position: "absolute", 
        bottom: 0, 
        left: 8,
        display: "flex", // Use flexbox for alignment
        alignItems: "center", // Center vertically
        justifyContent: "center", // Align content to the left
        backgroundColor:"#F00057"
    }}
      onClick={()=>{
         if(comment.length < 1) return;
         
        setAddCommentLoading(true);
        addComment(comment).then(()=>{
          setAddCommentLoading(false);
          setComment("");
          // if(sellerEmail != "" && sellerId != myId){
          //   // sendCommentEmail()
          // }
        })}}
    >
    {   
     addCommentLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    maxHeight:"35px" 
      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : <p style={{whiteSpace:"nowrap", marginBottom:"0px", fontSize:"14px"}}>اضافة تعليق</p>
              }
    </button>
  </div>
)}

{auth.currentUser == null && (
  <div
    style={{
      padding: "8px 0px",
      color: "dodgerblue",
      cursor: "pointer",
      width: "fit-content",
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.color = "#1fa9ff"; // Slightly lighter dodger blue
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.color = "dodgerblue";
    }}
    onClick={() => {
      navigate('/sign_in', { state: { currentUrl } });
    }}
  >
    <p style={{ marginTop: "16px", fontWeight: 500 }}>
      أضف تعليق
    </p>
  </div>
)}




{
  Listing.length > 0 ? (
    <>
    <h5 style={{fontWeight: "500", color: "#00A49B", marginBottom:"16px", marginTop:"32px" }}>منتجات البائع ({Listing.length}) </h5>
{
   <div 
   style={{
     display: "grid",
     width: "100%",
     height: "fit-content",
     direction: "rtl",
     rowGap: "16px",
     columnGap: "16px",
     gridTemplateColumns: window.innerWidth > window.innerHeight ? window.innerWidth > 1500 ? `repeat(auto-fill, minmax(9vw, 1fr))` : window.innerWidth < 1100 ? `repeat(auto-fill, minmax(18vw, 1fr))` : `repeat(auto-fill, minmax(14vw, 1fr))` : window.innerWidth > 600 ? `repeat(auto-fill, minmax(18vw, 1fr))` : `repeat(auto-fill, minmax(42vw, 1fr))`,

     justifyContent: "center",
     justifyItems: "stretch",
     alignItems: "stretch",
   }}
   >
   {Listing.map((item) => (
     <div key={item.id}>
       <Product
         postId={item.id}
         carPlateKind={item.carPlate.kind}
         expiryDate={item.expiryDate}
         auctionStartDate={item.auctionStartDate && item.auctionStartDate.toDate()}
         link={`/product/${parseFloat(item.id)}`}
         numBidders={item.numBidders}
         currentBid={item.currentBid}
         imgs={item.imgs[0]}
         isSold={item.isSold}
         myId={myId}
         condition={item.condition}
         isAuction={item.postType.includes("مزاد")}
         acceptsOffers={item.postType.includes("عروض")}
         productPrice={item.productPrice}
         productDescription={item.productDescription}
         sellerId={item.sellerId}
         startingPrice={item.startingPrice}
         sellerCity={item.seller}
         category={item.category}
         productName={item.productName}
         views={item.views}
         />
     </div>
   ))}
  </div>
}
    </>
  ) : 
  <h5 style={{fontWeight: "500", color: "#00A49B", margin:"32px 0px" }}>البائع لم يضف أي منتج بعد</h5>

}



</div>
    </div>
    </div>
   );
  }

export default UserScreen;